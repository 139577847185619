// src/lib/avatar-config.ts
var avatarConfig = {
  topType: "WinterHat2",
  topColor: "Blue03",
  hairColor: "Black",
  accessoriesType: "Round",
  facialHairType: "Blank",
  facialHairColor: "Blonde",
  clothesType: "CollarSweater",
  clothesColor: "PastelRed",
  eyeType: "Close",
  eyebrowType: "UnibrowNatural",
  mouthType: "Default",
  skinColor: "Black",
  backgroundColor: "Primary",
  graphicType: "Hola"
};
var partialAvatarConfig = {
  topType: "ShortHairShortCurly",
  topColor: "PastelOrange",
  facialHairColor: "Black",
  clothesType: "ShirtCrewNeck",
  eyeType: "Default",
  mouthType: "Default",
  skinColor: "Yellow"
};
var mockDataURI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAAEYCAYAAACgIGhkAAAgAElEQVR4Xu1dB3gU1dp";

// src/lib/actor-avatar.ts
var actorAvatar = {
  id: 1,
  config: avatarConfig,
  createdAt: "2020-08-11T11:42:50-06:00",
  updatedAt: "2020-08-14T12:56:32-06:00"
};

// src/lib/address.ts
var mockAddress = {
  lat: 34.0632023,
  lng: -118.3188666,
  number: "605",
  city: "Los Angeles",
  cityOrCounty: "Los Angeles County",
  state: "CA",
  country: "United States",
  zip: "90005",
  name: null,
  formatted: "605 S Irving Blvd, Los Angeles, CA 90005, USA",
  placeId: "ChIJLav0vZO4woARHRj7joFj2Tc",
  street1: "605 South Irving Boulevard"
};
var mockGooglePlace = {
  address_components: [
    {
      long_name: "605",
      short_name: "605",
      types: ["street_number"]
    },
    {
      long_name: "South Irving Boulevard",
      short_name: "S Irving Blvd",
      types: ["route"]
    },
    {
      long_name: "Central LA",
      short_name: "Central LA",
      types: ["neighborhood", "political"]
    },
    {
      long_name: "Los Angeles",
      short_name: "Los Angeles",
      types: ["locality", "political"]
    },
    {
      long_name: "Los Angeles County",
      short_name: "Los Angeles County",
      types: ["administrative_area_level_2", "political"]
    },
    {
      long_name: "California",
      short_name: "CA",
      types: ["administrative_area_level_1", "political"]
    },
    {
      long_name: "United States",
      short_name: "US",
      types: ["country", "political"]
    },
    {
      long_name: "90005",
      short_name: "90005",
      types: ["postal_code"]
    },
    {
      long_name: "3608",
      short_name: "3608",
      types: ["postal_code_suffix"]
    }
  ],
  formatted_address: "605 S Irving Blvd, Los Angeles, CA 90005, USA",
  geometry: {
    location: {
      lat: () => 34.0632023,
      lng: () => -118.3188666
    },
    viewport: {
      south: 34.0618538197085,
      west: -118.3200465802915,
      north: 34.0645517802915,
      east: -118.3173486197085
    }
  },
  place_id: "ChIJLav0vZO4woARHRj7joFj2Tc",
  html_attributions: []
};
var address = {
  id: 1,
  street1: "1600 Pennsylvania Avenue Northwest",
  street2: null,
  city: "Washington",
  cityState: "Washington, DC",
  state: "DC",
  zip: "20500",
  country: "United States",
  multiline: ["1600 Pennsylvania Avenue Northwest", "Washington, DC 20500"],
  formatted: "1600 Pennsylvania Avenue Northwest, Washington, DC 20500",
  lat: 38.8977,
  lng: -77.0366
};

// src/lib/avatar-url.ts
var avatarUrl = "//localhost:3000/public/files/square/missing.png";

// src/lib/assignment.ts
var assignment = {
  id: 888888,
  attended: null,
  avatarUrl,
  createdAt: "2021-06-28T14:16:24-04:00",
  employeeId: 1234,
  employeeType: "Tutor",
  firstName: "Professor",
  lastName: "Oak",
  supervisor: true,
  updatedAt: "2021-06-28T14:16:24-04:00"
};

// src/lib/attachment.ts
var attachments = [
  {
    id: 112233,
    createdAt: "2020-10-14T12:36:42-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_112233",
    updatedAt: "2020-10-14T12:36:42-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_112233",
    fileName: null,
    contentType: null
  },
  {
    id: 123456,
    createdAt: "2020-10-14T12:36:48-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_123456",
    updatedAt: "2020-10-14T12:36:48-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_123456",
    fileName: null,
    contentType: null
  },
  {
    id: 123457,
    createdAt: "2020-10-14T12:53:29-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_123457",
    updatedAt: "2020-10-14T12:53:29-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_123457",
    fileName: null,
    contentType: null
  },
  {
    id: 123458,
    createdAt: "2020-10-14T12:53:42-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_1234568",
    updatedAt: "2020-10-14T12:53:42-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_1234568",
    fileName: null,
    contentType: null
  },
  {
    id: 123459,
    createdAt: "2020-10-14T12:54:03-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_123459",
    updatedAt: "2020-10-14T12:54:03-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_123459",
    fileName: null,
    contentType: null
  },
  {
    id: 123450,
    createdAt: "2020-10-14T13:01:47-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_123450",
    updatedAt: "2020-10-14T13:01:47-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_123450",
    fileName: null,
    contentType: null
  },
  {
    id: 135791,
    createdAt: "2020-10-14T13:01:58-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_135791",
    updatedAt: "2020-10-14T13:01:58-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_135791",
    fileName: null,
    contentType: null
  },
  {
    id: 124680,
    createdAt: "2020-10-14T13:02:55-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_124680",
    updatedAt: "2020-10-14T13:02:55-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_124680",
    fileName: null,
    contentType: null
  },
  {
    id: 101010,
    createdAt: "2020-10-14T13:59:59-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_101010",
    updatedAt: "2020-10-14T13:59:59-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_101010",
    fileName: null,
    contentType: null
  },
  {
    id: 121212,
    createdAt: "2020-10-14T15:03:08-07:00",
    description: null,
    fileSize: null,
    thumbnailUrl: "https://attachment-thumbnail-url_121212",
    updatedAt: "2020-10-14T15:03:08-07:00",
    uploaderName: "Ash Ketchum",
    url: "https://attachment-url_121212",
    fileName: null,
    contentType: null
  }
];
var attachment = {
  id: 111111,
  createdAt: "2020-10-14T15:03:08-07:00",
  description: null,
  fileSize: null,
  thumbnailUrl: "https://attachment-thumbnail-url_111111",
  updatedAt: "2020-10-14T15:03:08-07:00",
  uploaderName: "Ash Ketchum",
  url: "https://attachment-url_111111",
  fileName: null,
  contentType: null
};
var tutoringUpload = {
  id: 222222,
  createdAt: "2021-06-28T14:16:50-04:00",
  description: null,
  fileSize: null,
  thumbnailUrl: "https://attachment-thumbnail-url_222222",
  updatedAt: "2021-06-28T14:16:50-04:00",
  uploaderName: "Ash Ketchum",
  url: "https://attachment-url_222222",
  fileName: null,
  contentType: null
};

// src/lib/brand.ts
var brand = {
  id: 2,
  description: null,
  departmentId: 1,
  forceDuration: null,
  handle: "private-tutoring-department-1",
  includePoints: false,
  offerArrearBilling: false,
  name: "Private Tutoring",
  includeParentUpdates: true
};
var brandRevolutionNow = {
  id: 13,
  description: null,
  departmentId: 1,
  forceDuration: null,
  handle: "homework-help-department-1",
  includePoints: false,
  offerArrearBilling: false,
  name: "Revolution Now",
  includeParentUpdates: false
};
var brandTutoring = {
  id: 37,
  description: null,
  departmentId: 1,
  forceDuration: 60,
  handle: "tutoring-department-1",
  includePoints: false,
  offerArrearBilling: true,
  name: "Tutoring",
  includeParentUpdates: true
};
var brandPrivateTutoring = {
  active: true,
  allowDynamicSubject: false,
  allowLimitedAttendance: false,
  allowManualAttendance: false,
  classDuration: 120,
  contractIntervalLength: null,
  contractIntervalUnit: null,
  convertible: true,
  createdAt: "2017-08-29T19:24:36-07:00",
  delightedSurveyMinutesInterval: 360,
  departmentId: 1,
  description: null,
  employeeStaffing: false,
  enrollmentCap: 1,
  examDuration: 120,
  forceDuration: null,
  grantDiscountPricing: false,
  handle: "private-tutoring-department-1",
  hasPromotionType: false,
  hidden: true,
  hoursAdjustable: true,
  id: 2,
  includeCourseTemplate: false,
  includeCustomName: false,
  includeDelightedSurveys: true,
  includeEmployees: false,
  includeGradeLevel: false,
  includeJointSessions: false,
  includeLeadSource: false,
  includeParents: false,
  includeParentUpdates: true,
  includePoints: false,
  includeReviews: true,
  includeScoreReports: true,
  includeSegment: false,
  includeSessions: true,
  includeStartingEpisodeNumber: false,
  includeStudents: true,
  includeSubject: false,
  includeTestDate: false,
  leadGenerator: false,
  limitedAttendancesCount: 0,
  maxEpisodeNumber: null,
  maxJointAttendances: 0,
  maxWeeklySessions: null,
  meterSessionUsage: true,
  name: "Private Tutoring",
  offerArrearBilling: false,
  offerContract: false,
  offerDiscount: false,
  offerReserved: false,
  offerSplitPayments: true,
  ongoingEnrollment: false,
  ongoingEnrollmentOffset: null,
  parentUpdatesInterval: "Weekly",
  paymentType: "None",
  promotionTypeName: null,
  reportingType: "Private",
  requireGradeLevel: false,
  requireHost: false,
  requireSegment: false,
  requireStartingEpisodeNumber: false,
  requireSubject: false,
  requireTestDate: false,
  reservedIntervalLength: null,
  reservedIntervalUnit: null,
  reviewInterval: 600,
  reviewIntervalUnit: "minutes",
  reviewRetainIncompleteForHours: null,
  splitPaymentThreshold: 479,
  startingEpisodeWeek: null,
  trackPace: true,
  tutorScheduling: true,
  updatedAt: "2020-08-26T13:05:32-07:00"
};
var brandSelfStudy = {
  id: 44,
  affiliateId: void 0,
  description: null,
  departmentId: 1,
  expireDurationAfterDays: void 0,
  forceBillingRate: null,
  forceDuration: null,
  handle: "self-study-department-1",
  includePoints: false,
  offerArrearBilling: false,
  offerReupMessage: void 0,
  offerStudentReup: false,
  renewalDuration: void 0,
  name: "Self Study",
  requireProgressUpdateAfterDays: void 0,
  requireProgressUpdateMinProvisionedDuration: void 0,
  forceScheduleAllHours: false,
  forceScheduleByAfterDays: void 0,
  schedulingWindowDays: void 0,
  includeParentUpdates: false,
  includeProgressUpdate: false,
  userflowToken: "ca5853bd4449ad60c4f44a45361d6f8653bdf47e48639ce1fd9ddd956d0ae4ee"
};

// src/lib/deliverable.ts
var deliverable = {
  id: null,
  type: "TutorPackage",
  brandName: "Private Tutoring",
  conversionItemId: 18765,
  conversionItemType: "TutorPackage",
  deliveryMethod: "online",
  department: {
    id: 1,
    name: "Retail",
    handle: "retail",
    enroll_url: "https://localhost:9000",
    default_advisor_id: 1999,
    created_at: "2017-04-17T18:27:16-04:00",
    updated_at: "2020-05-04T18:39:17-04:00"
  },
  duration: 720,
  gradeLevel: null,
  leadSource: null,
  restrictByLeadSource: null,
  schedule: null,
  seatsRemaining: null,
  segmentName: "Premium",
  subjectName: null,
  tier: {
    id: 3,
    name: "Premium",
    billing_rate: "99.0",
    membership_billing_rate: "49.0",
    created_at: "2017-04-17T18:27:16-04:00",
    updated_at: "2018-06-04T23:52:05-04:00"
  },
  title: "12 Hours of Premium Tutoring for Ash Ketchum with Professor Oak"
};
var deliverableMembership = {
  id: 273,
  type: "Course",
  brandName: "Membership",
  conversionItemId: 273,
  conversionItemType: "Course",
  deliveryMethod: "online",
  department: {
    id: 1,
    name: "Retail",
    handle: "retail",
    enroll_url: "https://localhost:9000",
    default_advisor_id: 1703,
    created_at: "2017-04-17T18:27:16-04:00",
    updated_at: "2020-05-04T18:39:17-04:00"
  },
  duration: 0,
  gradeLevel: null,
  leadSource: null,
  restrictByLeadSource: false,
  schedule: [],
  seatsRemaining: null,
  segmentName: null,
  subjectName: null,
  tier: null,
  title: "Membership"
};

// src/lib/lead-source.ts
var leadSource = {
  id: 123,
  avatar: null,
  createdAt: "2006-05-29T06:33:09-07:00",
  description: "New York, NY",
  highGrade: "12",
  category: "High School",
  centerId: 1,
  centerException: true,
  leadScore: null,
  level: null,
  lowGrade: "K",
  managed: false,
  marketingAllowed: false,
  name: "Awesome School",
  nickname: null,
  param: null,
  schoolType: "private",
  standing: "good",
  trailingSixMonthsRevenue: 9e3,
  type: "School",
  updatedAt: "2022-01-03T11:42:16-08:00",
  websiteUrl: "www.awesomeschool.com",
  fullName: "Awesome School",
  currentYearRevenue: 999,
  institutionPhone: "+2125557700",
  lastActivityAt: "2021-06-07T12:17:50-07:00",
  lastYearRevenue: 8888,
  numberOfStudents: 1e3,
  owner: "advisor",
  partnerEndDate: "2016-09-23",
  partnerEndedBy: "Revolution",
  partnerStartDate: "2014-11-17",
  partnerStatus: null,
  rank: null,
  revenueShare: false,
  status: "Target",
  totalRevenue: 336699.99
};
var schoolStatusLeadSource = {
  id: 18888,
  avatar: {
    url: "https://testimage.test"
  },
  createdAt: "2017-08-30T20:31:48-07:00",
  centerId: 4,
  centerException: false,
  description: "A good school",
  highGrade: "12",
  category: "High School",
  leadScore: 66,
  level: null,
  lowGrade: "9",
  name: "Learning Academies",
  nickname: "LEARNING",
  param: null,
  schoolType: "private",
  type: "School",
  updatedAt: "2021-06-08T12:52:06-07:00",
  websiteUrl: null,
  fullName: "Learning Academies",
  consultNotes: "Great school",
  currentYearRevenue: 0,
  institutionPhone: "1111111111",
  lastActivityAt: "2021-06-08T12:52:06-07:00",
  lastYearRevenue: 0,
  managed: false,
  marketingAllowed: true,
  numberOfStudents: 700,
  owner: "closer",
  partnerEndDate: null,
  partnerEndedBy: null,
  partnerStartDate: null,
  partnerStatus: null,
  rank: null,
  revenueShare: false,
  standing: "Target",
  status: "Managed",
  totalRevenue: 44763,
  trailingSixMonthsRevenue: 0
};
var schoolLeadSource = {
  ...schoolStatusLeadSource,
  consultNotes: null,
  status: "Partner"
};
var libraryLeadSource = {
  id: 133333,
  avatar: null,
  createdAt: "2017-04-10T08:58:56-07:00",
  centerId: 13,
  centerException: false,
  description: "Somewhere, CA",
  highGrade: "PK",
  category: "Library",
  leadScore: null,
  level: 0,
  lowGrade: "PK",
  name: "Nice Library",
  nickname: "Nice Library",
  param: null,
  schoolType: null,
  type: "LeadSource",
  updatedAt: "2021-04-02T14:46:21-07:00",
  websiteUrl: "",
  fullName: "Nice Library",
  consultNotes: null,
  currentYearRevenue: 0,
  institutionPhone: "",
  lastActivityAt: "2018-04-06T08:23:02-07:00",
  lastYearRevenue: 0,
  managed: true,
  marketingAllowed: true,
  numberOfStudents: null,
  owner: "advisor",
  partnerEndDate: null,
  partnerEndedBy: null,
  partnerStartDate: "2017-04-10",
  partnerStatus: "2nd Year+",
  rank: null,
  revenueShare: false,
  standing: "Underperformer",
  status: "Managed",
  totalRevenue: 29350,
  trailingSixMonthsRevenue: 0
};

// src/lib/subject.ts
var subjects = [
  {
    id: 2,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "17",
    homeworkHelp: true,
    name: "Algebra II",
    param: "algebra-ii",
    categoryName: "Math"
  },
  {
    id: 3,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "AP Calculus AB",
    param: "ap-calculus-ab",
    categoryName: "Math"
  },
  {
    id: 4,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "AP Calculus BC",
    param: "ap-calculus-bc",
    categoryName: "Math"
  },
  {
    id: 60,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Calculus (College-Level)",
    param: "calculus-college-level",
    categoryName: "Math"
  },
  {
    id: 58,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "17",
    homeworkHelp: true,
    name: "Geometry",
    param: "geometry",
    categoryName: "Math"
  },
  {
    id: 7,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Pre-Calculus",
    param: "pre-calculus",
    categoryName: "Math"
  },
  {
    id: 8,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Statistics",
    param: "statistics",
    categoryName: "Math"
  },
  {
    id: 9,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Trigonometry",
    param: "trigonometry",
    categoryName: "Math"
  },
  {
    id: 90,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Anatomy & Physiology (College-Level)",
    param: "anatomy-physiology-college-level",
    categoryName: "Science"
  },
  {
    id: 76,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Biology (College-Level)",
    param: "biology-college-level",
    categoryName: "Science"
  },
  {
    id: 79,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Chemistry (College-Level)",
    param: "chemistry-college-level",
    categoryName: "Science"
  },
  {
    id: 15,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Computer Science ",
    param: "computer-science",
    categoryName: "Science"
  },
  {
    id: 178,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Organic Chemistry (College-Level)",
    param: "organic-chemistry-college-level",
    categoryName: "Science"
  },
  {
    id: 83,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Physics (College-Level)",
    param: "physics-college-level",
    categoryName: "Science"
  },
  {
    id: 23,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Reading Comprehension",
    param: "reading-comprehension",
    categoryName: "English"
  },
  {
    id: 72,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: false,
    name: "TOEFL",
    param: "toefl",
    categoryName: "English"
  },
  {
    id: 24,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Writing",
    param: "writing",
    categoryName: "English"
  },
  {
    id: 104,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Chinese",
    param: "chinese",
    categoryName: "Language"
  },
  {
    id: 105,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Chinese (Level 2)",
    param: "chinese-level-2",
    categoryName: "Language"
  },
  {
    id: 106,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Chinese (Level 3)",
    param: "chinese-level-3",
    categoryName: "Language"
  },
  {
    id: 107,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "French",
    param: "french",
    categoryName: "Language"
  },
  {
    id: 108,
    aliasedSubjectId: 107,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "French (Level 2)",
    param: "french-level-2",
    categoryName: "Language"
  },
  {
    id: 109,
    aliasedSubjectId: 107,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "French (Level 3)",
    param: "french-level-3",
    categoryName: "Language"
  },
  {
    id: 110,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "German",
    param: "german",
    categoryName: "Language"
  },
  {
    id: 111,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "German (Level 2)",
    param: "german-level-2",
    categoryName: "Language"
  },
  {
    id: 112,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Italian",
    param: "italian",
    categoryName: "Language"
  },
  {
    id: 113,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Italian (Level 2)",
    param: "italian-level-2",
    categoryName: "Language"
  },
  {
    id: 114,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Japanese",
    param: "japanese",
    categoryName: "Language"
  },
  {
    id: 115,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Japanese (Level 2)",
    param: "japanese-level-2",
    categoryName: "Language"
  },
  {
    id: 116,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Japanese (Level 3)",
    param: "japanese-level-3",
    categoryName: "Language"
  },
  {
    id: 117,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Latin",
    param: "latin",
    categoryName: "Language"
  },
  {
    id: 118,
    aliasedSubjectId: 117,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Latin (Level 2)",
    param: "latin-level-2",
    categoryName: "Language"
  },
  {
    id: 119,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Spanish",
    param: "spanish",
    categoryName: "Language"
  },
  {
    id: 120,
    aliasedSubjectId: 119,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Spanish (Level 2)",
    param: "spanish-level-2",
    categoryName: "Language"
  },
  {
    id: 121,
    aliasedSubjectId: 119,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Spanish (Level 3)",
    param: "spanish-level-3",
    categoryName: "Language"
  },
  {
    id: 98,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: false,
    name: "Art History",
    param: "art-history",
    categoryName: "Social Studies"
  },
  {
    id: 125,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Economics",
    param: "economics",
    categoryName: "Social Studies"
  },
  {
    id: 35,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "European History",
    param: "european-history",
    categoryName: "Social Studies"
  },
  {
    id: 37,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Human Geography ",
    param: "human-geography",
    categoryName: "Social Studies"
  },
  {
    id: 92,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Macroeconomics (College-Level)",
    param: "macroeconomics-college-level",
    categoryName: "Social Studies"
  },
  {
    id: 94,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Microeconomics (College-Level)",
    param: "microeconomics-college-level",
    categoryName: "Social Studies"
  },
  {
    id: 101,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: false,
    name: "Music Theory",
    param: "music-theory",
    categoryName: "Social Studies"
  },
  {
    id: 85,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Psychology (College-Level)",
    param: "psychology-college-level",
    categoryName: "Social Studies"
  },
  {
    id: 40,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "U.S. Government",
    param: "u-s-government",
    categoryName: "Social Studies"
  },
  {
    id: 41,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "U.S. History",
    param: "u-s-history",
    categoryName: "Social Studies"
  },
  {
    id: 42,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "World History",
    param: "world-history",
    categoryName: "Social Studies"
  },
  {
    id: 150,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "PK",
    highGrade: "17",
    homeworkHelp: false,
    name: "Executive Functioning",
    param: "executive-functioning",
    categoryName: "Test Prep"
  },
  {
    id: 44,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "GMAT",
    param: "gmat",
    categoryName: "Test Prep"
  },
  {
    id: 45,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "GRE",
    param: "gre",
    categoryName: "Test Prep"
  },
  {
    id: 48,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "LSAT",
    param: "lsat",
    categoryName: "Test Prep"
  },
  {
    id: 49,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "MCAT",
    param: "mcat",
    categoryName: "Test Prep"
  }
];
var subjectsByCategory = {
  English: [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "English",
      highGrade: "17",
      homeworkHelp: false,
      id: 23,
      lowGrade: "K",
      name: "Reading Comprehension",
      param: "reading-comprehension"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "English",
      highGrade: "17",
      homeworkHelp: false,
      id: 72,
      lowGrade: "9",
      name: "TOEFL",
      param: "toefl"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "English",
      highGrade: "17",
      homeworkHelp: false,
      id: 24,
      lowGrade: "K",
      name: "Writing",
      param: "writing"
    }
  ],
  Language: [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 104,
      lowGrade: "K",
      name: "Chinese",
      param: "chinese"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 105,
      lowGrade: "K",
      name: "Chinese (Level 2)",
      param: "chinese-level-2"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 106,
      lowGrade: "K",
      name: "Chinese (Level 3)",
      param: "chinese-level-3"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 107,
      lowGrade: "K",
      name: "French",
      param: "french"
    },
    {
      aliasedSubjectId: 107,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 108,
      lowGrade: "K",
      name: "French (Level 2)",
      param: "french-level-2"
    },
    {
      aliasedSubjectId: 107,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 109,
      lowGrade: "K",
      name: "French (Level 3)",
      param: "french-level-3"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 110,
      lowGrade: "K",
      name: "German",
      param: "german"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 111,
      lowGrade: "K",
      name: "German (Level 2)",
      param: "german-level-2"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 112,
      lowGrade: "K",
      name: "Italian",
      param: "italian"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 113,
      lowGrade: "K",
      name: "Italian (Level 2)",
      param: "italian-level-2"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 114,
      lowGrade: "K",
      name: "Japanese",
      param: "japanese"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 115,
      lowGrade: "K",
      name: "Japanese (Level 2)",
      param: "japanese-level-2"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: false,
      id: 116,
      lowGrade: "K",
      name: "Japanese (Level 3)",
      param: "japanese-level-3"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 117,
      lowGrade: "K",
      name: "Latin",
      param: "latin"
    },
    {
      aliasedSubjectId: 117,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 118,
      lowGrade: "K",
      name: "Latin (Level 2)",
      param: "latin-level-2"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 119,
      lowGrade: "K",
      name: "Spanish",
      param: "spanish"
    },
    {
      aliasedSubjectId: 119,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 120,
      lowGrade: "K",
      name: "Spanish (Level 2)",
      param: "spanish-level-2"
    },
    {
      aliasedSubjectId: 119,
      digitalExam: false,
      categoryName: "Language",
      highGrade: "17",
      homeworkHelp: true,
      id: 121,
      lowGrade: "K",
      name: "Spanish (Level 3)",
      param: "spanish-level-3"
    }
  ],
  Math: [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 2,
      lowGrade: "6",
      name: "Algebra II",
      param: "algebra-ii"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 3,
      lowGrade: "9",
      name: "AP Calculus AB",
      param: "ap-calculus-ab"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 4,
      lowGrade: "9",
      name: "AP Calculus BC",
      param: "ap-calculus-bc"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: false,
      id: 60,
      lowGrade: "13",
      name: "Calculus (College-Level)",
      param: "calculus-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 58,
      lowGrade: "6",
      name: "Geometry",
      param: "geometry"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 7,
      lowGrade: "9",
      name: "Pre-Calculus",
      param: "pre-calculus"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 8,
      lowGrade: "9",
      name: "Statistics",
      param: "statistics"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Math",
      highGrade: "17",
      homeworkHelp: true,
      id: 9,
      lowGrade: "9",
      name: "Trigonometry",
      param: "trigonometry"
    }
  ],
  Science: [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: false,
      id: 90,
      lowGrade: "13",
      name: "Anatomy & Physiology (College-Level)",
      param: "anatomy-physiology-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: false,
      id: 76,
      lowGrade: "13",
      name: "Biology (College-Level)",
      param: "biology-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: false,
      id: 79,
      lowGrade: "13",
      name: "Chemistry (College-Level)",
      param: "chemistry-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: true,
      id: 15,
      lowGrade: "9",
      name: "Computer Science ",
      param: "computer-science"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: false,
      id: 178,
      lowGrade: "13",
      name: "Organic Chemistry (College-Level)",
      param: "organic-chemistry-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "17",
      homeworkHelp: false,
      id: 83,
      lowGrade: "13",
      name: "Physics (College-Level)",
      param: "physics-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Science",
      highGrade: "12",
      homeworkHelp: true,
      id: 75,
      lowGrade: "9",
      name: "Biology (Honors)",
      param: "biology-honors"
    }
  ],
  "Social Studies": [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: false,
      id: 98,
      lowGrade: "9",
      name: "Art History",
      param: "art-history"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 125,
      lowGrade: "9",
      name: "Economics",
      param: "economics"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 35,
      lowGrade: "9",
      name: "European History",
      param: "european-history"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 37,
      lowGrade: "9",
      name: "Human Geography ",
      param: "human-geography"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: false,
      id: 92,
      lowGrade: "13",
      name: "Macroeconomics (College-Level)",
      param: "macroeconomics-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: false,
      id: 94,
      lowGrade: "13",
      name: "Microeconomics (College-Level)",
      param: "microeconomics-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: false,
      id: 101,
      lowGrade: "9",
      name: "Music Theory",
      param: "music-theory"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: false,
      id: 85,
      lowGrade: "13",
      name: "Psychology (College-Level)",
      param: "psychology-college-level"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 40,
      lowGrade: "9",
      name: "U.S. Government",
      param: "u-s-government"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 41,
      lowGrade: "9",
      name: "U.S. History",
      param: "u-s-history"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Social Studies",
      highGrade: "17",
      homeworkHelp: true,
      id: 42,
      lowGrade: "9",
      name: "World History",
      param: "world-history"
    }
  ],
  "Test Prep": [
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Test Prep",
      highGrade: "17",
      homeworkHelp: false,
      id: 150,
      lowGrade: "PK",
      name: "Executive Functioning",
      param: "executive-functioning"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Test Prep",
      highGrade: "17",
      homeworkHelp: false,
      id: 44,
      lowGrade: "13",
      name: "GMAT",
      param: "gmat"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Test Prep",
      highGrade: "17",
      homeworkHelp: false,
      id: 45,
      lowGrade: "13",
      name: "GRE",
      param: "gre"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Test Prep",
      highGrade: "17",
      homeworkHelp: false,
      id: 48,
      lowGrade: "13",
      name: "LSAT",
      param: "lsat"
    },
    {
      aliasedSubjectId: null,
      digitalExam: false,
      categoryName: "Test Prep",
      highGrade: "17",
      homeworkHelp: false,
      id: 49,
      lowGrade: "13",
      name: "MCAT",
      param: "mcat"
    }
  ]
};
var testPrepSubjects = [
  {
    id: 43,
    aliasedSubjectId: 148,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "14",
    homeworkHelp: true,
    name: "ACT",
    param: "act",
    categoryName: "Test Prep"
  },
  {
    id: 47,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "0",
    highGrade: "12",
    homeworkHelp: false,
    name: "ISEE",
    param: "isee",
    categoryName: "Test Prep"
  },
  {
    id: 50,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "12",
    homeworkHelp: false,
    name: "PSAT/NMSQT",
    param: "psat-nmsqt",
    categoryName: "Test Prep"
  },
  {
    id: 51,
    aliasedSubjectId: 147,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "14",
    homeworkHelp: true,
    name: "SAT",
    param: "sat",
    categoryName: "Test Prep"
  },
  {
    id: 52,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "12",
    homeworkHelp: false,
    name: "SHSAT",
    param: "shsat",
    categoryName: "Test Prep"
  },
  {
    id: 53,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "0",
    highGrade: "12",
    homeworkHelp: false,
    name: "SSAT",
    param: "ssat",
    categoryName: "Test Prep"
  }
];
var subject = testPrepSubjects[3];
var studyAreaSubjects = [
  {
    id: 75,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "12",
    homeworkHelp: true,
    name: "Biology (Honors)",
    param: "biology-honors",
    categoryName: "Science"
  },
  {
    id: 11,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "12",
    homeworkHelp: true,
    name: "Biology (AP)",
    param: "biology-ap",
    categoryName: "Science"
  },
  {
    id: 2,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "17",
    homeworkHelp: true,
    name: "Algebra II",
    param: "algebra-ii",
    categoryName: "Math"
  },
  {
    id: 83,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Physics (College-Level)",
    param: "physics-college-level",
    categoryName: "Science"
  },
  {
    id: 119,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: true,
    name: "Spanish",
    param: "spanish",
    categoryName: "Language"
  },
  {
    id: 24,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Writing",
    param: "writing",
    categoryName: "English"
  },
  {
    id: 15,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Computer Science ",
    param: "computer-science",
    categoryName: "Science"
  },
  {
    id: 90,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Anatomy & Physiology (College-Level)",
    param: "anatomy-physiology-college-level",
    categoryName: "Science"
  },
  {
    id: 79,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Chemistry (College-Level)",
    param: "chemistry-college-level",
    categoryName: "Science"
  },
  {
    id: 4,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "AP Calculus BC",
    param: "ap-calculus-bc",
    categoryName: "Math"
  },
  {
    id: 8,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "Statistics",
    param: "statistics",
    categoryName: "Math"
  },
  {
    id: 58,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "6",
    highGrade: "17",
    homeworkHelp: true,
    name: "Geometry",
    param: "geometry",
    categoryName: "Math"
  },
  {
    id: 60,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Calculus (College-Level)",
    param: "calculus-college-level",
    categoryName: "Math"
  },
  {
    id: 101,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: false,
    name: "Music Theory",
    param: "music-theory",
    categoryName: "Social Studies"
  },
  {
    id: 23,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "K",
    highGrade: "17",
    homeworkHelp: false,
    name: "Reading Comprehension",
    param: "reading-comprehension",
    categoryName: "English"
  },
  {
    id: 35,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "European History",
    param: "european-history",
    categoryName: "Social Studies"
  },
  {
    id: 40,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "U.S. Government",
    param: "u-s-government",
    categoryName: "Social Studies"
  },
  {
    id: 92,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "Macroeconomics (College-Level)",
    param: "macroeconomics-college-level",
    categoryName: "Social Studies"
  },
  {
    id: 3,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "17",
    homeworkHelp: true,
    name: "AP Calculus AB",
    param: "ap-calculus-ab",
    categoryName: "Math"
  },
  {
    id: 44,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "GMAT",
    param: "gmat",
    categoryName: "Test Prep"
  },
  {
    id: 48,
    aliasedSubjectId: null,
    digitalExam: false,
    lowGrade: "13",
    highGrade: "17",
    homeworkHelp: false,
    name: "LSAT",
    param: "lsat",
    categoryName: "Test Prep"
  }
];
var digitalExamSubject = {
  id: 316,
  aliasedSubjectId: 50,
  digitalExam: true,
  lowGrade: "6",
  highGrade: "14",
  homeworkHelp: false,
  name: "PSAT/NMSQT",
  param: "psat-nmsqt",
  categoryName: "Test Prep"
};
var examReviewSubject = {
  id: 45,
  aliasedSubjectId: null,
  digitalExam: false,
  lowGrade: "13",
  highGrade: "17",
  homeworkHelp: false,
  name: "GRE Exam Review",
  param: "gre",
  categoryName: "Test Prep"
};

// src/lib/tier.ts
var tiers = [
  {
    id: 1,
    name: "Advanced",
    billingRate: 129,
    membershipBillingRate: 99
  },
  {
    id: 2,
    name: "Distinguished",
    billingRate: 179,
    membershipBillingRate: 149
  },
  {
    id: 3,
    name: "Premium",
    billingRate: 229,
    membershipBillingRate: 199
  },
  {
    id: 4,
    name: "Global Elite",
    billingRate: 699,
    membershipBillingRate: 669
  }
];
var tier = tiers[2];

// src/lib/tutor.ts
var avatarUrl2 = "https://place-hold.it/100";
var loremIpsum700Chars = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tincidunt neque mi, in pulvinar dui congue eget. Mauris fringilla ex interdum luctus vulputate. Nam ipsum elit, aliquet ac dapibus ac, fermentum eu nunc. Integer id ligula eget erat sodales sagittis. Ut at convallis mi, vel facilisis ante. Vivamus ultricies augue eros, eu consectetur felis congue vel. Cras congue lacinia dui, vitae volutpat nulla hendrerit vitae. Mauris nec lectus volutpat, dapibus velit et, tincidunt turpis. Donec semper scelerisque arcu quis dapibus. Mauris dui quam, varius vel arcu at, convallis vehicula odio. Pellentesque mollis volutpat lacus id sagittis. Ut risus velit, aliquam in venenatis a, mollis et in.";
var loremIpsum400Chars = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nunc enim, bibendum a pellentesque ut, sollicitudin vel tortor. Suspendisse accumsan tellus a blandit dapibus. Mauris cursus a diam iaculis sollicitudin. Sed rutrum ultrices dictum. Vestibulum id ipsum porta, tempor sapien non, ornare tellus. Nunc sem quam, posuere tempus nisl sit amet, mattis rutrum diam. Morbi pellentesque erat sit.";
var tutor = {
  abbreviatedName: "Walter W.",
  acceptNewStudents: true,
  active: true,
  acuityHandle: null,
  availabilityNote: null,
  availabilityTarget: 0,
  avatarLargeUrl: avatarUrl2,
  avatarUrl: avatarUrl2,
  calendarToken: null,
  createdAt: "2020-02-11T18:24:29-06:00",
  curriculumWork: false,
  deliveryTarget: 0,
  displayOnAdvisorSearch: true,
  displayOnWebsite: false,
  email: "walter.white@gmail.com",
  essayGrader: false,
  examsApiToken: "tutor-exams-api-token",
  extension: null,
  extensionHandle: null,
  facultyLeader: false,
  featured: false,
  firstName: "Walter",
  friendlyUrl: "//localhost:8888/tutors/walterwhite",
  fullName: "Walter White",
  gender: "Male",
  hireDate: "2020-02-12",
  id: 2468,
  lastInitial: "W",
  lastName: "White",
  lockAcceptNewStudents: false,
  online: true,
  param: "walterwhite",
  phone: "+12345678901",
  primaryAcuityUser: false,
  pushNotifications: true,
  reviewsCount: 1,
  roles: ["tutor"],
  scheduleUrl: null,
  starRating: 4.7074,
  stemSpecialist: false,
  teamMembershipId: 1111,
  tier: tiers[0],
  timeZone: "America/Chicago",
  title: "Tutor",
  trainingRate: 11,
  tutorType: "Professional",
  type: "Tutor",
  updatedAt: "2020-08-14T13:39:00-05:00",
  userId: 886644,
  address,
  stories: [],
  subjects,
  personalProfile: {
    id: 8642,
    approach: loremIpsum700Chars,
    background: loremIpsum400Chars,
    likes: null,
    dislikes: null,
    professionalTitle: null,
    educations: [
      {
        id: 246,
        graduationYear: null,
        institution: "Syracuse University",
        schoolId: 144444,
        degrees: [
          {
            id: 777,
            levelId: 3,
            levelName: "Bachelor of Science",
            major: "Biomedical Engineering",
            name: "Bachelor of Science in Biomedical Engineering",
            shortName: "B.S. in Biomedical Engineering"
          },
          {
            id: 778,
            levelId: 3,
            levelName: "Bachelor of Science",
            major: "Biochemistry",
            name: "Bachelor of Science in Biochemistry",
            shortName: "B.S. in Biochemistry"
          }
        ]
      }
    ]
  }
};
var tutors = [
  {
    abbreviatedName: "Professor B.",
    acceptNewStudents: true,
    active: true,
    acuityHandle: null,
    availabilityNote: "Days Off: Saturday and Sunday",
    availabilityTarget: 35,
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    createdAt: "2020-03-31T20:14:00-04:00",
    curriculumWork: false,
    deliveryTarget: 28,
    displayOnWebsite: false,
    email: "professorbirch@gmail.com",
    essayGrader: false,
    examsApiToken: null,
    extension: null,
    extensionHandle: null,
    facultyLeader: false,
    featured: true,
    firstName: "Professor",
    friendlyUrl: "//localhost:8888/tutors/professor-b",
    fullName: "Professor Birch",
    gender: "Male",
    hireDate: "2020-04-01",
    id: 2222,
    lastInitial: "B",
    lastName: "Birch",
    lockAcceptNewStudents: false,
    param: "professor-b",
    personalProfile: {
      id: 9999,
      approach: "Professor Birch's approach",
      background: "Professor Birch's background",
      likes: null,
      dislikes: null,
      professionalTitle: null,
      educations: [{
        id: 888,
        graduationYear: null,
        institution: "UCLA",
        schoolId: 3456,
        degrees: [{
          id: 777,
          levelId: 3,
          levelName: "Bachelor of Science",
          major: "Civil Engineering",
          name: "Bachelor of Science in Civil Engineering",
          shortName: "B.S. in Civil Engineering"
        }]
      }]
    },
    phone: "+12345678901",
    primaryAcuityUser: false,
    pushNotifications: false,
    reviewsCount: 2,
    roles: ["tutor"],
    starRating: 5,
    stemSpecialist: false,
    stories: [],
    subjects,
    teamMembershipId: 1357,
    tier: tiers[0],
    timeZone: "America/Los_Angeles",
    title: "Tutor",
    tutorType: "Professional",
    type: "Tutor",
    updatedAt: "2020-04-24T20:56:03-04:00",
    userId: 909090
  },
  {
    abbreviatedName: "Professor J.",
    acceptNewStudents: true,
    active: true,
    acuityHandle: null,
    availabilityNote: "Days Off: Monday and Friday",
    availabilityTarget: 28,
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    createdAt: "2020-03-31T20:08:39-04:00",
    curriculumWork: false,
    deliveryTarget: 20,
    displayOnWebsite: false,
    email: "professorjuniper@gmail.com",
    essayGrader: false,
    examsApiToken: null,
    extension: null,
    extensionHandle: null,
    facultyLeader: false,
    featured: true,
    firstName: "Professor",
    friendlyUrl: "//localhost:8888/tutors/professor-j",
    fullName: "Professor Juniper",
    gender: "Female",
    hireDate: "2020-04-01",
    id: 2525,
    lastInitial: "J",
    lastName: "Juniper",
    lockAcceptNewStudents: false,
    param: "professor-j",
    personalProfile: {
      id: 6060,
      approach: "Professor Juniper's approach",
      background: "Professor Juniper's background",
      likes: null,
      dislikes: null,
      professionalTitle: null,
      educations: [
        {
          id: 800,
          graduationYear: null,
          institution: "California State University-San Bernardino",
          schoolId: 131313,
          degrees: [{
            id: 808,
            levelId: 2,
            levelName: "Bachelor of Arts",
            major: "Mathematics and Environmental Studies",
            name: "Bachelor of Arts in Mathematics and Environmental Studies",
            shortName: "B.A. in Mathematics and Environmental Studies"
          }]
        },
        {
          id: 900,
          graduationYear: null,
          institution: "University of Nevada - Las Vegas",
          schoolId: 151515,
          degrees: [{
            id: 909,
            levelId: 3,
            levelName: "Bachelor of Science",
            major: "Environmental Engineering",
            name: "Bachelor of Science in Environmental Engineering",
            shortName: "B.S. in Environmental Engineering"
          }]
        }
      ]
    },
    phone: "+12345678901",
    primaryAcuityUser: false,
    pushNotifications: false,
    reviewsCount: 3,
    roles: ["tutor"],
    starRating: 5,
    stemSpecialist: false,
    stories: [],
    subjects,
    teamMembershipId: 1888,
    tier: tiers[0],
    timeZone: "America/Los_Angeles",
    title: "Tutor",
    tutorType: "Professional",
    type: "Tutor",
    updatedAt: "2020-04-23T13:26:42-04:00",
    userId: 922222
  }
];
var tutorsPartial = [
  {
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    id: 1234,
    acuityHandle: null,
    essayGrader: false,
    extension: null,
    extensionHandle: null,
    firstName: "Professor",
    lastInitial: "O",
    primaryAcuityUser: false,
    timeZone: "America/New_York",
    type: "Tutor",
    updatedAt: "2021-02-26T10:15:30-05:00",
    userId: 888888,
    active: true,
    roles: ["tutor", "faculty_leader"],
    abbreviatedName: "Professor O.",
    acceptNewStudents: true,
    createdAt: "2020-02-14T23:01:50-05:00",
    featured: false,
    friendlyUrl: "//localhost:8888/tutors/professor-o",
    lockAcceptNewStudents: false,
    param: "professor-o",
    reviewsCount: 49,
    starRating: 4.9388,
    title: "Faculty Leader",
    tutorType: "Professional",
    facultyLeader: true,
    curriculumWork: false,
    online: true,
    displayOnAdvisorSearch: true,
    displayOnWebsite: false,
    trainingRate: 10
  },
  {
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    id: 2222,
    acuityHandle: null,
    essayGrader: false,
    extension: null,
    extensionHandle: null,
    firstName: "Leslie",
    lastInitial: "K",
    primaryAcuityUser: false,
    timeZone: "America/Chicago",
    type: "Tutor",
    updatedAt: "2021-02-17T14:16:12-05:00",
    userId: 9e5,
    active: true,
    roles: ["tutor"],
    abbreviatedName: "Leslie K.",
    acceptNewStudents: true,
    createdAt: "2020-03-31T20:08:39-04:00",
    featured: false,
    friendlyUrl: "//localhost:8888/tutors/leslie-k",
    lockAcceptNewStudents: false,
    param: "leslie-k",
    reviewsCount: 10,
    starRating: 4.8,
    title: "Tutor",
    tutorType: "Restricted",
    facultyLeader: false,
    curriculumWork: false,
    online: true,
    displayOnAdvisorSearch: true,
    displayOnWebsite: false,
    trainingRate: 10
  }
];
var sessionSupervisor = {
  avatarUrl: avatarUrl2,
  avatarLargeUrl: avatarUrl2,
  id: 1223,
  acuityHandle: null,
  essayGrader: false,
  extensionHandle: null,
  firstName: "Erin",
  lastInitial: "H",
  primaryAcuityUser: false,
  timeZone: "America/New_York",
  type: "Tutor",
  updatedAt: "2020-06-22T08:12:44-07:00",
  userId: 1567,
  active: true,
  examsApiToken: null,
  roles: ["tutor"],
  abbreviatedName: "Erin H.",
  acceptNewStudents: false,
  availabilityNote: "My day off is Saturday.",
  availabilityTarget: 35,
  createdAt: "2016-07-14T12:37:00-07:00",
  curriculumWork: true,
  deliveryTarget: 28,
  displayOnWebsite: false,
  featured: false,
  friendlyUrl: "//localhost:8888/tutors/erin-h",
  gender: "Female",
  hireDate: "2016-07-25",
  param: "erin-h",
  reviewsCount: 71,
  starRating: 4.8592,
  stemSpecialist: false,
  teamMembershipId: 1360,
  title: "Tutor",
  tutorType: "Professional",
  facultyLeader: false,
  subjects
};
var sessionSupervisors = [
  {
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    id: 2020,
    acuityHandle: null,
    essayGrader: false,
    extensionHandle: null,
    firstName: "Andy",
    lastInitial: "B",
    primaryAcuityUser: false,
    timeZone: "America/New_York",
    type: "Tutor",
    updatedAt: "2020-04-27T13:02:07-04:00",
    userId: 898989,
    active: true,
    examsApiToken: null,
    roles: ["tutor"],
    abbreviatedName: "Andy B.",
    acceptNewStudents: true,
    availabilityNote: "Days Off - Friday & Saturday",
    availabilityTarget: 35,
    createdAt: "2020-02-14T23:01:50-05:00",
    curriculumWork: false,
    deliveryTarget: 28,
    displayOnWebsite: false,
    featured: false,
    friendlyUrl: "//localhost:8888/tutors/andy-b",
    gender: "Male",
    hireDate: "2020-02-22",
    param: "andy-b",
    reviewsCount: 13,
    starRating: 4.7692,
    stemSpecialist: false,
    teamMembershipId: 1240,
    title: "Tutor",
    tutorType: "Professional",
    facultyLeader: false
  },
  {
    avatarUrl: avatarUrl2,
    avatarLargeUrl: avatarUrl2,
    id: 12,
    acuityHandle: null,
    essayGrader: false,
    extensionHandle: null,
    firstName: "Kelly",
    lastInitial: "K",
    primaryAcuityUser: false,
    timeZone: "America/New_York",
    type: "Tutor",
    updatedAt: "2020-04-27T15:01:54-04:00",
    userId: 12,
    active: true,
    examsApiToken: null,
    roles: ["tutor", "faculty_leader"],
    abbreviatedName: "Kelly K.",
    acceptNewStudents: false,
    availabilityNote: "Faculty Leader",
    availabilityTarget: 35,
    createdAt: "2017-04-18T17:07:25-04:00",
    curriculumWork: true,
    deliveryTarget: 28,
    displayOnWebsite: true,
    featured: false,
    friendlyUrl: "//localhost:8888/tutors/kelly-k",
    gender: "Female",
    hireDate: "2011-08-04",
    param: "kelly-k",
    reviewsCount: 188,
    starRating: 4.7074,
    stemSpecialist: false,
    teamMembershipId: null,
    title: "Faculty Leader",
    tutorType: "Professional",
    facultyLeader: true
  }
];

// src/lib/course.ts
var course = {
  id: 101,
  availableForSale: false,
  availableForSaleError: "has ended",
  batchEnroll: false,
  createdAt: "2017-08-31T00:01:04-07:00",
  deliveredDuration: 240,
  distance: null,
  duration: 240,
  dynamicSubject: false,
  endsAt: "2015-12-12T06:00:00-08:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-101",
  enrollmentCap: 78,
  enrollmentsCount: 68,
  firstSessionAt: null,
  fullName: "ACT Practice Exam",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: 12345,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: "2015-12-12T06:00:00-08:00",
  notes: null,
  parentUpdateLastSentAt: null,
  possibleEvents: [
    "unpublish",
    "cancel"
  ],
  provisionedDuration: 0,
  remainingProvisionedDuration: 0,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [
    {
      name: "Practice Exam",
      count: 1,
      days: [
        "Saturday at 6:00AM"
      ],
      timeZone: "PST"
    }
  ],
  startingEpisodeNumber: null,
  startsAt: "2015-12-12T06:00:00-08:00",
  title: "ACT Practice Exam",
  tutorScheduling: false,
  type: "Course",
  unscheduledDuration: -240,
  updatedAt: "2018-01-17T14:32:06-08:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: true,
  thirdParty: false,
  shipmentsPresent: true
};
var coursePrivateTutoring = {
  id: 12345,
  availableForSale: false,
  availableForSaleError: "has no more seats remaining",
  batchEnroll: false,
  createdAt: "2017-08-31T08:59:56-04:00",
  deliveredDuration: 10044,
  distance: null,
  duration: 13314,
  dynamicSubject: false,
  endsAt: "2021-07-26T00:00:00-04:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-12345",
  enrollmentCap: 1,
  enrollmentsCount: 1,
  firstSessionAt: null,
  fullName: "Private Tutoring",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: "2016-02-22T16:30:00-05:00",
  notes: "These are some notes",
  parentUpdateLastSentAt: "2017-12-05T00:40:14-05:00",
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 13704,
  remainingProvisionedDuration: 3660,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [
    {
      name: "Tutoring",
      count: 157,
      days: [],
      timeZone: "EDT"
    }
  ],
  startingEpisodeNumber: null,
  startsAt: "2016-02-22T16:30:00-05:00",
  title: "Private Tutoring",
  tutorScheduling: true,
  type: "Course",
  unscheduledDuration: 390,
  updatedAt: "2021-06-11T20:29:17-04:00",
  status: "published",
  requiresParentUpdate: true,
  staffed: true,
  thirdParty: false,
  shipmentsPresent: false,
  host: null
};
var coursePrivateTutoringWithBrand = {
  id: 12234,
  availableForSale: false,
  availableForSaleError: "has no more seats remaining",
  batchEnroll: false,
  createdAt: "2018-05-16T11:22:27-07:00",
  deliveredDuration: 19245,
  distance: null,
  duration: 21945,
  dynamicSubject: false,
  endsAt: "2021-03-28T18:15:00-07:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-12234",
  enrollmentCap: 1,
  enrollmentsCount: 1,
  firstSessionAt: null,
  fullName: "Private Tutoring",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: "2018-07-30T12:00:00-07:00",
  notes: "Some notes",
  parentUpdateLastSentAt: "2020-12-14T15:32:03-08:00",
  possibleEvents: [
    "unpublish",
    "cancel"
  ],
  provisionedDuration: 21960,
  remainingProvisionedDuration: 2715,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [
    {
      name: "Tutoring",
      count: 216,
      days: [],
      timeZone: "PDT"
    }
  ],
  startingEpisodeNumber: null,
  startsAt: "2018-07-30T12:00:00-07:00",
  title: "Private Tutoring",
  tutorScheduling: true,
  type: "Course",
  unscheduledDuration: 15,
  updatedAt: "2020-12-14T15:32:03-08:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: true,
  thirdParty: false,
  shipmentsPresent: false,
  brand: brandPrivateTutoring
};
var courseTutoringAlgebraII = {
  id: 44444,
  availableForSale: false,
  availableForSaleError: "has no more seats remaining",
  batchEnroll: false,
  createdAt: "2021-03-24T12:52:36-04:00",
  deliveredDuration: 0,
  distance: null,
  duration: 120,
  dynamicSubject: false,
  endsAt: "2021-06-09T14:00:00-04:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-44638",
  enrollmentCap: 1,
  enrollmentsCount: 1,
  firstSessionAt: null,
  fullName: "Algebra II Tutoring",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: 8,
  name: null,
  nextStartsAt: "2021-05-06T09:00:00-04:00",
  notes: null,
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 300,
  remainingProvisionedDuration: 300,
  requireSubject: true,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [],
  startingEpisodeNumber: null,
  startsAt: "2021-05-06T09:00:00-04:00",
  title: "Algebra II Tutoring",
  tutorScheduling: true,
  type: "Course",
  unscheduledDuration: 180,
  updatedAt: "2021-06-10T14:49:33-04:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: false,
  thirdParty: false,
  shipmentsPresent: false,
  host: tutorsPartial[0]
};
var courseTutoringGeometry = {
  id: 44668,
  availableForSale: false,
  availableForSaleError: "has no more seats remaining",
  batchEnroll: false,
  brand: brandTutoring,
  createdAt: "2021-04-09T15:49:12-04:00",
  deliveredDuration: 0,
  distance: null,
  duration: 240,
  dynamicSubject: false,
  endsAt: "2021-06-17T20:00:00-04:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-44668",
  enrollmentCap: 1,
  enrollmentsCount: 1,
  firstSessionAt: null,
  fullName: "Geometry Tutoring",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: 8,
  name: null,
  nextStartsAt: "2021-05-17T17:00:00-04:00",
  notes: null,
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 300,
  remainingProvisionedDuration: 300,
  requireSubject: true,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [],
  startingEpisodeNumber: null,
  startsAt: "2021-05-17T17:00:00-04:00",
  title: "Geometry Tutoring",
  tutorScheduling: true,
  type: "Course",
  unscheduledDuration: 60,
  updatedAt: "2021-06-15T17:52:49-04:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: false,
  thirdParty: false,
  shipmentsPresent: false,
  host: tutorsPartial[1]
};
var courseRevolutionNowLimited = {
  id: 15555,
  availableForSale: true,
  availableForSaleError: null,
  batchEnroll: false,
  createdAt: "2018-01-15T20:36:03-08:00",
  deliveredDuration: 0,
  distance: null,
  duration: 0,
  dynamicSubject: false,
  endsAt: null,
  enrollUrl: "//localhost:9000/checkout/add/Course-15555",
  enrollmentCap: null,
  enrollmentsCount: 39713,
  firstSessionAt: null,
  fullName: "Revolution Now",
  gradeLevel: null,
  grantManualAttendance: true,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: null,
  notes: "DO NOT DELETE OR EDIT THIS COURSE. SPEAK TO JULIE.",
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 0,
  remainingProvisionedDuration: 0,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [],
  startingEpisodeNumber: null,
  startsAt: null,
  title: "Revolution Now",
  tutorScheduling: false,
  type: "Course",
  unscheduledDuration: 0,
  updatedAt: "2018-08-16T20:39:52-07:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: false,
  thirdParty: false,
  shipmentsPresent: false
};
var courseMembership = {
  id: 273,
  availableForSale: true,
  availableForSaleError: null,
  batchEnroll: false,
  createdAt: "2017-08-29T22:24:40-04:00",
  deliveredDuration: 0,
  distance: null,
  duration: 0,
  dynamicSubject: false,
  endsAt: null,
  enrollUrl: "//localhost:9000/checkout/add/Course-273",
  enrollmentCap: null,
  enrollmentsCount: 8760,
  firstSessionAt: null,
  fullName: "Membership",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: null,
  notes: "DO NOT edit this course. This is our Revolution Prep Membership Course.",
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 0,
  remainingProvisionedDuration: 0,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [],
  startingEpisodeNumber: null,
  startsAt: null,
  title: "Membership",
  tutorScheduling: false,
  type: "Course",
  unscheduledDuration: 0,
  updatedAt: "2018-08-16T23:39:52-04:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: false,
  thirdParty: false,
  shipmentsPresent: false,
  deliverable: deliverableMembership
};
var courseSATPracticeExam = {
  id: 800,
  availableForSale: false,
  availableForSaleError: "has ended",
  batchEnroll: false,
  createdAt: "2017-08-31T00:08:04-07:00",
  deliveredDuration: 240,
  distance: null,
  duration: 240,
  dynamicSubject: false,
  endsAt: "2017-05-13T10:00:00-07:00",
  enrollUrl: "//localhost:9000/checkout/add/Course-800",
  enrollmentCap: 51,
  enrollmentsCount: 49,
  firstSessionAt: null,
  fullName: "SAT Practice Exam",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: 39805,
  maxWeeklySessions: null,
  name: "",
  nextStartsAt: "2017-05-13T10:00:00-07:00",
  notes: "",
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 0,
  remainingProvisionedDuration: 0,
  requireSubject: false,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [
    {
      name: "Practice Exam",
      count: 1,
      days: [],
      timeZone: "PST"
    }
  ],
  startingEpisodeNumber: null,
  startsAt: "2017-05-13T10:00:00-07:00",
  title: "SAT Practice Exam",
  tutorScheduling: false,
  type: "Course",
  unscheduledDuration: -240,
  updatedAt: "2018-01-17T14:32:10-08:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: true,
  thirdParty: false,
  shipmentsPresent: true,
  leadSource: libraryLeadSource
};
var courseSelfStudy = {
  id: 75137,
  availableForSale: false,
  availableForSaleError: "has no more seats remaining",
  batchEnroll: true,
  blockEmails: false,
  createdAt: "2024-10-07T13:37:34-07:00",
  deliveredDuration: 0,
  distance: null,
  duration: 0,
  dynamicSubject: false,
  endsAt: null,
  enrollUrl: "//localhost:9000/checkout/add/Course-75137",
  enrollmentCap: 1,
  enrollmentsCount: 1,
  firstSessionAt: null,
  fullName: "ACT 12-Week Self Study",
  gradeLevel: null,
  grantManualAttendance: false,
  lastSessionAt: null,
  legacyId: null,
  maxWeeklySessions: null,
  name: null,
  nextStartsAt: null,
  notes: null,
  parentUpdateLastSentAt: null,
  possibleEvents: ["unpublish", "cancel"],
  provisionedDuration: 0,
  remainingProvisionedDuration: 0,
  requireSubject: true,
  reservationEndsAt: null,
  restrictByLeadSource: false,
  schedule: [],
  startingEpisodeNumber: null,
  startsAt: null,
  title: "ACT 12-Week Self Study",
  tutorScheduling: false,
  type: "Course",
  unscheduledDuration: 0,
  updatedAt: "2024-10-07T13:37:39-07:00",
  status: "published",
  requiresParentUpdate: false,
  staffed: false,
  thirdParty: false,
  segment: {
    name: "12 Week",
    active: true,
    createdAt: "2024-10-11 22:07:38",
    handle: "12-week-department-1",
    id: 52,
    updatedAt: "2024-10-11 22:07:38"
  },
  shipmentsPresent: false,
  subject: {
    id: 43,
    aliasedSubjectId: 148,
    digitalExam: false,
    lowGrade: "9",
    highGrade: "14",
    homeworkHelp: true,
    name: "ACT",
    param: "act",
    categoryName: "Test Prep"
  }
};

// src/lib/session-allotment.ts
var sessionAllotment = {
  id: 567890,
  categoryName: "Math",
  createdAt: "2021-06-28T14:16:24-04:00",
  minutes: 0,
  subjectId: 2,
  subjectName: "Algebra II",
  updatedAt: "2021-06-28T14:16:24-04:00"
};
var sessionAllotments = [
  {
    id: 455555,
    categoryName: "Test Prep",
    createdAt: "2020-10-09T14:09:57-07:00",
    minutes: 0,
    subjectId: 51,
    subjectName: "SAT",
    updatedAt: "2020-10-09T14:09:57-07:00"
  },
  {
    ...sessionAllotment,
    id: 455556,
    createdAt: "2020-10-09T14:09:57-07:00",
    updatedAt: "2020-10-09T14:09:57-07:00"
  }
];

// src/lib/zoom-meeting.ts
var zoomMeeting = {
  id: 555678,
  handle: "99999999999",
  joinUrl: "https://revolutionprep.zoom.us/j/99999999999"
};

// src/lib/session.ts
var session = {
  id: 767676,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesJoinedCount: 0,
  canAttend: true,
  canCancel: false,
  canLaunch: false,
  canEdit: false,
  createdAt: "2020-09-14T09:23:55-07:00",
  description: "Algebra II",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2020-09-29T08:00:00-07:00",
  identifier: "RN",
  inPast: false,
  inPerson: false,
  name: "",
  notes: null,
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  recordingsReady: false,
  room: null,
  sequence: 4,
  startsAt: "2020-09-29T07:00:00-07:00",
  studentEditable: false,
  subjectId: 2,
  supervisorId: 1223,
  title: "Algebra II",
  type: "Session::ManuallyAttendable",
  updatedAt: "2020-09-24T12:43:15-07:00",
  launchable: false,
  isTutoring: false,
  launchedAt: null,
  supervisor: sessionSupervisor,
  sessionSubject: subjects[0],
  zoomMeeting
};
var sessionNoTitle = {
  ...session,
  title: void 0
};
var sessionNoAvatarUrl = {
  ...session,
  supervisor: {
    ...sessionSupervisor,
    avatarUrl: ""
  }
};
var sessions = [
  {
    id: 727272,
    assignmentsCount: 1,
    attendanceTakenAt: null,
    attendancesAttendedCount: 0,
    attendancesCount: 1,
    attendancesJoinedCount: 0,
    canAttend: true,
    canCancel: false,
    canLaunch: false,
    canEdit: false,
    createdAt: "2021-01-25T12:50:03-05:00",
    description: "Tutoring with Ash K.",
    duration: 90,
    employeesRequired: 1,
    endsAt: "2021-01-26T11:30:00-05:00",
    identifier: "PT",
    inPerson: false,
    name: "Tutoring",
    notes: null,
    online: true,
    parentUpdateId: null,
    parentUpdateSentAt: null,
    problems: [],
    promotionSubject: null,
    recordingsReady: false,
    room: null,
    segmentName: "Premium",
    sequence: 130,
    startsAt: "2021-01-26T10:00:00-05:00",
    studentEditable: false,
    subjectId: null,
    supervisorId: 2020,
    suppressAttendance: false,
    title: "Tutoring",
    type: "Tutoring",
    updatedAt: "2021-01-25T12:50:03-05:00",
    launchable: false,
    isPast: true,
    isTutoring: true,
    launchedAt: null,
    supervisor: sessionSupervisors[0]
  },
  {
    id: 797979,
    assignmentsCount: 1,
    attendanceTakenAt: null,
    attendancesAttendedCount: 0,
    attendancesCount: 1,
    attendancesJoinedCount: 0,
    canAttend: true,
    canCancel: false,
    canLaunch: false,
    canEdit: false,
    createdAt: "2020-12-01T16:30:06-05:00",
    description: "SAT Exam Review",
    duration: 60,
    employeesRequired: 1,
    endsAt: "2021-01-29T11:00:00-05:00",
    identifier: "RN",
    inPerson: false,
    name: "",
    notes: null,
    online: true,
    parentUpdateId: null,
    parentUpdateSentAt: null,
    problems: [],
    promotionSubject: null,
    recordingsReady: false,
    room: null,
    segmentName: null,
    sequence: 8,
    startsAt: "2021-01-29T10:00:00-05:00",
    studentEditable: false,
    subjectId: 147,
    supervisorId: 12,
    suppressAttendance: false,
    title: "SAT Exam Review",
    type: "Session::ManuallyAttendable",
    updatedAt: "2021-01-22T20:47:21-05:00",
    launchable: false,
    isPast: false,
    isTutoring: false,
    launchedAt: null,
    supervisor: sessionSupervisors[1]
  }
];
var sessionBiologyHonors = {
  id: 777777,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: true,
  canCancel: false,
  canLaunch: false,
  canEdit: false,
  createdAt: "2020-12-08T14:32:48-05:00",
  description: "Biology (Honors)",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2020-12-11T22:00:00-05:00",
  identifier: "RN",
  inPerson: false,
  name: "",
  notes: null,
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: false,
  room: null,
  segmentName: null,
  sequence: 1,
  startsAt: "2020-12-11T21:00:00-05:00",
  studentEditable: false,
  subjectId: 75,
  supervisorId: 1234,
  suppressAttendance: false,
  title: "Biology (Honors)",
  type: "Session::ManuallyAttendable",
  updatedAt: "2020-12-08T14:35:52-05:00",
  launchable: false,
  isPast: false,
  isTutoring: false,
  launchedAt: null
};
var sessionACT = {
  id: 1555555,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: true,
  canCancel: false,
  canLaunch: false,
  canEdit: false,
  createdAt: "2021-09-29T12:23:02-07:00",
  description: "ACT",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2021-10-07T12:00:00-07:00",
  identifier: "RN",
  inPerson: false,
  name: "",
  notes: null,
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: false,
  room: null,
  segmentName: null,
  sequence: 6,
  startsAt: "2021-10-07T11:00:00-07:00",
  studentEditable: false,
  subjectId: 43,
  supervisorId: 1234,
  suppressAttendance: false,
  title: "ACT",
  type: "Session::ManuallyAttendable",
  updatedAt: "2021-10-06T17:46:40-07:00",
  launchable: false,
  isPast: false,
  isTutoring: false,
  launchedAt: null,
  connectAt: "2021-10-07T10:45:00-07:00"
};
var sessionAlgebraII = {
  id: 722222,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: true,
  canCancel: false,
  canLaunch: false,
  canEdit: false,
  createdAt: "2020-12-01T16:29:32-05:00",
  description: "Algebra II",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2020-12-18T11:00:00-05:00",
  identifier: "RN",
  inPerson: false,
  name: "",
  notes: null,
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: false,
  room: null,
  segmentName: null,
  sequence: 2,
  startsAt: "2020-12-18T10:00:00-05:00",
  studentEditable: false,
  subjectId: 2,
  supervisorId: 1234,
  suppressAttendance: false,
  title: "Algebra II",
  type: "Session::ManuallyAttendable",
  updatedAt: "2020-12-08T13:53:43-05:00",
  launchable: false,
  isPast: false,
  isTutoring: false,
  launchedAt: null
};
var sessionGeometry = {
  id: 999999,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: false,
  canCancel: true,
  canLaunch: false,
  canEdit: false,
  createdAt: "2021-06-30T17:39:06-04:00",
  description: "Tutoring",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2021-07-05T19:00:00-04:00",
  identifier: "T",
  inPerson: false,
  name: "Tutoring",
  notes: null,
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: null,
  room: null,
  segmentName: "Advanced",
  sequence: 92,
  startsAt: "2021-07-05T18:00:00-04:00",
  studentEditable: true,
  subjectId: 58,
  supervisorId: 2222,
  suppressAttendance: false,
  title: "Geometry Tutoring",
  type: "Tutoring",
  updatedAt: "2021-06-30T17:39:06-04:00",
  launchable: false,
  isPast: false,
  isTutoring: true,
  launchedAt: null
};
var sessionTutoring = {
  id: 848484,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: false,
  canCancel: true,
  canLaunch: false,
  canEdit: false,
  createdAt: "2020-10-09T14:09:56-07:00",
  description: "Tutoring with Danny S.",
  duration: 120,
  employeesRequired: 1,
  endsAt: "2021-03-21T18:15:00-07:00",
  identifier: "PT",
  inPerson: false,
  name: "Tutoring",
  notes: "Some notes",
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: null,
  room: null,
  segmentName: "Premium",
  sequence: 215,
  startsAt: "2021-03-21T16:15:00-07:00",
  studentEditable: false,
  subjectId: null,
  supervisorId: 44,
  suppressAttendance: false,
  title: "Tutoring",
  type: "Tutoring",
  updatedAt: "2020-10-09T14:09:56-07:00",
  launchable: false,
  isPast: true,
  isTutoring: true,
  launchedAt: null,
  supervisor: sessionSupervisor,
  course: coursePrivateTutoringWithBrand,
  sessionSubject: null,
  allotments: sessionAllotments,
  zoomMeeting
};

// src/lib/attendance.ts
var attendance = {
  id: 1333333,
  attended: false,
  attendeeId: 123456,
  avatarUrl: null,
  createdAt: "2020-12-08T14:36:00-05:00",
  employeeId: null,
  firstName: "Ash",
  fullName: "Ash Ketchum",
  joinedAt: null,
  lastName: "Ketchum",
  notes: null,
  points: 0,
  sessionId: 777777,
  studentId: 123456,
  parentId: null,
  type: "Attendance",
  updatedAt: "2020-12-08T14:36:00-05:00",
  session: sessionBiologyHonors
};
var dynamicSessionAttendances = [
  {
    id: 1886644,
    attended: false,
    attendeeId: 123456,
    avatarUrl: null,
    createdAt: "2021-10-06T17:46:49-07:00",
    employeeId: null,
    firstName: "Ash",
    fullName: "Ash Ketchum",
    joinedAt: null,
    lastName: "Ketchum",
    notes: null,
    points: 0,
    sessionId: 1555555,
    studentId: 123456,
    subjectId: 43,
    parentId: null,
    type: "Attendance",
    updatedAt: "2021-10-06T17:46:49-07:00",
    session: sessionACT
  }
];
var attendances = [
  {
    id: 1357911,
    attended: false,
    attendeeId: 123456,
    avatarUrl: null,
    createdAt: "2020-12-08T13:53:49-05:00",
    employeeId: null,
    firstName: "Ash",
    fullName: "Ash Ketchum",
    joinedAt: null,
    lastName: "Ketchum",
    notes: null,
    points: 0,
    sessionId: 729595,
    studentId: 123456,
    parentId: null,
    type: "Attendance",
    updatedAt: "2020-12-08T13:53:49-05:00",
    session: sessionAlgebraII
  },
  { ...attendance }
];
var blockoutAttendances = [
  {
    id: 1777777,
    attended: false,
    attendeeId: 123456,
    avatarUrl: null,
    createdAt: "2021-06-30T17:39:06-04:00",
    employeeId: null,
    firstName: "Ash",
    fullName: "Ash Ketchum",
    joinedAt: null,
    lastName: "Ketchum",
    notes: null,
    points: 0,
    sessionId: 975078,
    studentId: 123456,
    parentId: null,
    type: "AttendanceBlockout",
    updatedAt: "2021-06-30T17:39:25-04:00",
    session: sessionGeometry
  }
];
var attendanceTutoring = {
  id: 1778899,
  attended: false,
  attendeeId: 123456,
  avatarUrl: null,
  createdAt: "2021-06-28T14:16:25-04:00",
  employeeId: null,
  firstName: "Ash",
  fullName: "Ash Ketchum",
  joinedAt: null,
  lastName: "Ketchum",
  notes: null,
  points: 0,
  sessionId: 977777,
  studentId: 123456,
  parentId: null,
  type: "Attendance",
  updatedAt: "2021-06-28T14:16:25-04:00"
};

// src/lib/authorize-net-profile.ts
var authorizeNetProfile = {
  id: 12345,
  customerProfileHandle: "1234599999",
  paymentProfileHandle: "1555555555",
  maskedCardNumber: "4242",
  cardType: "Visa",
  expirationDate: "2024-02-01",
  createdAt: "2020-10-06T18:27:38-06:00",
  updatedAt: "2020-10-08T10:50:18-10:00"
};

// src/lib/availability.ts
var availabilities = [
  {
    id: 2e6,
    startsAt: "2021-03-28T08:00:00-04:00",
    endsAt: "2021-03-28T09:00:00-04:00",
    duration: 60,
    createdAt: "2021-02-23T09:11:41-05:00",
    updatedAt: "2021-02-23T09:11:41-05:00",
    locked: null
  },
  {
    id: 2000001,
    startsAt: "2021-03-28T11:30:00-04:00",
    endsAt: "2021-03-28T13:00:00-04:00",
    duration: 90,
    createdAt: "2021-01-18T17:39:03-05:00",
    updatedAt: "2021-01-18T17:39:03-05:00",
    locked: null
  },
  {
    id: 2000002,
    startsAt: "2021-03-28T22:00:00-04:00",
    endsAt: "2021-03-29T00:00:00-04:00",
    duration: 120,
    createdAt: "2021-02-26T16:49:46-05:00",
    updatedAt: "2021-02-26T16:49:46-05:00",
    locked: null
  },
  {
    id: 2000003,
    startsAt: "2021-03-29T09:00:00-04:00",
    endsAt: "2021-03-29T13:30:00-04:00",
    duration: 270,
    createdAt: "2021-02-27T12:04:25-05:00",
    updatedAt: "2021-02-27T12:04:25-05:00",
    locked: null
  },
  {
    id: 2000004,
    startsAt: "2021-03-29T15:30:00-04:00",
    endsAt: "2021-03-29T18:30:00-04:00",
    duration: 180,
    createdAt: "2020-07-01T00:13:22-04:00",
    updatedAt: "2020-07-01T00:13:22-04:00",
    locked: null
  },
  {
    id: 2000005,
    startsAt: "2021-03-29T21:00:00-04:00",
    endsAt: "2021-03-29T23:00:00-04:00",
    duration: 120,
    createdAt: "2021-03-01T23:10:10-05:00",
    updatedAt: "2021-03-01T23:10:10-05:00",
    locked: null
  },
  {
    id: 2000006,
    startsAt: "2021-03-30T09:00:00-04:00",
    endsAt: "2021-03-30T13:30:00-04:00",
    duration: 270,
    createdAt: "2021-03-23T12:12:17-04:00",
    updatedAt: "2021-03-23T12:12:17-04:00",
    locked: null
  },
  {
    id: 2000007,
    startsAt: "2021-03-30T17:00:00-04:00",
    endsAt: "2021-03-30T18:30:00-04:00",
    duration: 90,
    createdAt: "2021-02-06T17:02:56-05:00",
    updatedAt: "2021-02-06T17:02:56-05:00",
    locked: null
  },
  {
    id: 2000008,
    startsAt: "2021-03-30T23:00:00-04:00",
    endsAt: "2021-03-31T00:00:00-04:00",
    duration: 60,
    createdAt: "2021-02-09T22:17:20-05:00",
    updatedAt: "2021-02-09T22:17:20-05:00",
    locked: null
  },
  {
    id: 2000009,
    startsAt: "2021-03-31T09:00:00-04:00",
    endsAt: "2021-03-31T12:00:00-04:00",
    duration: 180,
    createdAt: "2021-02-26T10:00:13-05:00",
    updatedAt: "2021-02-26T10:00:13-05:00",
    locked: null
  },
  {
    id: 2000010,
    startsAt: "2021-03-31T13:00:00-04:00",
    endsAt: "2021-03-31T14:00:00-04:00",
    duration: 60,
    createdAt: "2021-02-26T10:00:13-05:00",
    updatedAt: "2021-02-26T10:00:13-05:00",
    locked: null
  },
  {
    id: 2000011,
    startsAt: "2021-03-31T16:30:00-04:00",
    endsAt: "2021-03-31T18:00:00-04:00",
    duration: 90,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  },
  {
    id: 2000012,
    startsAt: "2021-03-31T15:45:00-04:00",
    endsAt: "2021-03-31T17:15:00-04:00",
    duration: 90,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  },
  {
    id: 2888888,
    startsAt: "2021-03-31T15:30:00-04:00",
    endsAt: "2021-03-31T17:00:00-04:00",
    duration: 90,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  },
  {
    id: 2777777,
    startsAt: "2021-03-31T15:15:00-04:00",
    endsAt: "2021-03-31T16:45:00-04:00",
    duration: 90,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  },
  {
    id: 2777778,
    startsAt: "2021-03-31T15:05:00-04:00",
    endsAt: "2021-03-31T16:45:00-04:00",
    duration: 60,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  },
  {
    id: 2777779,
    startsAt: "2021-03-31T15:33:00-04:00",
    endsAt: "2021-03-31T16:45:00-04:00",
    duration: 60,
    createdAt: "2021-02-21T09:55:13-05:00",
    updatedAt: "2021-02-21T09:55:13-05:00",
    locked: null
  }
];
var availability = {
  id: 2999999,
  startsAt: "2021-05-23T08:00:00-04:00",
  endsAt: "2021-05-23T13:00:00-04:00",
  duration: 300,
  createdAt: "2021-01-18T17:39:20-05:00",
  updatedAt: "2021-05-17T17:05:14-04:00",
  locked: null,
  slots: []
};
var tutorAvailabilities = [
  {
    id: 4801375,
    startsAt: "2023-10-08T05:00:00-07:00",
    endsAt: "2023-10-08T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:56-08:00",
    updatedAt: "2023-01-29T17:30:56-08:00",
    locked: null
  },
  {
    id: 4801392,
    startsAt: "2023-10-08T12:00:00-07:00",
    endsAt: "2023-10-08T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:59-08:00",
    updatedAt: "2023-01-29T17:30:59-08:00",
    locked: null
  },
  {
    id: 4847705,
    startsAt: "2023-10-08T18:30:00-07:00",
    endsAt: "2023-10-08T19:30:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:37:53-08:00",
    updatedAt: "2023-02-12T14:37:53-08:00",
    locked: true
  },
  {
    id: 4888154,
    startsAt: "2023-10-09T05:00:00-07:00",
    endsAt: "2023-10-09T06:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-03T20:56:47-08:00",
    updatedAt: "2023-03-03T20:56:47-08:00",
    locked: null
  },
  {
    id: 4883166,
    startsAt: "2023-10-09T06:00:00-07:00",
    endsAt: "2023-10-09T07:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-01T13:12:17-08:00",
    updatedAt: "2023-03-03T20:56:47-08:00",
    locked: true
  },
  {
    id: 4888155,
    startsAt: "2023-10-09T07:00:00-07:00",
    endsAt: "2023-10-09T10:00:00-07:00",
    duration: 180,
    createdAt: "2023-03-03T20:56:47-08:00",
    updatedAt: "2023-03-03T20:56:47-08:00",
    locked: null
  },
  {
    id: 4801453,
    startsAt: "2023-10-09T10:00:00-07:00",
    endsAt: "2023-10-09T11:00:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:11-08:00",
    updatedAt: "2023-03-01T13:12:17-08:00",
    locked: true
  },
  {
    id: 4848007,
    startsAt: "2023-10-09T12:00:00-07:00",
    endsAt: "2023-10-09T16:00:00-07:00",
    duration: 240,
    createdAt: "2023-02-12T14:57:52-08:00",
    updatedAt: "2023-02-12T14:57:52-08:00",
    locked: null
  },
  {
    id: 4848006,
    startsAt: "2023-10-09T16:00:00-07:00",
    endsAt: "2023-10-09T17:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:52-08:00",
    updatedAt: "2023-02-12T14:57:52-08:00",
    locked: true
  },
  {
    id: 4848008,
    startsAt: "2023-10-09T17:00:00-07:00",
    endsAt: "2023-10-09T18:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:52-08:00",
    updatedAt: "2023-02-12T14:57:52-08:00",
    locked: null
  },
  {
    id: 4801531,
    startsAt: "2023-10-10T05:00:00-07:00",
    endsAt: "2023-10-10T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:25-08:00",
    updatedAt: "2023-01-29T17:31:25-08:00",
    locked: null
  },
  {
    id: 4801548,
    startsAt: "2023-10-10T12:00:00-07:00",
    endsAt: "2023-10-10T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:27-08:00",
    updatedAt: "2023-01-29T17:31:27-08:00",
    locked: null
  },
  {
    id: 4801609,
    startsAt: "2023-10-11T05:00:00-07:00",
    endsAt: "2023-10-11T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:40-08:00",
    updatedAt: "2023-01-29T17:31:40-08:00",
    locked: null
  },
  {
    id: 4989913,
    startsAt: "2023-10-11T12:00:00-07:00",
    endsAt: "2023-10-11T15:00:00-07:00",
    duration: 180,
    createdAt: "2023-04-27T20:42:49-07:00",
    updatedAt: "2023-04-27T20:42:49-07:00",
    locked: null
  },
  {
    id: 4801626,
    startsAt: "2023-10-11T15:00:00-07:00",
    endsAt: "2023-10-11T16:00:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:43-08:00",
    updatedAt: "2023-04-27T20:42:49-07:00",
    locked: true
  },
  {
    id: 4989914,
    startsAt: "2023-10-11T16:00:00-07:00",
    endsAt: "2023-10-11T18:00:00-07:00",
    duration: 120,
    createdAt: "2023-04-27T20:42:49-07:00",
    updatedAt: "2023-04-27T20:42:49-07:00",
    locked: null
  },
  {
    id: 4883167,
    startsAt: "2023-10-12T05:00:00-07:00",
    endsAt: "2023-10-12T07:30:00-07:00",
    duration: 150,
    createdAt: "2023-03-01T13:12:17-08:00",
    updatedAt: "2023-03-01T13:12:17-08:00",
    locked: null
  },
  {
    id: 4801690,
    startsAt: "2023-10-12T07:30:00-07:00",
    endsAt: "2023-10-12T08:30:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:56-08:00",
    updatedAt: "2023-03-01T13:12:17-08:00",
    locked: true
  },
  {
    id: 4883168,
    startsAt: "2023-10-12T08:30:00-07:00",
    endsAt: "2023-10-12T11:00:00-07:00",
    duration: 150,
    createdAt: "2023-03-01T13:12:17-08:00",
    updatedAt: "2023-03-01T13:12:17-08:00",
    locked: null
  },
  {
    id: 4924653,
    startsAt: "2023-10-12T12:00:00-07:00",
    endsAt: "2023-10-12T13:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:39:22-07:00",
    updatedAt: "2023-03-22T15:39:22-07:00",
    locked: true
  },
  {
    id: 4924705,
    startsAt: "2023-10-12T13:00:00-07:00",
    endsAt: "2023-10-12T16:30:00-07:00",
    duration: 210,
    createdAt: "2023-03-22T15:46:42-07:00",
    updatedAt: "2023-03-22T15:46:42-07:00",
    locked: null
  },
  {
    id: 4924704,
    startsAt: "2023-10-12T16:30:00-07:00",
    endsAt: "2023-10-12T17:30:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:46:41-07:00",
    updatedAt: "2023-03-22T15:46:42-07:00",
    locked: true
  },
  {
    id: 4924706,
    startsAt: "2023-10-12T17:30:00-07:00",
    endsAt: "2023-10-12T18:00:00-07:00",
    duration: 30,
    createdAt: "2023-03-22T15:46:42-07:00",
    updatedAt: "2023-03-22T15:46:42-07:00",
    locked: null
  },
  {
    id: 4801376,
    startsAt: "2023-10-15T05:00:00-07:00",
    endsAt: "2023-10-15T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:56-08:00",
    updatedAt: "2023-01-29T17:30:56-08:00",
    locked: null
  },
  {
    id: 4801393,
    startsAt: "2023-10-15T12:00:00-07:00",
    endsAt: "2023-10-15T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:59-08:00",
    updatedAt: "2023-01-29T17:30:59-08:00",
    locked: null
  },
  {
    id: 4847707,
    startsAt: "2023-10-15T18:30:00-07:00",
    endsAt: "2023-10-15T19:30:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:37:54-08:00",
    updatedAt: "2023-02-12T14:37:54-08:00",
    locked: true
  },
  {
    id: 4888156,
    startsAt: "2023-10-16T05:00:00-07:00",
    endsAt: "2023-10-16T06:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-03T20:56:48-08:00",
    updatedAt: "2023-03-03T20:56:48-08:00",
    locked: null
  },
  {
    id: 4801454,
    startsAt: "2023-10-16T06:00:00-07:00",
    endsAt: "2023-10-16T07:00:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:11-08:00",
    updatedAt: "2023-03-03T20:56:48-08:00",
    locked: true
  },
  {
    id: 4888157,
    startsAt: "2023-10-16T07:00:00-07:00",
    endsAt: "2023-10-16T11:00:00-07:00",
    duration: 240,
    createdAt: "2023-03-03T20:56:48-08:00",
    updatedAt: "2023-03-03T20:56:48-08:00",
    locked: null
  },
  {
    id: 4848010,
    startsAt: "2023-10-16T12:00:00-07:00",
    endsAt: "2023-10-16T16:00:00-07:00",
    duration: 240,
    createdAt: "2023-02-12T14:57:53-08:00",
    updatedAt: "2023-02-12T14:57:53-08:00",
    locked: null
  },
  {
    id: 4848009,
    startsAt: "2023-10-16T16:00:00-07:00",
    endsAt: "2023-10-16T17:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:53-08:00",
    updatedAt: "2023-02-12T14:57:53-08:00",
    locked: true
  },
  {
    id: 4848011,
    startsAt: "2023-10-16T17:00:00-07:00",
    endsAt: "2023-10-16T18:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:53-08:00",
    updatedAt: "2023-02-12T14:57:53-08:00",
    locked: null
  },
  {
    id: 4801532,
    startsAt: "2023-10-17T05:00:00-07:00",
    endsAt: "2023-10-17T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:25-08:00",
    updatedAt: "2023-01-29T17:31:25-08:00",
    locked: null
  },
  {
    id: 4801549,
    startsAt: "2023-10-17T12:00:00-07:00",
    endsAt: "2023-10-17T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:27-08:00",
    updatedAt: "2023-01-29T17:31:27-08:00",
    locked: null
  },
  {
    id: 4801610,
    startsAt: "2023-10-18T05:00:00-07:00",
    endsAt: "2023-10-18T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:40-08:00",
    updatedAt: "2023-01-29T17:31:40-08:00",
    locked: null
  },
  {
    id: 4989915,
    startsAt: "2023-10-18T12:00:00-07:00",
    endsAt: "2023-10-18T15:00:00-07:00",
    duration: 180,
    createdAt: "2023-04-27T20:42:51-07:00",
    updatedAt: "2023-04-27T20:42:51-07:00",
    locked: null
  },
  {
    id: 4801627,
    startsAt: "2023-10-18T15:00:00-07:00",
    endsAt: "2023-10-18T16:00:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:43-08:00",
    updatedAt: "2023-04-27T20:42:51-07:00",
    locked: true
  },
  {
    id: 4989916,
    startsAt: "2023-10-18T16:00:00-07:00",
    endsAt: "2023-10-18T18:00:00-07:00",
    duration: 120,
    createdAt: "2023-04-27T20:42:51-07:00",
    updatedAt: "2023-04-27T20:42:51-07:00",
    locked: null
  },
  {
    id: 4801692,
    startsAt: "2023-10-19T05:00:00-07:00",
    endsAt: "2023-10-19T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:56-08:00",
    updatedAt: "2023-01-29T17:31:56-08:00",
    locked: null
  },
  {
    id: 4924655,
    startsAt: "2023-10-19T12:00:00-07:00",
    endsAt: "2023-10-19T13:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:39:23-07:00",
    updatedAt: "2023-03-22T15:39:23-07:00",
    locked: true
  },
  {
    id: 4924708,
    startsAt: "2023-10-19T13:00:00-07:00",
    endsAt: "2023-10-19T16:30:00-07:00",
    duration: 210,
    createdAt: "2023-03-22T15:46:43-07:00",
    updatedAt: "2023-03-22T15:46:43-07:00",
    locked: null
  },
  {
    id: 4924707,
    startsAt: "2023-10-19T16:30:00-07:00",
    endsAt: "2023-10-19T17:30:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:46:43-07:00",
    updatedAt: "2023-03-22T15:46:43-07:00",
    locked: true
  },
  {
    id: 4924709,
    startsAt: "2023-10-19T17:30:00-07:00",
    endsAt: "2023-10-19T18:00:00-07:00",
    duration: 30,
    createdAt: "2023-03-22T15:46:43-07:00",
    updatedAt: "2023-03-22T15:46:43-07:00",
    locked: null
  },
  {
    id: 4801377,
    startsAt: "2023-10-22T05:00:00-07:00",
    endsAt: "2023-10-22T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:57-08:00",
    updatedAt: "2023-01-29T17:30:57-08:00",
    locked: null
  },
  {
    id: 4801394,
    startsAt: "2023-10-22T12:00:00-07:00",
    endsAt: "2023-10-22T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:30:59-08:00",
    updatedAt: "2023-01-29T17:30:59-08:00",
    locked: null
  },
  {
    id: 4847709,
    startsAt: "2023-10-22T18:30:00-07:00",
    endsAt: "2023-10-22T19:30:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:37:55-08:00",
    updatedAt: "2023-02-12T14:37:55-08:00",
    locked: true
  },
  {
    id: 4888158,
    startsAt: "2023-10-23T05:00:00-07:00",
    endsAt: "2023-10-23T06:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-03T20:56:49-08:00",
    updatedAt: "2023-03-03T20:56:49-08:00",
    locked: null
  },
  {
    id: 4801455,
    startsAt: "2023-10-23T06:00:00-07:00",
    endsAt: "2023-10-23T07:00:00-07:00",
    duration: 60,
    createdAt: "2023-01-29T17:31:11-08:00",
    updatedAt: "2023-03-03T20:56:49-08:00",
    locked: true
  },
  {
    id: 4888159,
    startsAt: "2023-10-23T07:00:00-07:00",
    endsAt: "2023-10-23T11:00:00-07:00",
    duration: 240,
    createdAt: "2023-03-03T20:56:49-08:00",
    updatedAt: "2023-03-03T20:56:49-08:00",
    locked: null
  },
  {
    id: 4848013,
    startsAt: "2023-10-23T12:00:00-07:00",
    endsAt: "2023-10-23T16:00:00-07:00",
    duration: 240,
    createdAt: "2023-02-12T14:57:54-08:00",
    updatedAt: "2023-02-12T14:57:54-08:00",
    locked: null
  },
  {
    id: 4848012,
    startsAt: "2023-10-23T16:00:00-07:00",
    endsAt: "2023-10-23T17:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:54-08:00",
    updatedAt: "2023-02-12T14:57:54-08:00",
    locked: true
  },
  {
    id: 4848014,
    startsAt: "2023-10-23T17:00:00-07:00",
    endsAt: "2023-10-23T18:00:00-07:00",
    duration: 60,
    createdAt: "2023-02-12T14:57:54-08:00",
    updatedAt: "2023-02-12T14:57:54-08:00",
    locked: null
  },
  {
    id: 4801533,
    startsAt: "2023-10-24T05:00:00-07:00",
    endsAt: "2023-10-24T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:25-08:00",
    updatedAt: "2023-01-29T17:31:25-08:00",
    locked: null
  },
  {
    id: 4801550,
    startsAt: "2023-10-24T12:00:00-07:00",
    endsAt: "2023-10-24T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:28-08:00",
    updatedAt: "2023-01-29T17:31:28-08:00",
    locked: null
  },
  {
    id: 4801611,
    startsAt: "2023-10-25T05:00:00-07:00",
    endsAt: "2023-10-25T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:41-08:00",
    updatedAt: "2023-01-29T17:31:41-08:00",
    locked: null
  },
  {
    id: 4801628,
    startsAt: "2023-10-25T12:00:00-07:00",
    endsAt: "2023-10-25T18:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:43-08:00",
    updatedAt: "2023-01-29T17:31:43-08:00",
    locked: null
  },
  {
    id: 4801693,
    startsAt: "2023-10-26T05:00:00-07:00",
    endsAt: "2023-10-26T11:00:00-07:00",
    duration: 360,
    createdAt: "2023-01-29T17:31:56-08:00",
    updatedAt: "2023-01-29T17:31:56-08:00",
    locked: null
  },
  {
    id: 4924657,
    startsAt: "2023-10-26T12:00:00-07:00",
    endsAt: "2023-10-26T13:00:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:39:24-07:00",
    updatedAt: "2023-03-22T15:39:24-07:00",
    locked: true
  },
  {
    id: 4924711,
    startsAt: "2023-10-26T13:00:00-07:00",
    endsAt: "2023-10-26T16:30:00-07:00",
    duration: 210,
    createdAt: "2023-03-22T15:46:44-07:00",
    updatedAt: "2023-03-22T15:46:44-07:00",
    locked: null
  },
  {
    id: 4924710,
    startsAt: "2023-10-26T16:30:00-07:00",
    endsAt: "2023-10-26T17:30:00-07:00",
    duration: 60,
    createdAt: "2023-03-22T15:46:43-07:00",
    updatedAt: "2023-03-22T15:46:44-07:00",
    locked: true
  },
  {
    id: 4924712,
    startsAt: "2023-10-26T17:30:00-07:00",
    endsAt: "2023-10-26T18:00:00-07:00",
    duration: 30,
    createdAt: "2023-03-22T15:46:44-07:00",
    updatedAt: "2023-03-22T15:46:44-07:00",
    locked: null
  }
];

// src/lib/availability-repeat-option.ts
var availabilityRepeatOptions = {
  repeatOptions: [
    {
      startsAt: "2021-05-30T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224444,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-06-06T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224445,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-06-13T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224446,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-06-20T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224447,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-06-27T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224448,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-07-04T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224449,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-07-11T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224450,
      available: true,
      canAutoCreate: false
    },
    {
      startsAt: "2021-07-18T08:00:00-04:00",
      duration: 120,
      availabilityId: 2224451,
      available: true,
      canAutoCreate: false
    }
  ],
  availability,
  tutor
};

// src/lib/availabilities-json.ts
var availabilitiesJson = [
  {
    day: "Sunday",
    duration: 90,
    time: "13:00",
    timeZone: "America/Los_Angeles"
  },
  {
    day: "Tuesday",
    duration: 90,
    time: "11:30",
    timeZone: "America/Los_Angeles"
  }
];

// src/lib/blockouts-json.ts
var blockoutsJson = [
  {
    starts: "2050-01-01",
    ends: "2050-01-10"
  },
  {
    starts: "2020-02-01",
    ends: "2020-02-10"
  }
];

// src/lib/brand-permission.ts
var brandPermissions = [
  {
    id: 2416,
    brandId: 12,
    name: "Executive Functioning",
    userId: 1594
  },
  {
    id: 5809,
    brandId: 30,
    name: "Parent Event",
    userId: 1594
  },
  {
    id: 6003,
    brandId: 14,
    name: "Boot Camp",
    userId: 1594
  },
  {
    id: 6162,
    brandId: 33,
    name: "Workshop",
    userId: 1594
  },
  {
    id: 6561,
    brandId: 4,
    name: "Special Event",
    userId: 1594
  },
  {
    id: 6562,
    brandId: 18,
    name: "Special Event",
    userId: 1594
  }
];

// src/lib/calendar.ts
var calendarAvailabilitiesItems = [
  {
    end: new Date(2100, 2, 2, 13),
    start: new Date(2100, 2, 2, 12)
  },
  {
    end: new Date(2100, 2, 2, 11),
    start: new Date(2100, 2, 2, 10)
  },
  {
    end: new Date(2100, 2, 2, 9),
    start: new Date(2100, 2, 2, 8)
  },
  {
    end: new Date(2100, 2, 2, 7),
    start: new Date(2100, 2, 2, 6)
  }
];
var calendarAvailabilitiesJson = [
  {
    day: "Tuesday",
    duration: 60,
    time: "12:00",
    timeZone: "UTC"
  },
  {
    day: "Tuesday",
    duration: 60,
    time: "10:00",
    timeZone: "UTC"
  },
  {
    day: "Tuesday",
    duration: 60,
    time: "08:00",
    timeZone: "UTC"
  },
  {
    day: "Tuesday",
    duration: 60,
    time: "06:00",
    timeZone: "UTC"
  }
];

// src/lib/chart-data.ts
var chartData = {
  labels: ["5J", "4J", "3J", "2J", "7J", "1J"],
  datasets: [
    {
      fill: false,
      tension: 0.1,
      backgroundColor: "#fff",
      borderColor: "#f4c041",
      borderDashOffset: 0,
      pointBorderColor: "#f4c041",
      pointBackgroundColor: "#f4c041",
      pointBorderWidth: 3,
      pointRadius: 5,
      data: [22, 20, 20, 21, 19, 6],
      spanGaps: false
    }
  ]
};

// src/lib/chart-options.ts
var chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      position: "right",
      ticks: {
        color: "#999999"
      },
      max: 22
    },
    x: {
      ticks: {
        color: "#999999"
      }
    }
  }
};

// src/lib/contact.ts
var contact = {
  id: 1,
  active: true,
  category: "Advisor",
  email: "admin@revprep.com",
  extension: null,
  featured: false,
  firstName: "Jon",
  fullName: "Jon Snow",
  homeUrl: "localhost:9030",
  lastName: "Snow",
  leadSourceId: 1111,
  leadSourceName: "Golden View Elementary",
  login: "JS",
  name: "Jon",
  phone: "15554443333",
  primary: true,
  referralCount: 100,
  timeZone: "PST",
  type: "yes"
};

// src/lib/student.ts
var student = {
  id: 123456,
  active: true,
  address,
  availabilitiesJson,
  avatar: actorAvatar,
  avatarLargeUrl: avatarUrl,
  avatarUrl,
  blockoutsJson: [],
  canLogin: true,
  createdAt: "2015-11-17T15:43:01-07:00",
  defaultPassword: "ketchumrevolution",
  email: "ash.ketchum@gmail.com",
  emailSubscriptionStatus: "unsubscribed",
  esl: false,
  examsApiToken: "student-exams-api-token",
  firstName: "Ash",
  fullName: "Ash Ketchum",
  grade: 15,
  graduationYear: 2018,
  hasNoPassword: false,
  homeUrl: "https://localhost:9003",
  hubspotContactUrl: null,
  isNew: null,
  language: "en",
  lastInitial: "K",
  lastName: "Ketchum",
  lastSignInAt: "2020-08-18T17:02:10-06:00",
  leadScore: null,
  leadScoreDigest: null,
  lockedAt: null,
  lockPassword: false,
  login: "a",
  note: null,
  pace: 0,
  paceScore: 0,
  param: "ash",
  parent,
  phone: "+12345556789",
  points: 0,
  properties: [
    {
      createdAt: "2022-02-09T14:05:18-08:00",
      id: 4,
      kind: "Learning Difference",
      propertyId: 1,
      value: "ADHD/ADD"
    },
    {
      createdAt: "2022-02-09T14:05:29-08:00",
      id: 5,
      kind: "Accommodation",
      propertyId: 10,
      value: "Extend Time (50%)"
    }
  ],
  pushNotifications: true,
  requireSetPassword: false,
  requiresTutorMatchEmail: true,
  revenue: "98723.0",
  school: leadSource,
  signInCount: 873,
  timeZone: "America/New_York",
  tutors,
  type: "Student",
  updatedAt: "2020-08-14T12:56:32-06:00",
  userId: 112233,
  username: "a"
};
var studentWithoutPaymentProfile = {
  ...student,
  parent: {
    ...parent,
    authorizeNetProfile: null
  }
};

// src/lib/parent.ts
var parent = {
  id: 123456,
  firstName: "Michael",
  lastInitial: "S",
  type: "Parent",
  timeZone: "America/Chicago",
  userId: 1234,
  callsCount: 0,
  createdAt: "2019-01-28T21:20:02-08:00",
  customer: true,
  doNotContact: true,
  emailSubscriptionStatus: "subscribed",
  email: "michael.scott@gmail.com",
  emailsCount: 0,
  employeeId: null,
  fullName: "Michael Scott",
  handoff: false,
  handlerType: "Learning Success Manager",
  homeEstimate: 123456,
  homeEstimateRatio: 0.9,
  homeUrl: "http://localhost:8888",
  hubspotContactUrl: null,
  language: "en",
  lastActivityAt: null,
  lastName: "Shearer",
  lastWebSaleAt: null,
  leadAt: "2019-01-28T21:20:02-08:00",
  leadScore: null,
  leadScoreDigest: null,
  lockedAt: null,
  login: "michaelscott",
  phone: "+13333334444",
  pushNotifications: true,
  referralRevenue: "0.0",
  revenue: 4312,
  smsCount: 0,
  smsOptInAt: null,
  smsOptInMedium: null,
  smsOptInType: null,
  source: "Internet Search",
  sourceLink: course,
  status: "No Sales/Partner Contact",
  students: [student],
  updatedAt: "2019-02-07T14:44:10-08:00",
  username: "michaelscott",
  vip: false,
  active: true,
  name: "Michael Scott"
};
var parentWithContact = {
  ...parent,
  sourceLink: contact
};

// src/lib/consult.ts
var consult = {
  id: 1234,
  callPreference: "Call",
  completed: false,
  consultType: "1-on-1",
  createdAt: "2021-08-17T07:02:07-07:00",
  endsAt: "2021-08-23T14:30:00-07:00",
  formParams: {
    name: "Stanley Hudson",
    phone: "(323) 222-1111",
    email: "stanley.hudson@gmail.com"
  },
  formText: "Name: Stanley Hudson\nPhone: (323) 222-1111\nEmail: stanley.hudson@gmail.com",
  noPitchReason: null,
  notes: "I would like information on ACT/SAT preparation programs.",
  outcome: null,
  pitchedProduct: null,
  parent,
  startsAt: "2021-08-23T14:00:00-07:00",
  student,
  timeZone: "America/Chicago",
  topic: "Academic Strategy Session",
  updatedAt: "2021-09-24T09:53:35-07:00",
  zoomLink: "https://revolutionprep.zoom.us/j/98675877067"
};

// src/lib/country.ts
var countries = [
  {
    name: "United States"
  },
  {
    name: "Afghanistan"
  },
  {
    name: "Albania"
  },
  {
    name: "Algeria"
  },
  {
    name: "American Samoa"
  },
  {
    name: "Andorra"
  },
  {
    name: "Angola"
  },
  {
    name: "Anguilla"
  },
  {
    name: "Antarctica"
  },
  {
    name: "Antigua and Barbuda"
  },
  {
    name: "Argentina"
  },
  {
    name: "Armenia"
  },
  {
    name: "Aruba"
  },
  {
    name: "Australia"
  },
  {
    name: "Austria"
  },
  {
    name: "Azerbaijan"
  },
  {
    name: "Bahamas"
  },
  {
    name: "Bahrain"
  },
  {
    name: "Bangladesh"
  },
  {
    name: "Barbados"
  },
  {
    name: "Belarus"
  },
  {
    name: "Belgium"
  },
  {
    name: "Belize"
  },
  {
    name: "Benin"
  },
  {
    name: "Bermuda"
  },
  {
    name: "Bhutan"
  },
  {
    name: "Bolivia, Plurinational State of"
  },
  {
    name: "Bonaire, Sint Eustatius and Saba"
  },
  {
    name: "Bosnia and Herzegovina"
  },
  {
    name: "Botswana"
  },
  {
    name: "Bouvet Island"
  },
  {
    name: "Brazil"
  },
  {
    name: "British Indian Ocean Territory"
  },
  {
    name: "Brunei Darussalam"
  },
  {
    name: "Bulgaria"
  },
  {
    name: "Burkina Faso"
  },
  {
    name: "Burundi"
  },
  {
    name: "Cabo Verde"
  },
  {
    name: "Cambodia"
  },
  {
    name: "Cameroon"
  },
  {
    name: "Canada"
  },
  {
    name: "Cayman Islands"
  },
  {
    name: "Central African Republic"
  },
  {
    name: "Chad"
  },
  {
    name: "Chile"
  },
  {
    name: "China"
  },
  {
    name: "Christmas Island"
  },
  {
    name: "Cocos (Keeling) Islands"
  },
  {
    name: "Colombia"
  },
  {
    name: "Comoros"
  },
  {
    name: "Congo"
  },
  {
    name: "Congo, The Democratic Republic of the"
  },
  {
    name: "Cook Islands"
  },
  {
    name: "Costa Rica"
  },
  {
    name: "Croatia"
  },
  {
    name: "Cuba"
  },
  {
    name: "Cura\xE7ao"
  },
  {
    name: "Cyprus"
  },
  {
    name: "Czechia"
  },
  {
    name: "C\xF4te d'Ivoire"
  },
  {
    name: "Denmark"
  },
  {
    name: "Djibouti"
  },
  {
    name: "Dominica"
  },
  {
    name: "Dominican Republic"
  },
  {
    name: "Ecuador"
  },
  {
    name: "Egypt"
  },
  {
    name: "El Salvador"
  },
  {
    name: "Equatorial Guinea"
  },
  {
    name: "Eritrea"
  },
  {
    name: "Estonia"
  },
  {
    name: "Ethiopia"
  },
  {
    name: "Falkland Islands (Malvinas)"
  },
  {
    name: "Faroe Islands"
  },
  {
    name: "Fiji"
  },
  {
    name: "Finland"
  },
  {
    name: "France"
  },
  {
    name: "French Guiana"
  },
  {
    name: "French Polynesia"
  },
  {
    name: "French Southern Territories"
  },
  {
    name: "Gabon"
  },
  {
    name: "Gambia"
  },
  {
    name: "Georgia"
  },
  {
    name: "Germany"
  },
  {
    name: "Ghana"
  },
  {
    name: "Gibraltar"
  },
  {
    name: "Greece"
  },
  {
    name: "Greenland"
  },
  {
    name: "Grenada"
  },
  {
    name: "Guadeloupe"
  },
  {
    name: "Guam"
  },
  {
    name: "Guatemala"
  },
  {
    name: "Guernsey"
  },
  {
    name: "Guinea"
  },
  {
    name: "Guinea-Bissau"
  },
  {
    name: "Guyana"
  },
  {
    name: "Haiti"
  },
  {
    name: "Heard Island and McDonald Islands"
  },
  {
    name: "Holy See (Vatican City State)"
  },
  {
    name: "Honduras"
  },
  {
    name: "Hong Kong"
  },
  {
    name: "Hungary"
  },
  {
    name: "Iceland"
  },
  {
    name: "India"
  },
  {
    name: "Indonesia"
  },
  {
    name: "Iran, Islamic Republic of"
  },
  {
    name: "Iraq"
  },
  {
    name: "Ireland"
  },
  {
    name: "Isle of Man"
  },
  {
    name: "Israel"
  },
  {
    name: "Italy"
  },
  {
    name: "Jamaica"
  },
  {
    name: "Japan"
  },
  {
    name: "Jersey"
  },
  {
    name: "Jordan"
  },
  {
    name: "Kazakhstan"
  },
  {
    name: "Kenya"
  },
  {
    name: "Kiribati"
  },
  {
    name: "Korea, Democratic People's Republic of"
  },
  {
    name: "Korea, Republic of"
  },
  {
    name: "Kuwait"
  },
  {
    name: "Kyrgyzstan"
  },
  {
    name: "Lao People's Democratic Republic"
  },
  {
    name: "Latvia"
  },
  {
    name: "Lebanon"
  },
  {
    name: "Lesotho"
  },
  {
    name: "Liberia"
  },
  {
    name: "Libya"
  },
  {
    name: "Liechtenstein"
  },
  {
    name: "Lithuania"
  },
  {
    name: "Luxembourg"
  },
  {
    name: "Macao"
  },
  {
    name: "Macedonia, Republic of"
  },
  {
    name: "Madagascar"
  },
  {
    name: "Malawi"
  },
  {
    name: "Malaysia"
  },
  {
    name: "Maldives"
  },
  {
    name: "Mali"
  },
  {
    name: "Malta"
  },
  {
    name: "Marshall Islands"
  },
  {
    name: "Martinique"
  },
  {
    name: "Mauritania"
  },
  {
    name: "Mauritius"
  },
  {
    name: "Mayotte"
  },
  {
    name: "Mexico"
  },
  {
    name: "Micronesia, Federated States of"
  },
  {
    name: "Moldova, Republic of"
  },
  {
    name: "Monaco"
  },
  {
    name: "Mongolia"
  },
  {
    name: "Montenegro"
  },
  {
    name: "Montserrat"
  },
  {
    name: "Morocco"
  },
  {
    name: "Mozambique"
  },
  {
    name: "Myanmar"
  },
  {
    name: "Namibia"
  },
  {
    name: "Nauru"
  },
  {
    name: "Nepal"
  },
  {
    name: "Netherlands"
  },
  {
    name: "New Caledonia"
  },
  {
    name: "New Zealand"
  },
  {
    name: "Nicaragua"
  },
  {
    name: "Niger"
  },
  {
    name: "Nigeria"
  },
  {
    name: "Niue"
  },
  {
    name: "Norfolk Island"
  },
  {
    name: "Northern Mariana Islands"
  },
  {
    name: "Norway"
  },
  {
    name: "Oman"
  },
  {
    name: "Pakistan"
  },
  {
    name: "Palau"
  },
  {
    name: "Palestine, State of"
  },
  {
    name: "Panama"
  },
  {
    name: "Papua New Guinea"
  },
  {
    name: "Paraguay"
  },
  {
    name: "Peru"
  },
  {
    name: "Philippines"
  },
  {
    name: "Pitcairn"
  },
  {
    name: "Poland"
  },
  {
    name: "Portugal"
  },
  {
    name: "Puerto Rico"
  },
  {
    name: "Qatar"
  },
  {
    name: "Romania"
  },
  {
    name: "Russia"
  },
  {
    name: "Rwanda"
  },
  {
    name: "R\xE9union"
  },
  {
    name: "Saint Barth\xE9lemy"
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    name: "Saint Kitts and Nevis"
  },
  {
    name: "Saint Lucia"
  },
  {
    name: "Saint Martin (French part)"
  },
  {
    name: "Saint Pierre and Miquelon"
  },
  {
    name: "Saint Vincent and the Grenadines"
  },
  {
    name: "Samoa"
  },
  {
    name: "San Marino"
  },
  {
    name: "Sao Tome and Principe"
  },
  {
    name: "Saudi Arabia"
  },
  {
    name: "Senegal"
  },
  {
    name: "Serbia"
  },
  {
    name: "Seychelles"
  },
  {
    name: "Sierra Leone"
  },
  {
    name: "Singapore"
  },
  {
    name: "Sint Maarten (Dutch part)"
  },
  {
    name: "Slovakia"
  },
  {
    name: "Slovenia"
  },
  {
    name: "Solomon Islands"
  },
  {
    name: "Somalia"
  },
  {
    name: "South Africa"
  },
  {
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    name: "South Sudan"
  },
  {
    name: "Spain"
  },
  {
    name: "Sri Lanka"
  },
  {
    name: "Sudan"
  },
  {
    name: "Suriname"
  },
  {
    name: "Svalbard and Jan Mayen"
  },
  {
    name: "Swaziland"
  },
  {
    name: "Sweden"
  },
  {
    name: "Switzerland"
  },
  {
    name: "Syrian Arab Republic"
  },
  {
    name: "Taiwan"
  },
  {
    name: "Tajikistan"
  },
  {
    name: "Tanzania, United Republic of"
  },
  {
    name: "Thailand"
  },
  {
    name: "Timor-Leste"
  },
  {
    name: "Togo"
  },
  {
    name: "Tokelau"
  },
  {
    name: "Tonga"
  },
  {
    name: "Trinidad and Tobago"
  },
  {
    name: "Tunisia"
  },
  {
    name: "Turkey"
  },
  {
    name: "Turkmenistan"
  },
  {
    name: "Turks and Caicos Islands"
  },
  {
    name: "Tuvalu"
  },
  {
    name: "Uganda"
  },
  {
    name: "Ukraine"
  },
  {
    name: "United Arab Emirates"
  },
  {
    name: "United Kingdom"
  },
  {
    name: "United States"
  },
  {
    name: "United States Minor Outlying Islands"
  },
  {
    name: "Uruguay"
  },
  {
    name: "Uzbekistan"
  },
  {
    name: "Vanuatu"
  },
  {
    name: "Venezuela, Bolivarian Republic of"
  },
  {
    name: "Vietnam"
  },
  {
    name: "Virgin Islands, British"
  },
  {
    name: "Virgin Islands, U.S."
  },
  {
    name: "Wallis and Futuna"
  },
  {
    name: "Western Sahara"
  },
  {
    name: "Yemen"
  },
  {
    name: "Zambia"
  },
  {
    name: "Zimbabwe"
  },
  {
    name: "\xC5land Islands"
  }
];

// src/lib/course-materials.ts
var courseMaterials = [
  {
    id: 203,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 1",
    segmentId: 25,
    subjectId: 51,
    sequence: 1,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 204,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 1",
    segmentId: 25,
    subjectId: 51,
    sequence: 1,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 205,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 2",
    segmentId: 25,
    subjectId: 51,
    sequence: 2,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 206,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 2",
    segmentId: 25,
    subjectId: 51,
    sequence: 2,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 207,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 3",
    segmentId: 25,
    subjectId: 51,
    sequence: 3,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 208,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 3",
    segmentId: 25,
    subjectId: 51,
    sequence: 3,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 209,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 4",
    segmentId: 25,
    subjectId: 51,
    sequence: 4,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 210,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 4",
    segmentId: 25,
    subjectId: 51,
    sequence: 4,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 211,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 5",
    segmentId: 25,
    subjectId: 51,
    sequence: 5,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 212,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 5",
    segmentId: 25,
    subjectId: 51,
    sequence: 5,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 213,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Lesson Companion",
    name: "Lesson Companion 6",
    segmentId: 25,
    subjectId: 51,
    sequence: 6,
    attachmentUrl: "https://attachment-url_123456"
  },
  {
    id: 214,
    brandId: 7,
    courseTitle: "SAT 12-Hour Small Group Course",
    kind: "Homework",
    name: "Homework 6",
    segmentId: 25,
    subjectId: 51,
    sequence: 6,
    attachmentUrl: "https://attachment-url_123456"
  }
];

// src/lib/credit-card.ts
var creditCard = {
  brand: "visa",
  number: "4242 4242 4242 4242",
  expiration: "02 / 24",
  cvc: "444",
  postalCode: "23242"
};
var validCreditCardEventPayload = {
  card: {
    brand: "visa",
    number: "4242424242424242",
    expiration: "",
    cvc: "",
    postalCode: "10304",
    numberFormatted: "4242 4242 4242 4242",
    expMonth: null,
    expYear: null
  },
  brand: "visa",
  invalid: false,
  complete: false,
  input: {
    el: {
      _prevClass: "credit-card-field-field credit-card-field-number",
      _value: "4242 4242 4242 4242",
      _vModifiers: {}
    },
    name: "number",
    valid: true
  }
};
var invalidCreditCardEventPayload = {
  card: {
    brand: "visa",
    number: "4242424",
    expiration: "",
    cvc: "",
    postalCode: "10304",
    numberFormatted: "4242 424",
    expMonth: null,
    expYear: null
  },
  brand: "visa",
  invalid: true,
  complete: false,
  input: {
    el: {
      _prevClass: "credit-card-field-field credit-card-field-number",
      _value: "4242 424",
      _vModifiers: {}
    },
    name: "number",
    valid: false
  }
};

// src/lib/dynamic-session.ts
var dynamicSession = {
  dynamicSessionId: 4444,
  id: 1555555,
  employeeId: 2888,
  startsAt: 1633629600,
  endsAt: 1633632300,
  subjectId: 43
};

// src/lib/contact-activity.ts
var imminentClose = {
  id: 5556789,
  body: null,
  campaignGenerated: false,
  direction: null,
  nextConsultAt: null,
  sentAt: "2022-03-22T00:00:00-07:00",
  subject: null,
  type: "ImminentClose",
  kind: "ImminentClose",
  revenue: 9
};

// src/lib/department.ts
var departments = [
  {
    id: 2,
    createdAt: "2013-09-15T17:00:00-07:00",
    enrollUrl: "http://localhost:9005",
    handle: "handle",
    name: "Revolution Learning",
    updatedAt: "2013-09-15T17:00:00-07:00"
  },
  {
    id: 3,
    createdAt: "2013-09-15T17:00:00-07:00",
    enrollUrl: "http://localhost:9005",
    handle: "handle",
    name: "Retail",
    updatedAt: "2013-09-15T17:00:00-07:00"
  }
];

// src/lib/employee.ts
var employee = {
  avatarUrl,
  avatarLargeUrl: avatarUrl,
  address,
  id: 1248,
  acuityHandle: "1234567",
  essayGrader: false,
  extension: null,
  extensionHandle: null,
  firstName: "Charlie",
  lastInitial: "B",
  primaryAcuityUser: true,
  timeZone: "America/Los_Angeles",
  type: "Employee",
  updatedAt: "2019-10-11T11:35:48-07:00",
  userId: 2022,
  active: true,
  examsApiToken: "employee-exams-api-token",
  roles: ["academic_advisor", "advising_manager", "admin", "developer", "spoof_advisor"],
  breakRate: 0,
  createdAt: "2013-09-15T17:00:00-07:00",
  curriculumRate: 0,
  emergencyContact: null,
  emergencyPhone: null,
  employmentType: "Full-Time",
  endDate: null,
  enforceBreaktime: false,
  exempt: false,
  hireDate: "2013-09-16",
  hourlyRate: 0,
  hrPaperwork: true,
  login: "cbrown",
  param: null,
  payrollHandle: "92929",
  personalEmail: null,
  personalPhone: null,
  prepRate: 0,
  pushNotifications: false,
  trainingRate: 0,
  username: "cbrown",
  homeUrl: "http://localhost:9005",
  email: "charlie.brown@revolutionprep.com",
  fullName: "Charlie Brown",
  lastName: "Brown",
  phone: "+13332221111",
  scheduleUrl: "//:localhost:3000/schedule",
  title: "Academic Advisor"
};

// src/lib/slot-subscription.ts
var slotSubscriptions = [
  {
    id: 27,
    slotId: 122,
    enrollmentId: 44e4,
    startsAt: "2021-06-17T19:00:00-04:00"
  },
  {
    id: 28,
    slotId: 113,
    enrollmentId: 44e4,
    startsAt: "2021-07-05T18:00:00-04:00"
  }
];

// src/lib/enrollment.ts
var enrollmentPrivateTutoring = {
  id: 33333,
  arrearBilling: false,
  attendancesCount: 157,
  attendancesAttendedCount: 112,
  canCancel: false,
  contractEndsAt: null,
  createdAt: "2017-08-31T08:59:56-04:00",
  isChild: false,
  limited: false,
  limitedAttendancesCount: 0,
  lockedAt: null,
  requiresSubscription: false,
  sessionDuration: null,
  sessionFrequency: null,
  startsAt: "2016-02-18T17:40:49-05:00",
  status: "Active",
  title: "Private Tutoring",
  trialEndsAt: null,
  type: "Enrollment",
  warnedAt: null,
  unenrolledAt: null,
  unenrolledReason: null,
  unenrolledNote: null,
  updatedAt: "2018-03-13T00:26:48-04:00",
  canConvertToContract: false,
  canConvertToDiscount: false,
  inTrial: false
};
var enrollments = [
  {
    ...enrollmentPrivateTutoring,
    course: coursePrivateTutoring,
    brand,
    blockoutAttendances: [],
    slotSubscriptions: [],
    tutors: tutorsPartial
  },
  {
    id: 446688,
    arrearBilling: true,
    attendancesCount: 2,
    attendancesAttendedCount: 0,
    canCancel: false,
    contractEndsAt: null,
    createdAt: "2021-03-24T12:52:36-04:00",
    isChild: false,
    limited: false,
    limitedAttendancesCount: 0,
    lockedAt: null,
    requiresSubscription: false,
    sessionDuration: null,
    sessionFrequency: null,
    startsAt: "2021-03-24T12:52:36-04:00",
    status: "Active",
    title: "Algebra II Tutoring",
    trialEndsAt: null,
    type: "Enrollment",
    warnedAt: null,
    unenrolledAt: null,
    unenrolledReason: null,
    unenrolledNote: null,
    updatedAt: "2021-03-24T12:52:37-04:00",
    canConvertToContract: false,
    canConvertToDiscount: false,
    inTrial: false,
    course: courseTutoringAlgebraII,
    brand: brandTutoring,
    blockoutAttendances: [],
    slotSubscriptions: [],
    tutors: [tutorsPartial[0]]
  },
  {
    id: 44e4,
    arrearBilling: true,
    attendancesCount: 5,
    attendancesAttendedCount: 0,
    canCancel: false,
    contractEndsAt: null,
    createdAt: "2021-04-09T15:49:12-04:00",
    isChild: false,
    limited: false,
    limitedAttendancesCount: 0,
    lockedAt: null,
    requiresSubscription: false,
    sessionDuration: null,
    sessionFrequency: null,
    startsAt: "2021-04-09T15:49:12-04:00",
    status: "Active",
    title: "Geometry Tutoring",
    trialEndsAt: null,
    type: "Enrollment",
    warnedAt: null,
    unenrolledAt: null,
    unenrolledReason: null,
    unenrolledNote: null,
    updatedAt: "2021-04-09T15:49:13-04:00",
    canConvertToContract: false,
    canConvertToDiscount: false,
    inTrial: false,
    course: courseTutoringGeometry,
    brand: brandTutoring,
    blockoutAttendances,
    slotSubscriptions,
    tutors: [tutorsPartial[1]]
  },
  {
    id: 746827,
    arrearBilling: false,
    attendancesCount: 0,
    attendancesAttendedCount: 0,
    availableTiers: [],
    canCancel: true,
    contractEndsAt: null,
    createdAt: "2024-10-07T13:39:30-07:00",
    firstSessionFocus: null,
    isChild: false,
    limited: false,
    limitedAttendancesCount: 0,
    lockedAt: null,
    requiresSubscription: false,
    sessionDuration: null,
    sessionFrequency: null,
    startsAt: "2024-10-07T13:39:29-07:00",
    status: "Active",
    title: "ACT 12-Week Self Study",
    trialEndsAt: null,
    type: "Enrollment",
    warnedAt: null,
    unenrolledAt: null,
    unenrolledReason: null,
    unenrolledNote: null,
    updatedAt: "2024-10-07T13:39:30-07:00",
    canConvertToContract: false,
    canConvertToDiscount: false,
    inTrial: false,
    course: courseSelfStudy,
    brand: brandSelfStudy,
    tutors: []
  }
];
var limitedEnrollment = {
  id: 477777,
  arrearBilling: false,
  attendancesCount: 0,
  attendancesAttendedCount: 0,
  canCancel: true,
  contractEndsAt: null,
  createdAt: "2021-08-18T17:23:53-07:00",
  isChild: false,
  limited: true,
  limitedAttendancesCount: 3,
  lockedAt: null,
  requiresSubscription: false,
  sessionDuration: null,
  sessionFrequency: null,
  startsAt: "2021-08-18T17:23:53-07:00",
  status: "Trial",
  title: "Revolution Now",
  trialEndsAt: null,
  type: "LimitedEnrollment",
  warnedAt: null,
  unenrolledAt: null,
  unenrolledReason: null,
  unenrolledNote: null,
  updatedAt: "2021-08-18T17:23:53-07:00",
  canConvertToContract: false,
  canConvertToDiscount: false,
  inTrial: false,
  course: courseRevolutionNowLimited,
  brand: brandRevolutionNow
};
var paidRNEnrollment = {
  id: 611111,
  arrearBilling: false,
  attendancesCount: 0,
  attendancesAttendedCount: 0,
  canCancel: true,
  contractEndsAt: null,
  createdAt: "2022-06-22T16:21:57-07:00",
  isChild: false,
  limited: false,
  limitedAttendancesCount: 3,
  lockedAt: null,
  requiresSubscription: true,
  sessionDuration: null,
  sessionFrequency: null,
  startsAt: "2022-06-22T16:21:56-07:00",
  status: "Active",
  title: "Revolution Now",
  trialEndsAt: null,
  type: "Enrollment",
  warnedAt: null,
  unenrolledAt: null,
  unenrolledReason: null,
  unenrolledNote: null,
  updatedAt: "2022-06-22T16:21:57-07:00",
  canConvertToContract: false,
  canConvertToDiscount: false,
  inTrial: false
};
var academicsEnrollment = {
  id: 705592,
  arrearBilling: true,
  attendancesCount: 25,
  attendancesAttendedCount: 0,
  canCancel: false,
  contractEndsAt: null,
  createdAt: "2023-08-11T15:36:00-07:00",
  isChild: false,
  limited: false,
  limitedAttendancesCount: 0,
  lockedAt: null,
  requiresSubscription: false,
  sessionDuration: null,
  sessionFrequency: null,
  startsAt: "2023-08-11T15:36:00-07:00",
  status: "Active",
  title: "Academics",
  trialEndsAt: null,
  type: "Enrollment",
  warnedAt: null,
  unenrolledAt: null,
  unenrolledReason: null,
  unenrolledNote: null,
  updatedAt: "2023-08-11T15:36:01-07:00",
  canConvertToContract: false,
  canConvertToDiscount: false,
  inTrial: false,
  course: {
    id: 71103,
    availableForSale: false,
    availableForSaleError: "has no more seats remaining",
    batchEnroll: false,
    createdAt: "2023-08-11T15:36:00-07:00",
    deliveredDuration: 0,
    distance: null,
    duration: 1500,
    dynamicSubject: false,
    endsAt: "2023-10-19T13:00:00-07:00",
    enrollUrl: "//localhost:9000/checkout/add/Course-71103",
    enrollmentCap: 1,
    enrollmentsCount: 1,
    firstSessionAt: null,
    fullName: "Academics",
    gradeLevel: null,
    grantManualAttendance: false,
    lastSessionAt: null,
    legacyId: null,
    maxWeeklySessions: null,
    name: null,
    nextStartsAt: "2023-08-17T12:00:00-07:00",
    notes: null,
    parentUpdateLastSentAt: null,
    possibleEvents: [
      "unpublish",
      "cancel"
    ],
    provisionedDuration: 600,
    remainingProvisionedDuration: 600,
    requireSubject: false,
    reservationEndsAt: null,
    restrictByLeadSource: false,
    schedule: [{
      name: "Tutoring",
      count: 25,
      days: [
        "Thursday at 10:30AM",
        "Thursday at 12:00PM",
        "Thursday at 2:15PM",
        "Thursday at 3:15PM",
        "Thursday at 8:30AM"
      ],
      timeZone: "PDT"
    }],
    startingEpisodeNumber: null,
    startsAt: "2023-08-17T12:00:00-07:00",
    title: "Academics",
    tutorScheduling: true,
    type: "Course",
    unscheduledDuration: -900,
    updatedAt: "2023-08-14T12:27:01-07:00",
    status: "published",
    requiresParentUpdate: false,
    staffed: false,
    thirdParty: false,
    shipmentsPresent: false,
    host: null
  },
  brand: {
    id: 41,
    description: null,
    departmentId: 1,
    forceDuration: null,
    handle: "academics-department-1",
    includePoints: false,
    offerArrearBilling: true,
    renewalDuration: 600,
    name: "Academics",
    includeParentUpdates: false,
    userflowToken: "78d58bbe47819c6360fd3752a0f1fba04ec0269066f2707a33d02d67f5474165"
  },
  blockoutAttendances: [],
  slotSubscriptions: [],
  tutors: [{
    avatarUrl: "https://localhost:3000/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN3BEQnc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6eb010343722451d208ef69d9288c42be5556b6b/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2QzNKbGMybDZaVWtpRFRFd01IZ3hNREErQmpzR1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--a2b8f2e44acc6748ef6cda7f241e6bf1f2ea2917/IMG_7128.jpeg",
    avatarLargeUrl: "https://localhost:3000/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN3BEQnc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6eb010343722451d208ef69d9288c42be5556b6b/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2QzNKbGMybDZaVWtpRFRNd01IZ3pNREErQmpzR1ZBPT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--21c22fbe9eceedec37130a7fc2003a269073986b/IMG_7128.jpeg",
    id: 3069,
    acuityHandle: null,
    essayGrader: false,
    extension: null,
    extensionHandle: null,
    firstName: "Mark",
    lastInitial: "W",
    online: true,
    primaryAcuityUser: false,
    timeZone: "America/Chicago",
    type: "Tutor",
    updatedAt: "2023-05-23T11:47:14-07:00",
    userId: 1459191,
    active: true,
    roles: ["tutor"],
    abbreviatedName: "Mark W.",
    acceptNewStudents: true,
    createdAt: "2023-01-13T13:22:51-08:00",
    featured: false,
    friendlyUrl: "//localhost:8888/tutors/mark-w",
    lockAcceptNewStudents: false,
    param: "mark-w",
    reviewsCount: 0,
    starRating: null,
    title: "Tutor",
    tutorType: "Professional",
    facultyLeader: false,
    curriculumWork: false,
    displayOnAdvisorSearch: true,
    displayOnWebsite: false,
    trainingRate: 15.4
  }]
};
var enrollmentWithTutoringHistories = {
  id: 704948,
  arrearBilling: false,
  attendancesCount: 58,
  attendancesAttendedCount: 18,
  canCancel: false,
  contractEndsAt: null,
  createdAt: "2023-06-19T18:32:05-04:00",
  isChild: false,
  limited: false,
  limitedAttendancesCount: 0,
  lockedAt: null,
  requiresSubscription: false,
  sessionDuration: 90,
  sessionFrequency: 1,
  startsAt: "2023-06-19T18:32:05-04:00",
  status: "Active",
  title: "Private Tutoring",
  trialEndsAt: null,
  type: "Enrollment",
  warnedAt: null,
  unenrolledAt: null,
  unenrolledReason: null,
  unenrolledNote: null,
  updatedAt: "2023-12-13T02:08:02-05:00",
  canConvertToContract: false,
  canConvertToDiscount: false,
  inTrial: false,
  tutoringHistories: [
    {
      id: 66720,
      active: true,
      brandName: "Private Tutoring",
      createdAt: "2023-06-19T18:43:44-04:00",
      endedAt: "2023-12-10T15:00:00-05:00",
      isNew: false,
      minutes: 1440,
      startedAt: "2023-06-28T19:00:00-04:00",
      updatedAt: "2023-12-10T15:50:29-05:00",
      tutor: {
        avatarUrl: "https://place-hold.it/100",
        avatarLargeUrl: "https://place-hold.it/100",
        id: 44,
        acuityHandle: null,
        essayGrader: false,
        extension: null,
        extensionHandle: null,
        firstName: "Tasha",
        lastInitial: "T",
        online: true,
        primaryAcuityUser: false,
        timeZone: "America/Chicago",
        type: "Tutor",
        updatedAt: "2023-11-26T06:33:52-05:00",
        userId: 454,
        active: true,
        roles: [
          "tutor"
        ],
        abbreviatedName: "Tasha T.",
        acceptNewStudents: true,
        createdAt: "2007-08-26T20:00:00-04:00",
        featured: false,
        friendlyUrl: "//localhost:8888/tutors/tasha",
        lockAcceptNewStudents: false,
        param: "tasha",
        reviewsCount: 317,
        starRating: 4.918,
        title: "Tutor",
        tutorType: "Professional",
        facultyLeader: false
      },
      recentSubjects: [
        {
          id: 315,
          aliasedSubjectId: 51,
          digitalExam: true,
          lowGrade: "6",
          highGrade: "14",
          homeworkHelp: false,
          name: "SAT",
          param: "sat",
          categoryName: "Test Prep"
        },
        {
          id: 51,
          aliasedSubjectId: 147,
          digitalExam: false,
          lowGrade: "6",
          highGrade: "14",
          homeworkHelp: true,
          name: "Paper SAT",
          param: "paper-sat",
          categoryName: "Test Prep"
        }
      ]
    }
  ]
};

// src/lib/event.ts
var events = [
  {
    id: 100123,
    details: "Location: Awesome School",
    departmentId: null,
    duration: null,
    endsAt: "2021-01-30",
    name: "SAT Practice Test",
    startsAt: "2021-01-30T07:00:00-05:00",
    type: "Event",
    identifier: "Event",
    allDay: true,
    attendee: student,
    department: null,
    subject
  },
  {
    id: 100222,
    details: "Location: Awesome School",
    departmentId: null,
    duration: null,
    endsAt: "2021-02-03",
    name: "Calculus Test",
    startsAt: "2021-02-03T07:00:00-05:00",
    type: "Event",
    identifier: "Event",
    allDay: true,
    attendee: student,
    department: null,
    subject: subjects[2]
  },
  {
    id: 100444,
    details: "",
    departmentId: null,
    duration: null,
    endsAt: "2021-02-05",
    name: "Biology Lab",
    startsAt: "2021-02-05T07:00:00-05:00",
    type: "Event",
    identifier: "Event",
    allDay: true,
    attendee: student,
    department: null,
    subject: subjects[9]
  },
  {
    id: 100555,
    details: "",
    departmentId: null,
    duration: null,
    endsAt: "2021-02-02",
    name: "SAT Prep",
    startsAt: "2021-02-02T07:00:00-05:00",
    type: "Event",
    identifier: "Event",
    allDay: true,
    attendee: student,
    department: null,
    subject
  },
  {
    id: 100999,
    details: "",
    departmentId: null,
    duration: null,
    endsAt: "2021-02-02",
    name: "",
    startsAt: "2021-02-02T07:00:00-05:00",
    type: "Event",
    identifier: "Event",
    allDay: true,
    attendee: student,
    department: null,
    subject
  }
];

// src/lib/exam.ts
var exams = [
  {
    id: 1,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 1A",
    series: null,
    form_code: "1A",
    active: true,
    has_essay: false
  },
  {
    id: 2,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 2A",
    series: null,
    form_code: "2A",
    active: true,
    has_essay: false
  },
  {
    id: 3,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 3A",
    series: null,
    form_code: "3A",
    active: true,
    has_essay: false
  },
  {
    id: 4,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 4A",
    series: null,
    form_code: "4A",
    active: true,
    has_essay: false
  },
  {
    id: 5,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 5A",
    series: null,
    form_code: "5A",
    active: true,
    has_essay: false
  },
  {
    id: 25,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_11A.pdf",
    exam_type: "ACT",
    gatekeep: false,
    name: "ACT 11A",
    series: null,
    form_code: "11A",
    active: true,
    has_essay: false
  },
  {
    id: 34,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_12A.pdf",
    exam_type: "ACT",
    gatekeep: false,
    name: "ACT 12A",
    series: null,
    form_code: "12A",
    active: true,
    has_essay: false
  },
  {
    id: 37,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_1B.pdf",
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 1B",
    series: null,
    form_code: "1B",
    active: true,
    has_essay: false
  },
  {
    id: 38,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_2B.pdf",
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 2B",
    series: null,
    form_code: "2B",
    active: true,
    has_essay: false
  },
  {
    id: 39,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_3B.pdf",
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 3B",
    series: null,
    form_code: "3B",
    active: true,
    has_essay: false
  },
  {
    id: 40,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_4B.pdf",
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 4B",
    series: null,
    form_code: "4B",
    active: true,
    has_essay: false
  },
  {
    id: 41,
    booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_5B.pdf",
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 5B",
    series: null,
    form_code: "5B",
    active: true,
    has_essay: false
  },
  {
    id: 72,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 11B",
    series: null,
    form_code: "11B",
    active: true,
    has_essay: false
  },
  {
    id: 73,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 12B",
    series: null,
    form_code: "12B",
    active: true,
    has_essay: false
  },
  {
    id: 66,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 13B",
    series: null,
    form_code: "13B",
    active: true,
    has_essay: false
  },
  {
    id: 42,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 1C",
    series: null,
    form_code: "1C",
    active: true,
    has_essay: false
  },
  {
    id: 43,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 2C",
    series: null,
    form_code: "2C",
    active: true,
    has_essay: false
  },
  {
    id: 58,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 3C",
    series: null,
    form_code: "3C",
    active: true,
    has_essay: false
  },
  {
    id: 60,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 4C",
    series: null,
    form_code: "4C",
    active: true,
    has_essay: false
  },
  {
    id: 62,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 5C",
    series: null,
    form_code: "5C",
    active: true,
    has_essay: false
  },
  {
    id: 12,
    booklet_url: null,
    exam_type: "ACT",
    gatekeep: true,
    name: "ACT 7J",
    series: null,
    form_code: "7J",
    active: true,
    has_essay: false
  }
];
var examTypes = ["ACT", "PSAT", "SAT", "AP"];
var sortedExams = {
  ACT: [
    {
      id: 1,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 1A",
      series: null,
      form_code: "1A",
      active: true,
      has_essay: false
    },
    {
      id: 2,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 2A",
      series: null,
      form_code: "2A",
      active: true,
      has_essay: false
    },
    {
      id: 3,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 3A",
      series: null,
      form_code: "3A",
      active: true,
      has_essay: false
    },
    {
      id: 4,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 4A",
      series: null,
      form_code: "4A",
      active: true,
      has_essay: false
    },
    {
      id: 5,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 5A",
      series: null,
      form_code: "5A",
      active: true,
      has_essay: false
    },
    {
      id: 25,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_11A.pdf",
      exam_type: "ACT",
      gatekeep: false,
      name: "ACT 11A",
      series: null,
      form_code: "11A",
      active: true,
      has_essay: false
    },
    {
      id: 34,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_12A.pdf",
      exam_type: "ACT",
      gatekeep: false,
      name: "ACT 12A",
      series: null,
      form_code: "12A",
      active: true,
      has_essay: false
    },
    {
      id: 37,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_1B.pdf",
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 1B",
      series: null,
      form_code: "1B",
      active: true,
      has_essay: false
    },
    {
      id: 38,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_2B.pdf",
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 2B",
      series: null,
      form_code: "2B",
      active: true,
      has_essay: false
    },
    {
      id: 39,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_3B.pdf",
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 3B",
      series: null,
      form_code: "3B",
      active: true,
      has_essay: false
    },
    {
      id: 40,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_4B.pdf",
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 4B",
      series: null,
      form_code: "4B",
      active: true,
      has_essay: false
    },
    {
      id: 41,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/ACT/ACT_5B.pdf",
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 5B",
      series: null,
      form_code: "5B",
      active: true,
      has_essay: false
    },
    {
      id: 72,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 11B",
      series: null,
      form_code: "11B",
      active: true,
      has_essay: false
    },
    {
      id: 73,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 12B",
      series: null,
      form_code: "12B",
      active: true,
      has_essay: false
    },
    {
      id: 66,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 13B",
      series: null,
      form_code: "13B",
      active: true,
      has_essay: false
    },
    {
      id: 42,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 1C",
      series: null,
      form_code: "1C",
      active: true,
      has_essay: false
    },
    {
      id: 43,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 2C",
      series: null,
      form_code: "2C",
      active: true,
      has_essay: false
    },
    {
      id: 58,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 3C",
      series: null,
      form_code: "3C",
      active: true,
      has_essay: false
    },
    {
      id: 60,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 4C",
      series: null,
      form_code: "4C",
      active: true,
      has_essay: false
    },
    {
      id: 62,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 5C",
      series: null,
      form_code: "5C",
      active: true,
      has_essay: false
    },
    {
      id: 12,
      booklet_url: null,
      exam_type: "ACT",
      gatekeep: true,
      name: "ACT 7J",
      series: null,
      form_code: "7J",
      active: true,
      has_essay: false
    }
  ],
  AP: [
    { id: 83, booklet_url: null, exam_type: "AP", name: "AP Biology 1W", series: "Biology", form_code: "1W", active: true, has_essay: false, gatekeep: true },
    { id: 86, booklet_url: null, exam_type: "AP", name: "AP Chemistry 2W", series: "Chemistry", form_code: "2W", active: true, has_essay: false, gatekeep: true },
    { id: 87, booklet_url: null, exam_type: "AP", name: "AP Physics 1 3W", series: "Physics 1", form_code: "3W", active: true, has_essay: false, gatekeep: true },
    { id: 84, booklet_url: null, exam_type: "AP", name: "AP Calculus AB 4W", series: "Calculus AB", form_code: "4W", active: true, has_essay: false, gatekeep: true },
    { id: 85, booklet_url: null, exam_type: "AP", name: "AP Calculus BC 5W", series: "Calculus BC", form_code: "5W", active: true, has_essay: false, gatekeep: true },
    { id: 82, booklet_url: null, exam_type: "AP", name: "AP US History 6W", series: "US History", form_code: "6W", active: true, has_essay: false, gatekeep: true },
    { id: 99, booklet_url: null, exam_type: "AP", name: "AP US History 7W", series: "US History", form_code: "7W", active: true, has_essay: false, gatekeep: true }
    // fake exam
  ],
  PSAT: [
    {
      id: 68,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 1N",
      series: null,
      form_code: "1N",
      active: true,
      has_essay: false
    },
    {
      id: 33,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_1P.pdf",
      exam_type: "PSAT",
      gatekeep: false,
      name: "PSAT 1P",
      series: null,
      form_code: "1P",
      active: true,
      has_essay: false
    },
    {
      id: 52,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_2P.pdf",
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 2P",
      series: null,
      form_code: "2P",
      active: true,
      has_essay: false
    },
    {
      id: 53,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_3P.pdf",
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 3P",
      series: null,
      form_code: "3P",
      active: true,
      has_essay: false
    },
    {
      id: 35,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_4P.pdf",
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 4P",
      series: null,
      form_code: "4P",
      active: true,
      has_essay: false
    },
    {
      id: 54,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_5P.pdf",
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 5P",
      series: null,
      form_code: "5P",
      active: true,
      has_essay: false
    },
    {
      id: 55,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 6P",
      series: null,
      form_code: "6P",
      active: true,
      has_essay: false
    },
    {
      id: 63,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 7P",
      series: null,
      form_code: "7P",
      active: true,
      has_essay: false
    },
    {
      id: 64,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 8P",
      series: null,
      form_code: "8P",
      active: true,
      has_essay: false
    },
    {
      id: 65,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 9P",
      series: null,
      form_code: "9P",
      active: true,
      has_essay: false
    },
    {
      id: 67,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 10P",
      series: null,
      form_code: "10P",
      active: true,
      has_essay: false
    },
    {
      id: 14,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 8P",
      series: null,
      form_code: "13P",
      active: true,
      has_essay: false
    },
    {
      id: 23,
      booklet_url: null,
      exam_type: "PSAT",
      gatekeep: true,
      name: "PSAT 9P",
      series: null,
      form_code: "14P",
      active: true,
      has_essay: false
    },
    {
      id: 56,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/PSAT/PSAT_15P.pdf",
      exam_type: "PSAT",
      gatekeep: false,
      name: "PSAT 7P",
      series: null,
      form_code: "15P",
      active: true,
      has_essay: false
    }
  ],
  SAT: [
    {
      id: 15,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 1R",
      series: null,
      form_code: "1R",
      active: true,
      has_essay: false
    },
    {
      id: 16,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 2R",
      series: null,
      form_code: "2R",
      active: true,
      has_essay: false
    },
    {
      id: 17,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 3R",
      series: null,
      form_code: "3R",
      active: true,
      has_essay: false
    },
    {
      id: 18,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 4R",
      series: null,
      form_code: "4R",
      active: true,
      has_essay: false
    },
    {
      id: 19,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 5R",
      series: null,
      form_code: "5R",
      active: true,
      has_essay: false
    },
    {
      id: 20,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 6R",
      series: null,
      form_code: "6R",
      active: true,
      has_essay: false
    },
    {
      id: 21,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 7R",
      series: null,
      form_code: "7R",
      active: true,
      has_essay: false
    },
    {
      id: 24,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 8R",
      series: null,
      form_code: "8R",
      active: true,
      has_essay: false
    },
    {
      id: 22,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 9R",
      series: null,
      form_code: "9R",
      active: true,
      has_essay: false
    },
    {
      id: 61,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_14R.pdf",
      exam_type: "SAT",
      gatekeep: false,
      name: "SAT 14R",
      series: null,
      form_code: "14R",
      active: true,
      has_essay: false
    },
    {
      id: 46,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_15R.pdf",
      exam_type: "SAT",
      gatekeep: false,
      name: "SAT 15R",
      series: null,
      form_code: "15R",
      active: true,
      has_essay: false
    },
    {
      id: 47,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_1S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 1S",
      series: null,
      form_code: "1S",
      active: true,
      has_essay: false
    },
    {
      id: 48,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_2S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 2S",
      series: null,
      form_code: "2S",
      active: true,
      has_essay: false
    },
    {
      id: 49,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_3S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 3S",
      series: null,
      form_code: "3S",
      active: true,
      has_essay: false
    },
    {
      id: 50,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_4S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 4S",
      series: null,
      form_code: "4S",
      active: true,
      has_essay: false
    },
    {
      id: 51,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_5S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 5S",
      series: null,
      form_code: "5S",
      active: true,
      has_essay: false
    },
    {
      id: 57,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_6S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 6S",
      series: null,
      form_code: "6S",
      active: true,
      has_essay: false
    },
    {
      id: 59,
      booklet_url: "https://exams-production.s3.amazonaws.com/practice_exams/SAT/SAT_7S.pdf",
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 7S",
      series: null,
      form_code: "7S",
      active: true,
      has_essay: false
    },
    {
      id: 69,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 8S",
      series: null,
      form_code: "8S",
      active: true,
      has_essay: false
    },
    {
      id: 70,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 9S",
      series: null,
      form_code: "9S",
      active: true,
      has_essay: false
    },
    {
      id: 71,
      booklet_url: null,
      exam_type: "SAT",
      gatekeep: true,
      name: "SAT 10S",
      series: null,
      form_code: "10S",
      active: true,
      has_essay: false
    }
  ]
};
var examToProctor = {
  examType: "ACT",
  answersheetUrl: "https://revolutionprep.com/ACT-Answer-Sheet.pdf",
  sections: [
    {
      include: true,
      name: "Introduction",
      type: "",
      duration: 0.5,
      clips: [
        {
          text: "Introduction",
          startTimer: "start",
          startsAt: 0,
          canProceed: false,
          url: "global/intro.mp3",
          id: "global-intro-mp3"
        }
      ]
    },
    {
      include: true,
      name: "Test 1",
      type: "English",
      duration: 45,
      clips: [
        {
          text: "Instructions",
          startTimer: "after",
          startsAt: 0,
          url: "act/test-1.mp3",
          id: "act-test-1-mp3"
        },
        {
          text: "5 minutes remaining",
          startsAt: 40,
          url: "global/5-minute-warning.mp3",
          id: "global-5-minute-warning-mp3"
        },
        {
          text: "Time is up!",
          startsAt: 45,
          canProceed: true,
          url: "global/time-is-up.mp3",
          id: "global-time-is-up-mp3"
        }
      ]
    },
    {
      include: true,
      name: "Test 2",
      type: "Math",
      duration: 60,
      clips: [
        {
          text: "Instructions",
          startTimer: "after",
          startsAt: 0,
          url: "act/test-2.mp3",
          id: "act-test-2-mp3"
        },
        {
          text: "5 minutes remaining",
          startsAt: 55,
          url: "global/5-minute-warning.mp3",
          id: "global-5-minute-warning-mp3"
        },
        {
          text: "Time is up!",
          startsAt: 60,
          canProceed: true,
          url: "global/time-is-up-alt.mp3",
          id: "global-time-is-up-alt-mp3"
        }
      ]
    },
    {
      include: true,
      name: "Break",
      type: "",
      duration: 10,
      clips: [
        {
          text: "10 minute break",
          startTimer: "start",
          startsAt: 0,
          url: "global/10-minute-break.mp3",
          id: "global-10-minute-break-mp3"
        },
        {
          text: "Break is over",
          startsAt: 10,
          url: "global/attention_alert_001.wav",
          id: "global-attention_alert_001-wav"
        }
      ]
    },
    {
      include: true,
      name: "Test 3",
      type: "Reading",
      duration: 35,
      clips: [
        {
          text: "Instructions",
          startTimer: "after",
          startsAt: 0,
          url: "act/test-3.mp3",
          id: "act-test-3-mp3"
        },
        {
          text: "5 minutes remaining",
          startsAt: 30,
          url: "global/5-minute-warning.mp3",
          id: "global-5-minute-warning-mp3"
        },
        {
          text: "Time is up!",
          startsAt: 35,
          canProceed: true,
          url: "global/time-is-up-alt.mp3",
          id: "global-time-is-up-alt-mp3"
        }
      ]
    },
    {
      include: true,
      name: "Test 4",
      type: "Science",
      duration: 35,
      clips: [
        {
          text: "Instructions",
          startTimer: "after",
          startsAt: 0,
          url: "act/test-4.mp3",
          id: "act-test-4-mp3"
        },
        {
          text: "5 minutes remaining",
          startsAt: 30,
          url: "global/5-minute-warning.mp3",
          id: "global-5-minute-warning-mp3"
        },
        {
          text: "Time is up!",
          startsAt: 35,
          canProceed: true,
          url: "global/time-is-up.mp3",
          id: "global-time-is-up-mp3"
        }
      ]
    },
    {
      include: true,
      name: "Break",
      type: "",
      duration: 5,
      clips: [
        {
          text: "5 minute break",
          startTimer: "start",
          startsAt: 0,
          url: "global/5-minute-break.mp3",
          id: "global-5-minute-break-mp3"
        },
        {
          text: "Break is over",
          startsAt: 5,
          url: "global/attention_alert_001.wav",
          id: "global-attention_alert_001-wav"
        }
      ]
    },
    {
      include: true,
      name: "Test 5",
      type: "Essay",
      duration: 40,
      clips: [
        {
          text: "Instructions",
          startTimer: "after",
          startsAt: 0,
          url: "act/test-5.mp3",
          id: "act-test-5-mp3"
        },
        {
          text: "5 minutes remaining",
          startsAt: 35,
          url: "global/5-minute-warning.mp3",
          id: "global-5-minute-warning-mp3"
        },
        {
          text: "Time is up!",
          startsAt: 40,
          canProceed: true,
          url: "global/time-is-up-alt.mp3",
          id: "global-time-is-up-alt-mp3"
        }
      ]
    }
  ],
  duration: 230.5,
  hours: 4
};

// src/lib/exam-transcript.ts
var user = {
  id: 12345,
  first_name: "Ash",
  last_name: "Ketchum",
  handle: 123456
};
var transcripts = [
  {
    id: 123456,
    created_at: "2019-08-12T06:00:21.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2019-08-12T06:00:21.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2019-08-12T06:00:21.000-07:00",
      exam_type: "SAT",
      attempt: 2,
      name: "SAT 2R",
      series: null,
      form_code: "2R",
      score: 1600,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 800, raw_score: 58 },
        { name: "Reading and Writing", score: 800, raw_score: 96 }
      ],
      child_subjects: [
        { name: "Reading", score: 40, raw_score: 52 },
        { name: "Writing and Language", score: 40, raw_score: 44 }
      ]
    },
    essay: {
      id: 123456,
      score: 6,
      components: [
        { name: "Reading", score: 2 },
        { name: "Writing", score: 2 },
        { name: "Analysis", score: 2 }
      ],
      create_url: null
    }
  },
  {
    id: 124680,
    created_at: "2019-08-03T14:30:50.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2019-08-03T14:30:50.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2019-08-03T14:30:50.000-07:00",
      exam_type: "SAT",
      attempt: 1,
      name: "SAT 1S",
      series: null,
      form_code: "1S",
      score: 710,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 310, raw_score: 8 },
        { name: "Reading and Writing", score: 400, raw_score: 31 }
      ],
      child_subjects: [
        { name: "Reading", score: 20, raw_score: 17 },
        { name: "Writing and Language", score: 20, raw_score: 14 }
      ]
    },
    essay: {
      id: 144444,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 42424,
    created_at: "2017-11-18T09:27:42.000-08:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-11-18T09:27:42.000-08:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-11-18T09:27:42.000-08:00",
      exam_type: "SAT",
      attempt: 3,
      name: "SAT 9R",
      series: null,
      form_code: "9R",
      score: 1160,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 610, raw_score: 34 },
        { name: "Reading and Writing", score: 550, raw_score: 56 }
      ],
      child_subjects: [
        { name: "Reading", score: 27, raw_score: 29 },
        { name: "Writing and Language", score: 28, raw_score: 27 }
      ]
    },
    essay: {
      id: 74747,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 41111,
    created_at: "2017-10-22T07:01:07.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-10-22T07:01:07.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-10-22T07:01:07.000-07:00",
      exam_type: "SAT",
      attempt: 2,
      name: "SAT 1R",
      series: null,
      form_code: "1R",
      score: 1280,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 660, raw_score: 45 },
        { name: "Reading and Writing", score: 620, raw_score: 70 }
      ],
      child_subjects: [
        { name: "Reading", score: 35, raw_score: 43 },
        { name: "Writing and Language", score: 27, raw_score: 27 }
      ]
    },
    essay: {
      id: 60606,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 1357,
    created_at: "2017-08-16T22:00:03.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-08-16T22:00:03.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-08-16T22:00:03.000-07:00",
      exam_type: "SAT",
      attempt: 1,
      name: "SAT 6R",
      series: null,
      form_code: "6R",
      score: 1200,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 600, raw_score: 37 },
        { name: "Reading and Writing", score: 600, raw_score: 68 }
      ],
      child_subjects: [
        { name: "Reading", score: 30, raw_score: 38 },
        { name: "Writing and Language", score: 30, raw_score: 30 }
      ]
    },
    essay: {
      id: 35353,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 2222,
    created_at: "2017-08-04T17:31:28.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-08-04T17:31:28.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-08-04T17:31:28.000-07:00",
      exam_type: "SAT",
      attempt: 1,
      name: "SAT 5R",
      series: null,
      form_code: "5R",
      score: 1180,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 580, raw_score: 35 },
        { name: "Reading and Writing", score: 600, raw_score: 68 }
      ],
      child_subjects: [
        { name: "Reading", score: 30, raw_score: 37 },
        { name: "Writing and Language", score: 30, raw_score: 31 }
      ]
    },
    essay: {
      id: 34444,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 3050,
    created_at: "2017-07-25T14:11:50.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-07-25T14:11:50.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-07-25T14:11:50.000-07:00",
      exam_type: "SAT",
      attempt: 1,
      name: "SAT 4R",
      series: null,
      form_code: "4R",
      score: 1280,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 670, raw_score: 40 },
        { name: "Reading and Writing", score: 610, raw_score: 68 }
      ],
      child_subjects: [
        { name: "Reading", score: 29, raw_score: 35 },
        { name: "Writing and Language", score: 32, raw_score: 33 }
      ]
    },
    essay: {
      id: 33333,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 3789,
    created_at: "2017-07-11T17:50:17.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-07-11T17:50:17.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-07-11T17:50:17.000-07:00",
      exam_type: "SAT",
      attempt: 2,
      name: "SAT 3R",
      series: null,
      form_code: "3R",
      score: 1190,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 590, raw_score: 33 },
        { name: "Reading and Writing", score: 600, raw_score: 64 }
      ],
      child_subjects: [
        { name: "Reading", score: 30, raw_score: 34 },
        { name: "Writing and Language", score: 30, raw_score: 30 }
      ]
    },
    essay: {
      id: 34567,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 5777,
    created_at: "2017-05-27T11:25:11.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-05-27T11:25:11.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-05-27T11:25:11.000-07:00",
      exam_type: "SAT",
      attempt: 1,
      name: "SAT 2R",
      series: null,
      form_code: "2R",
      score: 1090,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 540, raw_score: 29 },
        { name: "Reading and Writing", score: 550, raw_score: 59 }
      ],
      child_subjects: [
        { name: "Reading", score: 28, raw_score: 31 },
        { name: "Writing and Language", score: 27, raw_score: 28 }
      ]
    },
    essay: {
      id: 39999,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  },
  {
    id: 8999,
    created_at: "2017-04-15T11:24:06.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2017-04-15T11:24:06.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2017-04-15T11:24:06.000-07:00",
      exam_type: "SAT",
      attempt: 2,
      name: "SAT 1R",
      series: null,
      form_code: "1R",
      score: 970,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 560, raw_score: 33 },
        { name: "Reading and Writing", score: 410, raw_score: 36 }
      ],
      child_subjects: [
        { name: "Reading", score: 31, raw_score: 36 },
        { name: "Writing and Language", score: 10, raw_score: 0 }
      ]
    },
    essay: {
      id: 28888,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  }
];
var ACTTranscripts = [
  {
    id: 122334,
    created_at: "2020-07-25T12:08:31.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2020-07-25T12:08:31.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2020-07-25T12:08:31.000-07:00",
      exam_type: "ACT",
      attempt: 1,
      name: "ACT 11A",
      series: null,
      form_code: "11A",
      score: 13,
      complete: true,
      subjects: [
        {
          name: "English",
          score: 14,
          raw_score: 27
        },
        {
          name: "Mathematics",
          score: 13,
          raw_score: 9
        },
        {
          name: "Reading",
          score: 16,
          raw_score: 16
        },
        {
          name: "Science",
          score: 8,
          raw_score: 5
        }
      ]
    },
    essay: {
      id: 223344,
      score: null,
      components: [],
      create_url: "https://localhost:3001/essay/new"
    }
  }
];
var PSATTranscripts = [
  {
    id: 205050,
    created_at: "2020-09-10T15:55:32.000-07:00",
    error_log_url: null,
    report_url: "http://localhost:8888/report.pdf",
    updated_at: "2020-09-10T15:55:32.000-07:00",
    user,
    exam: {
      all_sections_scored: true,
      scored_on: "2020-09-10T15:55:32.000-07:00",
      exam_type: "PSAT",
      attempt: 1,
      name: "PSAT 1P",
      series: null,
      form_code: "1P",
      score: 400,
      complete: true,
      subjects: [
        { name: "Mathematics", score: 200, raw_score: 2 },
        { name: "Reading and Writing", score: 200, raw_score: 4 }
      ],
      child_subjects: [
        { name: "Reading", score: 10, raw_score: 2 },
        { name: "Writing and Language", score: 10, raw_score: 2 }
      ]
    }
  }
];

// src/lib/exam-transcript-scan.ts
var examTranscriptScan = {
  pages: [
    {
      qr: "some-qr",
      label: "1",
      pageNumber: 1,
      regions: [
        {
          type: "formcode",
          label: "Test Code",
          labelCode: "test-code",
          answerChoiceOptions: [
            [
              "1R",
              "2R",
              "3R",
              "4R",
              "5R",
              "6R",
              "7R",
              "8R",
              "9R",
              "14R",
              "15R",
              "1S",
              "2S",
              "3S",
              "4S",
              "5S",
              "6S",
              "7S",
              "8S",
              "9S",
              "10S"
            ]
          ],
          questionCount: 1,
          sectionId: 0,
          indexOffset: 1,
          locations: [
            {
              startIndex: 0,
              repeat: 2,
              x: 40,
              y: 239,
              xOffset: 33.1,
              yOffset: 30
            }
          ],
          questions: [
            {
              controlType: "dropdown",
              key: "formCode-0-0",
              label: "Test Code",
              locations: [
                [
                  59,
                  215,
                  22,
                  21
                ],
                [
                  88,
                  214,
                  22,
                  21
                ],
                [
                  117,
                  214,
                  22,
                  21
                ],
                [
                  146,
                  214,
                  22,
                  21
                ],
                [
                  175,
                  214,
                  22,
                  21
                ],
                [
                  204,
                  214,
                  22,
                  21
                ],
                [
                  233,
                  214,
                  22,
                  21
                ],
                [
                  262,
                  214,
                  22,
                  21
                ],
                [
                  292,
                  214,
                  22,
                  21
                ],
                [
                  321,
                  214,
                  22,
                  21
                ],
                [
                  350,
                  214,
                  22,
                  21
                ],
                [
                  379,
                  214,
                  22,
                  21
                ],
                [
                  408,
                  214,
                  22,
                  21
                ],
                [
                  438,
                  214,
                  22,
                  21
                ],
                [
                  467,
                  214,
                  22,
                  21
                ]
              ],
              options: [
                {
                  key: "1R",
                  value: "1R"
                },
                {
                  key: "2R",
                  value: "2R"
                },
                {
                  key: "3R",
                  value: "3R"
                },
                {
                  key: "4R",
                  value: "4R"
                },
                {
                  key: "5R",
                  value: "5R"
                },
                {
                  key: "6R",
                  value: "6R"
                },
                {
                  key: "7R",
                  value: "7R"
                },
                {
                  key: "8R",
                  value: "8R"
                },
                {
                  key: "9R",
                  value: "9R"
                },
                {
                  key: "14R",
                  value: "14R"
                },
                {
                  key: "15R",
                  value: "15R"
                },
                {
                  key: "1S",
                  value: "1S"
                },
                {
                  key: "2S",
                  value: "2S"
                },
                {
                  key: "3S",
                  value: "3S"
                },
                {
                  key: "4S",
                  value: "4S"
                },
                {
                  key: "5S",
                  value: "5S"
                },
                {
                  key: "6S",
                  value: "6S"
                },
                {
                  key: "7S",
                  value: "7S"
                },
                {
                  key: "8S",
                  value: "8S"
                },
                {
                  key: "9S",
                  value: "9S"
                },
                {
                  key: "10S",
                  value: "10S"
                }
              ],
              order: 0,
              originalValue: "14R",
              required: true,
              value: "14R"
            }
          ]
        },
        {
          type: "multipleChoice",
          label: "Section 1",
          labelCode: "section-1",
          answerChoiceOptions: [
            [
              "A",
              "B",
              "C",
              "D"
            ]
          ],
          questionCount: 52,
          sectionId: 1,
          indexOffset: 1,
          locations: [
            {
              startIndex: 0,
              repeat: 15,
              x: 135,
              y: 573,
              xOffset: 30,
              yOffset: 27.5
            },
            {
              startIndex: 15,
              repeat: 15,
              x: 344,
              y: 573,
              xOffset: 30,
              yOffset: 27.5
            },
            {
              startIndex: 30,
              repeat: 15,
              x: 541,
              y: 573,
              xOffset: 30,
              yOffset: 27.5
            },
            {
              startIndex: 45,
              repeat: 7,
              x: 741,
              y: 573,
              xOffset: 30,
              yOffset: 27.5
            }
          ],
          questions: [
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-0",
              label: "1",
              locations: [
                [
                  115,
                  500,
                  21,
                  17
                ],
                [
                  143,
                  500,
                  21,
                  17
                ],
                [
                  170,
                  500,
                  21,
                  17
                ],
                [
                  197,
                  500,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 0,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-1",
              label: "2",
              locations: [
                [
                  116,
                  524,
                  21,
                  17
                ],
                [
                  143,
                  524,
                  21,
                  17
                ],
                [
                  170,
                  525,
                  21,
                  17
                ],
                [
                  197,
                  525,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 1,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-2",
              label: "3",
              locations: [
                [
                  116,
                  548,
                  21,
                  17
                ],
                [
                  143,
                  548,
                  21,
                  17
                ],
                [
                  170,
                  549,
                  21,
                  17
                ],
                [
                  198,
                  549,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 2,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-3",
              label: "4",
              locations: [
                [
                  116,
                  572,
                  21,
                  17
                ],
                [
                  143,
                  572,
                  21,
                  17
                ],
                [
                  170,
                  572,
                  21,
                  17
                ],
                [
                  198,
                  572,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 3,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-4",
              label: "5",
              locations: [
                [
                  116,
                  596,
                  21,
                  17
                ],
                [
                  143,
                  596,
                  21,
                  17
                ],
                [
                  171,
                  596,
                  21,
                  17
                ],
                [
                  198,
                  596,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 4,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-5",
              label: "6",
              locations: [
                [
                  116,
                  620,
                  21,
                  17
                ],
                [
                  144,
                  620,
                  21,
                  17
                ],
                [
                  171,
                  620,
                  21,
                  17
                ],
                [
                  198,
                  621,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 5,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-6",
              label: "7",
              locations: [
                [
                  116,
                  644,
                  21,
                  17
                ],
                [
                  144,
                  644,
                  21,
                  17
                ],
                [
                  171,
                  645,
                  21,
                  17
                ],
                [
                  198,
                  645,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 6,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-7",
              label: "8",
              locations: [
                [
                  117,
                  668,
                  21,
                  17
                ],
                [
                  144,
                  669,
                  21,
                  17
                ],
                [
                  171,
                  669,
                  21,
                  17
                ],
                [
                  198,
                  669,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 7,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-8",
              label: "9",
              locations: [
                [
                  116,
                  693,
                  21,
                  17
                ],
                [
                  144,
                  693,
                  21,
                  17
                ],
                [
                  171,
                  693,
                  21,
                  17
                ],
                [
                  198,
                  693,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 8,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-9",
              label: "10",
              locations: [
                [
                  117,
                  716,
                  21,
                  17
                ],
                [
                  144,
                  717,
                  21,
                  17
                ],
                [
                  171,
                  717,
                  21,
                  17
                ],
                [
                  198,
                  717,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 9,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-10",
              label: "11",
              locations: [
                [
                  116,
                  741,
                  21,
                  17
                ],
                [
                  144,
                  740,
                  21,
                  17
                ],
                [
                  171,
                  740,
                  21,
                  17
                ],
                [
                  199,
                  740,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 10,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-11",
              label: "12",
              locations: [
                [
                  117,
                  764,
                  21,
                  17
                ],
                [
                  144,
                  765,
                  21,
                  17
                ],
                [
                  171,
                  765,
                  21,
                  17
                ],
                [
                  199,
                  765,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 11,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-12",
              label: "13",
              locations: [
                [
                  117,
                  788,
                  21,
                  17
                ],
                [
                  144,
                  788,
                  21,
                  17
                ],
                [
                  171,
                  788,
                  21,
                  17
                ],
                [
                  199,
                  788,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 12,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-13",
              label: "14",
              locations: [
                [
                  117,
                  813,
                  21,
                  17
                ],
                [
                  144,
                  812,
                  21,
                  17
                ],
                [
                  172,
                  812,
                  21,
                  17
                ],
                [
                  199,
                  812,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 13,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-14",
              label: "15",
              locations: [
                [
                  117,
                  837,
                  21,
                  17
                ],
                [
                  144,
                  837,
                  21,
                  17
                ],
                [
                  172,
                  836,
                  21,
                  17
                ],
                [
                  199,
                  836,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 14,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-15",
              label: "16",
              locations: [
                [
                  299,
                  501,
                  21,
                  17
                ],
                [
                  327,
                  501,
                  21,
                  17
                ],
                [
                  354,
                  501,
                  21,
                  17
                ],
                [
                  381,
                  501,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 15,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-16",
              label: "17",
              locations: [
                [
                  300,
                  526,
                  21,
                  17
                ],
                [
                  326,
                  526,
                  21,
                  17
                ],
                [
                  354,
                  525,
                  21,
                  17
                ],
                [
                  381,
                  526,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 16,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-17",
              label: "18",
              locations: [
                [
                  300,
                  549,
                  21,
                  17
                ],
                [
                  327,
                  550,
                  21,
                  17
                ],
                [
                  354,
                  550,
                  21,
                  17
                ],
                [
                  381,
                  550,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 17,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-18",
              label: "19",
              locations: [
                [
                  300,
                  573,
                  21,
                  17
                ],
                [
                  327,
                  573,
                  21,
                  17
                ],
                [
                  354,
                  573,
                  21,
                  17
                ],
                [
                  381,
                  573,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 18,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-19",
              label: "20",
              locations: [
                [
                  300,
                  597,
                  21,
                  17
                ],
                [
                  327,
                  597,
                  21,
                  17
                ],
                [
                  354,
                  597,
                  21,
                  17
                ],
                [
                  381,
                  597,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 19,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-20",
              label: "21",
              locations: [
                [
                  300,
                  621,
                  21,
                  17
                ],
                [
                  327,
                  621,
                  21,
                  17
                ],
                [
                  354,
                  621,
                  21,
                  17
                ],
                [
                  381,
                  622,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 20,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-21",
              label: "22",
              locations: [
                [
                  300,
                  645,
                  21,
                  17
                ],
                [
                  327,
                  645,
                  21,
                  17
                ],
                [
                  354,
                  645,
                  21,
                  17
                ],
                [
                  381,
                  645,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 21,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-22",
              label: "23",
              locations: [
                [
                  300,
                  669,
                  21,
                  17
                ],
                [
                  327,
                  669,
                  21,
                  17
                ],
                [
                  354,
                  670,
                  21,
                  17
                ],
                [
                  381,
                  669,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 22,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-23",
              label: "24",
              locations: [
                [
                  301,
                  693,
                  21,
                  17
                ],
                [
                  327,
                  693,
                  21,
                  17
                ],
                [
                  354,
                  693,
                  21,
                  17
                ],
                [
                  382,
                  693,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 23,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-24",
              label: "25",
              locations: [
                [
                  301,
                  717,
                  21,
                  17
                ],
                [
                  328,
                  717,
                  21,
                  17
                ],
                [
                  355,
                  717,
                  21,
                  17
                ],
                [
                  381,
                  717,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 24,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-25",
              label: "26",
              locations: [
                [
                  301,
                  741,
                  21,
                  17
                ],
                [
                  328,
                  741,
                  21,
                  17
                ],
                [
                  355,
                  741,
                  21,
                  17
                ],
                [
                  381,
                  741,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 25,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-26",
              label: "27",
              locations: [
                [
                  301,
                  765,
                  21,
                  17
                ],
                [
                  328,
                  765,
                  21,
                  17
                ],
                [
                  355,
                  766,
                  21,
                  17
                ],
                [
                  382,
                  764,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 26,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-27",
              label: "28",
              locations: [
                [
                  301,
                  788,
                  21,
                  17
                ],
                [
                  328,
                  788,
                  21,
                  17
                ],
                [
                  355,
                  788,
                  21,
                  17
                ],
                [
                  382,
                  788,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 27,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-28",
              label: "29",
              locations: [
                [
                  301,
                  812,
                  21,
                  17
                ],
                [
                  328,
                  812,
                  21,
                  17
                ],
                [
                  355,
                  812,
                  21,
                  17
                ],
                [
                  382,
                  812,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 28,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-29",
              label: "30",
              locations: [
                [
                  301,
                  836,
                  21,
                  17
                ],
                [
                  328,
                  836,
                  21,
                  17
                ],
                [
                  355,
                  836,
                  21,
                  17
                ],
                [
                  382,
                  836,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 29,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-30",
              label: "31",
              locations: [
                [
                  473,
                  502,
                  21,
                  17
                ],
                [
                  500,
                  501,
                  21,
                  17
                ],
                [
                  528,
                  501,
                  21,
                  17
                ],
                [
                  555,
                  501,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 30,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-31",
              label: "32",
              locations: [
                [
                  473,
                  526,
                  21,
                  17
                ],
                [
                  500,
                  525,
                  21,
                  17
                ],
                [
                  527,
                  526,
                  21,
                  17
                ],
                [
                  555,
                  525,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 31,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-32",
              label: "33",
              locations: [
                [
                  474,
                  550,
                  21,
                  17
                ],
                [
                  500,
                  549,
                  21,
                  17
                ],
                [
                  528,
                  549,
                  21,
                  17
                ],
                [
                  555,
                  549,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 32,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-33",
              label: "34",
              locations: [
                [
                  474,
                  573,
                  21,
                  17
                ],
                [
                  501,
                  573,
                  21,
                  17
                ],
                [
                  528,
                  574,
                  21,
                  17
                ],
                [
                  554,
                  573,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 33,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-34",
              label: "35",
              locations: [
                [
                  474,
                  597,
                  21,
                  17
                ],
                [
                  501,
                  597,
                  21,
                  17
                ],
                [
                  527,
                  597,
                  21,
                  17
                ],
                [
                  554,
                  597,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 34,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-35",
              label: "36",
              locations: [
                [
                  474,
                  621,
                  21,
                  17
                ],
                [
                  501,
                  621,
                  21,
                  17
                ],
                [
                  528,
                  622,
                  21,
                  17
                ],
                [
                  554,
                  621,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 35,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-36",
              label: "37",
              locations: [
                [
                  474,
                  645,
                  21,
                  17
                ],
                [
                  501,
                  645,
                  21,
                  17
                ],
                [
                  528,
                  645,
                  21,
                  17
                ],
                [
                  554,
                  645,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 36,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-37",
              label: "38",
              locations: [
                [
                  474,
                  669,
                  21,
                  17
                ],
                [
                  500,
                  669,
                  21,
                  17
                ],
                [
                  528,
                  669,
                  21,
                  17
                ],
                [
                  554,
                  669,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 37,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-38",
              label: "39",
              locations: [
                [
                  474,
                  693,
                  21,
                  17
                ],
                [
                  501,
                  693,
                  21,
                  17
                ],
                [
                  528,
                  693,
                  21,
                  17
                ],
                [
                  554,
                  693,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 38,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-39",
              label: "40",
              locations: [
                [
                  474,
                  717,
                  21,
                  17
                ],
                [
                  501,
                  717,
                  21,
                  17
                ],
                [
                  527,
                  717,
                  21,
                  17
                ],
                [
                  554,
                  717,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 39,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-40",
              label: "41",
              locations: [
                [
                  474,
                  741,
                  21,
                  17
                ],
                [
                  501,
                  741,
                  21,
                  17
                ],
                [
                  528,
                  741,
                  21,
                  17
                ],
                [
                  554,
                  741,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 40,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-41",
              label: "42",
              locations: [
                [
                  474,
                  765,
                  21,
                  17
                ],
                [
                  501,
                  765,
                  21,
                  17
                ],
                [
                  527,
                  764,
                  21,
                  17
                ],
                [
                  554,
                  765,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 41,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-42",
              label: "43",
              locations: [
                [
                  474,
                  788,
                  21,
                  17
                ],
                [
                  501,
                  789,
                  21,
                  17
                ],
                [
                  527,
                  788,
                  21,
                  17
                ],
                [
                  554,
                  788,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 42,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-43",
              label: "44",
              locations: [
                [
                  474,
                  812,
                  21,
                  17
                ],
                [
                  501,
                  812,
                  21,
                  17
                ],
                [
                  527,
                  812,
                  21,
                  17
                ],
                [
                  554,
                  812,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 43,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-44",
              label: "45",
              locations: [
                [
                  474,
                  836,
                  21,
                  17
                ],
                [
                  501,
                  836,
                  21,
                  17
                ],
                [
                  528,
                  836,
                  21,
                  17
                ],
                [
                  554,
                  836,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 44,
              originalValue: 1,
              required: false,
              value: 1
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-45",
              label: "46",
              locations: [
                [
                  649,
                  500,
                  21,
                  17
                ],
                [
                  676,
                  500,
                  21,
                  17
                ],
                [
                  703,
                  501,
                  21,
                  17
                ],
                [
                  730,
                  500,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 45,
              originalValue: 3,
              required: false,
              value: 3
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-46",
              label: "47",
              locations: [
                [
                  649,
                  524,
                  21,
                  17
                ],
                [
                  676,
                  525,
                  21,
                  17
                ],
                [
                  703,
                  524,
                  21,
                  17
                ],
                [
                  730,
                  524,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 46,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-47",
              label: "48",
              locations: [
                [
                  649,
                  548,
                  21,
                  17
                ],
                [
                  676,
                  548,
                  21,
                  17
                ],
                [
                  703,
                  548,
                  21,
                  17
                ],
                [
                  730,
                  548,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 47,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-48",
              label: "49",
              locations: [
                [
                  648,
                  572,
                  21,
                  17
                ],
                [
                  676,
                  574,
                  21,
                  17
                ],
                [
                  703,
                  572,
                  21,
                  17
                ],
                [
                  730,
                  572,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 48,
              originalValue: 2,
              required: false,
              value: 2
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-49",
              label: "50",
              locations: [
                [
                  648,
                  597,
                  21,
                  17
                ],
                [
                  676,
                  597,
                  21,
                  17
                ],
                [
                  702,
                  597,
                  21,
                  17
                ],
                [
                  730,
                  596,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 49,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-50",
              label: "51",
              locations: [
                [
                  648,
                  621,
                  21,
                  17
                ],
                [
                  675,
                  621,
                  21,
                  17
                ],
                [
                  702,
                  621,
                  21,
                  17
                ],
                [
                  730,
                  620,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 50,
              originalValue: 4,
              required: false,
              value: 4
            },
            {
              controlType: "multipleChoice",
              key: "multipleChoice-1-51",
              label: "52",
              locations: [
                [
                  648,
                  645,
                  21,
                  17
                ],
                [
                  675,
                  645,
                  21,
                  17
                ],
                [
                  702,
                  644,
                  21,
                  17
                ],
                [
                  730,
                  644,
                  21,
                  17
                ]
              ],
              options: [
                {
                  key: "A",
                  value: 1
                },
                {
                  key: "B",
                  value: 2
                },
                {
                  key: "C",
                  value: 3
                },
                {
                  key: "D",
                  value: 4
                }
              ],
              order: 51,
              originalValue: 2,
              required: false,
              value: 2
            }
          ]
        }
      ],
      image: "",
      processedImage: "data:image/jpeg;base64,/processed-image",
      status: "complete",
      json: {
        uuid: "some-uuid",
        answer_regions: [
          {
            section: 0,
            type: "",
            answers: [
              14,
              1
            ],
            locations: [
              [
                [
                  59,
                  215,
                  22,
                  21
                ],
                [
                  88,
                  214,
                  22,
                  21
                ],
                [
                  117,
                  214,
                  22,
                  21
                ],
                [
                  146,
                  214,
                  22,
                  21
                ],
                [
                  175,
                  214,
                  22,
                  21
                ],
                [
                  204,
                  214,
                  22,
                  21
                ],
                [
                  233,
                  214,
                  22,
                  21
                ],
                [
                  262,
                  214,
                  22,
                  21
                ],
                [
                  292,
                  214,
                  22,
                  21
                ],
                [
                  321,
                  214,
                  22,
                  21
                ],
                [
                  350,
                  214,
                  22,
                  21
                ],
                [
                  379,
                  214,
                  22,
                  21
                ],
                [
                  408,
                  214,
                  22,
                  21
                ],
                [
                  438,
                  214,
                  22,
                  21
                ],
                [
                  467,
                  214,
                  22,
                  21
                ]
              ],
              [
                [
                  61,
                  244,
                  22,
                  21
                ],
                [
                  89,
                  244,
                  22,
                  21
                ],
                [
                  118,
                  244,
                  22,
                  21
                ],
                [
                  145,
                  244,
                  22,
                  21
                ]
              ]
            ]
          },
          {
            section: 1,
            type: "",
            answers: [
              1,
              4,
              1,
              3,
              1,
              3,
              2,
              1,
              4,
              3,
              2,
              2,
              1,
              3,
              3,
              3,
              4,
              4,
              3,
              3,
              1,
              4,
              3,
              1,
              1,
              1,
              3,
              1,
              3,
              1,
              4,
              1,
              3,
              3,
              1,
              3,
              4,
              3,
              2,
              2,
              3,
              3,
              2,
              1,
              1,
              3,
              2,
              4,
              2,
              4,
              4,
              2
            ],
            locations: [
              [
                [
                  115,
                  500,
                  21,
                  17
                ],
                [
                  143,
                  500,
                  21,
                  17
                ],
                [
                  170,
                  500,
                  21,
                  17
                ],
                [
                  197,
                  500,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  524,
                  21,
                  17
                ],
                [
                  143,
                  524,
                  21,
                  17
                ],
                [
                  170,
                  525,
                  21,
                  17
                ],
                [
                  197,
                  525,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  548,
                  21,
                  17
                ],
                [
                  143,
                  548,
                  21,
                  17
                ],
                [
                  170,
                  549,
                  21,
                  17
                ],
                [
                  198,
                  549,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  572,
                  21,
                  17
                ],
                [
                  143,
                  572,
                  21,
                  17
                ],
                [
                  170,
                  572,
                  21,
                  17
                ],
                [
                  198,
                  572,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  596,
                  21,
                  17
                ],
                [
                  143,
                  596,
                  21,
                  17
                ],
                [
                  171,
                  596,
                  21,
                  17
                ],
                [
                  198,
                  596,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  620,
                  21,
                  17
                ],
                [
                  144,
                  620,
                  21,
                  17
                ],
                [
                  171,
                  620,
                  21,
                  17
                ],
                [
                  198,
                  621,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  644,
                  21,
                  17
                ],
                [
                  144,
                  644,
                  21,
                  17
                ],
                [
                  171,
                  645,
                  21,
                  17
                ],
                [
                  198,
                  645,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  668,
                  21,
                  17
                ],
                [
                  144,
                  669,
                  21,
                  17
                ],
                [
                  171,
                  669,
                  21,
                  17
                ],
                [
                  198,
                  669,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  693,
                  21,
                  17
                ],
                [
                  144,
                  693,
                  21,
                  17
                ],
                [
                  171,
                  693,
                  21,
                  17
                ],
                [
                  198,
                  693,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  716,
                  21,
                  17
                ],
                [
                  144,
                  717,
                  21,
                  17
                ],
                [
                  171,
                  717,
                  21,
                  17
                ],
                [
                  198,
                  717,
                  21,
                  17
                ]
              ],
              [
                [
                  116,
                  741,
                  21,
                  17
                ],
                [
                  144,
                  740,
                  21,
                  17
                ],
                [
                  171,
                  740,
                  21,
                  17
                ],
                [
                  199,
                  740,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  764,
                  21,
                  17
                ],
                [
                  144,
                  765,
                  21,
                  17
                ],
                [
                  171,
                  765,
                  21,
                  17
                ],
                [
                  199,
                  765,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  788,
                  21,
                  17
                ],
                [
                  144,
                  788,
                  21,
                  17
                ],
                [
                  171,
                  788,
                  21,
                  17
                ],
                [
                  199,
                  788,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  813,
                  21,
                  17
                ],
                [
                  144,
                  812,
                  21,
                  17
                ],
                [
                  172,
                  812,
                  21,
                  17
                ],
                [
                  199,
                  812,
                  21,
                  17
                ]
              ],
              [
                [
                  117,
                  837,
                  21,
                  17
                ],
                [
                  144,
                  837,
                  21,
                  17
                ],
                [
                  172,
                  836,
                  21,
                  17
                ],
                [
                  199,
                  836,
                  21,
                  17
                ]
              ],
              [
                [
                  299,
                  501,
                  21,
                  17
                ],
                [
                  327,
                  501,
                  21,
                  17
                ],
                [
                  354,
                  501,
                  21,
                  17
                ],
                [
                  381,
                  501,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  526,
                  21,
                  17
                ],
                [
                  326,
                  526,
                  21,
                  17
                ],
                [
                  354,
                  525,
                  21,
                  17
                ],
                [
                  381,
                  526,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  549,
                  21,
                  17
                ],
                [
                  327,
                  550,
                  21,
                  17
                ],
                [
                  354,
                  550,
                  21,
                  17
                ],
                [
                  381,
                  550,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  573,
                  21,
                  17
                ],
                [
                  327,
                  573,
                  21,
                  17
                ],
                [
                  354,
                  573,
                  21,
                  17
                ],
                [
                  381,
                  573,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  597,
                  21,
                  17
                ],
                [
                  327,
                  597,
                  21,
                  17
                ],
                [
                  354,
                  597,
                  21,
                  17
                ],
                [
                  381,
                  597,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  621,
                  21,
                  17
                ],
                [
                  327,
                  621,
                  21,
                  17
                ],
                [
                  354,
                  621,
                  21,
                  17
                ],
                [
                  381,
                  622,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  645,
                  21,
                  17
                ],
                [
                  327,
                  645,
                  21,
                  17
                ],
                [
                  354,
                  645,
                  21,
                  17
                ],
                [
                  381,
                  645,
                  21,
                  17
                ]
              ],
              [
                [
                  300,
                  669,
                  21,
                  17
                ],
                [
                  327,
                  669,
                  21,
                  17
                ],
                [
                  354,
                  670,
                  21,
                  17
                ],
                [
                  381,
                  669,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  693,
                  21,
                  17
                ],
                [
                  327,
                  693,
                  21,
                  17
                ],
                [
                  354,
                  693,
                  21,
                  17
                ],
                [
                  382,
                  693,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  717,
                  21,
                  17
                ],
                [
                  328,
                  717,
                  21,
                  17
                ],
                [
                  355,
                  717,
                  21,
                  17
                ],
                [
                  381,
                  717,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  741,
                  21,
                  17
                ],
                [
                  328,
                  741,
                  21,
                  17
                ],
                [
                  355,
                  741,
                  21,
                  17
                ],
                [
                  381,
                  741,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  765,
                  21,
                  17
                ],
                [
                  328,
                  765,
                  21,
                  17
                ],
                [
                  355,
                  766,
                  21,
                  17
                ],
                [
                  382,
                  764,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  788,
                  21,
                  17
                ],
                [
                  328,
                  788,
                  21,
                  17
                ],
                [
                  355,
                  788,
                  21,
                  17
                ],
                [
                  382,
                  788,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  812,
                  21,
                  17
                ],
                [
                  328,
                  812,
                  21,
                  17
                ],
                [
                  355,
                  812,
                  21,
                  17
                ],
                [
                  382,
                  812,
                  21,
                  17
                ]
              ],
              [
                [
                  301,
                  836,
                  21,
                  17
                ],
                [
                  328,
                  836,
                  21,
                  17
                ],
                [
                  355,
                  836,
                  21,
                  17
                ],
                [
                  382,
                  836,
                  21,
                  17
                ]
              ],
              [
                [
                  473,
                  502,
                  21,
                  17
                ],
                [
                  500,
                  501,
                  21,
                  17
                ],
                [
                  528,
                  501,
                  21,
                  17
                ],
                [
                  555,
                  501,
                  21,
                  17
                ]
              ],
              [
                [
                  473,
                  526,
                  21,
                  17
                ],
                [
                  500,
                  525,
                  21,
                  17
                ],
                [
                  527,
                  526,
                  21,
                  17
                ],
                [
                  555,
                  525,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  550,
                  21,
                  17
                ],
                [
                  500,
                  549,
                  21,
                  17
                ],
                [
                  528,
                  549,
                  21,
                  17
                ],
                [
                  555,
                  549,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  573,
                  21,
                  17
                ],
                [
                  501,
                  573,
                  21,
                  17
                ],
                [
                  528,
                  574,
                  21,
                  17
                ],
                [
                  554,
                  573,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  597,
                  21,
                  17
                ],
                [
                  501,
                  597,
                  21,
                  17
                ],
                [
                  527,
                  597,
                  21,
                  17
                ],
                [
                  554,
                  597,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  621,
                  21,
                  17
                ],
                [
                  501,
                  621,
                  21,
                  17
                ],
                [
                  528,
                  622,
                  21,
                  17
                ],
                [
                  554,
                  621,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  645,
                  21,
                  17
                ],
                [
                  501,
                  645,
                  21,
                  17
                ],
                [
                  528,
                  645,
                  21,
                  17
                ],
                [
                  554,
                  645,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  669,
                  21,
                  17
                ],
                [
                  500,
                  669,
                  21,
                  17
                ],
                [
                  528,
                  669,
                  21,
                  17
                ],
                [
                  554,
                  669,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  693,
                  21,
                  17
                ],
                [
                  501,
                  693,
                  21,
                  17
                ],
                [
                  528,
                  693,
                  21,
                  17
                ],
                [
                  554,
                  693,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  717,
                  21,
                  17
                ],
                [
                  501,
                  717,
                  21,
                  17
                ],
                [
                  527,
                  717,
                  21,
                  17
                ],
                [
                  554,
                  717,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  741,
                  21,
                  17
                ],
                [
                  501,
                  741,
                  21,
                  17
                ],
                [
                  528,
                  741,
                  21,
                  17
                ],
                [
                  554,
                  741,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  765,
                  21,
                  17
                ],
                [
                  501,
                  765,
                  21,
                  17
                ],
                [
                  527,
                  764,
                  21,
                  17
                ],
                [
                  554,
                  765,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  788,
                  21,
                  17
                ],
                [
                  501,
                  789,
                  21,
                  17
                ],
                [
                  527,
                  788,
                  21,
                  17
                ],
                [
                  554,
                  788,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  812,
                  21,
                  17
                ],
                [
                  501,
                  812,
                  21,
                  17
                ],
                [
                  527,
                  812,
                  21,
                  17
                ],
                [
                  554,
                  812,
                  21,
                  17
                ]
              ],
              [
                [
                  474,
                  836,
                  21,
                  17
                ],
                [
                  501,
                  836,
                  21,
                  17
                ],
                [
                  528,
                  836,
                  21,
                  17
                ],
                [
                  554,
                  836,
                  21,
                  17
                ]
              ],
              [
                [
                  649,
                  500,
                  21,
                  17
                ],
                [
                  676,
                  500,
                  21,
                  17
                ],
                [
                  703,
                  501,
                  21,
                  17
                ],
                [
                  730,
                  500,
                  21,
                  17
                ]
              ],
              [
                [
                  649,
                  524,
                  21,
                  17
                ],
                [
                  676,
                  525,
                  21,
                  17
                ],
                [
                  703,
                  524,
                  21,
                  17
                ],
                [
                  730,
                  524,
                  21,
                  17
                ]
              ],
              [
                [
                  649,
                  548,
                  21,
                  17
                ],
                [
                  676,
                  548,
                  21,
                  17
                ],
                [
                  703,
                  548,
                  21,
                  17
                ],
                [
                  730,
                  548,
                  21,
                  17
                ]
              ],
              [
                [
                  648,
                  572,
                  21,
                  17
                ],
                [
                  676,
                  574,
                  21,
                  17
                ],
                [
                  703,
                  572,
                  21,
                  17
                ],
                [
                  730,
                  572,
                  21,
                  17
                ]
              ],
              [
                [
                  648,
                  597,
                  21,
                  17
                ],
                [
                  676,
                  597,
                  21,
                  17
                ],
                [
                  702,
                  597,
                  21,
                  17
                ],
                [
                  730,
                  596,
                  21,
                  17
                ]
              ],
              [
                [
                  648,
                  621,
                  21,
                  17
                ],
                [
                  675,
                  621,
                  21,
                  17
                ],
                [
                  702,
                  621,
                  21,
                  17
                ],
                [
                  730,
                  620,
                  21,
                  17
                ]
              ],
              [
                [
                  648,
                  645,
                  21,
                  17
                ],
                [
                  675,
                  645,
                  21,
                  17
                ],
                [
                  702,
                  644,
                  21,
                  17
                ],
                [
                  730,
                  644,
                  21,
                  17
                ]
              ]
            ]
          }
        ],
        error: false
      }
    }
  ],
  transactionId: "testTransactionId",
  formCode: "1R",
  type: "SAT"
};

// src/lib/exam-transcript-summary.ts
var user2 = {
  id: 12345,
  first_name: "Ash",
  last_name: "Ketchum",
  handle: 112233
};
var mockCreateUrl = "https://localhost:3001/essay/new";
var mockReportUrl = "http://localhost:8888/report.pdf";
var transcriptSummary = [
  {
    exam_type: "ACT",
    improvement: 17.5,
    max_score: 36,
    min_score: 1,
    best_score: 22,
    transcripts: [
      {
        id: 30489,
        created_at: "2016-03-21T14:07:58.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-03-21T14:07:58.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2016-03-21T14:07:58.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 1J",
          series: null,
          form_code: "1J",
          score: 6,
          complete: false,
          subjects: [
            { name: "English", score: 1, raw_score: 0 },
            { name: "Mathematics", score: 19, raw_score: 28 },
            { name: "Reading", score: 1, raw_score: 0 },
            { name: "Science", score: 1, raw_score: 0 }
          ]
        },
        essay: {
          id: 7293,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 26835,
        created_at: "2016-06-30T06:42:10.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-06-30T06:42:10.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-06-30T06:42:10.000-07:00",
          exam_type: "ACT",
          attempt: 2,
          name: "ACT 7J",
          series: null,
          form_code: "7J",
          score: 19,
          complete: true,
          subjects: [
            { name: "English", score: 24, raw_score: 55 },
            { name: "Mathematics", score: 20, raw_score: 31 },
            { name: "Reading", score: 18, raw_score: 19 },
            { name: "Science", score: 14, raw_score: 12 }
          ]
        },
        essay: {
          id: 10930,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 25904,
        created_at: "2016-08-08T08:28:21.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-08-08T08:28:21.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-08-08T08:28:21.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 2J",
          series: null,
          form_code: "2J",
          score: 21,
          complete: true,
          subjects: [
            { name: "English", score: 21, raw_score: 48 },
            { name: "Mathematics", score: 19, raw_score: 29 },
            { name: "Reading", score: 23, raw_score: 26 },
            { name: "Science", score: 21, raw_score: 24 }
          ]
        },
        essay: {
          id: 11840,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 25150,
        created_at: "2016-08-25T10:55:44.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-08-25T10:55:44.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-08-25T10:55:44.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 3J",
          series: null,
          form_code: "3J",
          score: 20,
          complete: true,
          subjects: [
            { name: "English", score: 24, raw_score: 53 },
            { name: "Mathematics", score: 16, raw_score: 20 },
            { name: "Reading", score: 22, raw_score: 25 },
            { name: "Science", score: 19, raw_score: 20 }
          ]
        },
        essay: {
          id: 12579,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 16345,
        created_at: "2016-12-21T07:04:25.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-12-21T07:04:25.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-12-21T07:04:25.000-08:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 4J",
          series: null,
          form_code: "4J",
          score: 20,
          complete: true,
          subjects: [
            { name: "English", score: 26, raw_score: 58 },
            { name: "Mathematics", score: 19, raw_score: 30 },
            { name: "Reading", score: 14, raw_score: 14 },
            { name: "Science", score: 22, raw_score: 26 }
          ]
        },
        essay: {
          id: 20467,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 16116,
        created_at: "2019-06-05T15:23:51.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-06-05T15:23:51.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-06-05T15:23:51.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 5J",
          series: null,
          form_code: "5J",
          score: 22,
          complete: true,
          subjects: [
            { name: "English", score: 27, raw_score: 62 },
            { name: "Mathematics", score: 20, raw_score: 33 },
            { name: "Reading", score: 17, raw_score: 18 },
            { name: "Science", score: 22, raw_score: 25 }
          ]
        },
        essay: {
          id: 20694,
          score: 12,
          components: [
            { name: "Analysis", score: 12 },
            { name: "Development", score: 12 },
            { name: "Organization", score: 12 },
            { name: "Language Use", score: 12 }
          ],
          create_url: null
        }
      }
    ]
  },
  {
    exam_type: "PSAT",
    improvement: 0,
    max_score: 1520,
    min_score: 320,
    best_score: 1050,
    transcripts: [
      {
        id: 26780,
        created_at: "2016-07-03T19:25:16.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-07-03T19:25:16.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-07-03T19:25:16.000-07:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 13P",
          series: null,
          form_code: "13P",
          score: 1050,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 510, raw_score: 20 },
            { name: "Reading and Writing", score: 540, raw_score: 56 }
          ],
          child_subjects: [
            { name: "Reading", score: 25, raw_score: 24 },
            { name: "Writing and Language", score: 29, raw_score: 32 }
          ]
        }
      },
      {
        id: 170696,
        created_at: "2020-02-27T08:11:53.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:11:53.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:11:53.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 8P",
          series: null,
          form_code: "8P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      },
      {
        id: 170697,
        created_at: "2020-02-27T08:12:37.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:12:37.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:12:37.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 7P",
          series: null,
          form_code: "7P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      },
      {
        id: 170700,
        created_at: "2020-02-27T08:14:52.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:14:52.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:14:52.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 9P",
          series: null,
          form_code: "9P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      }
    ]
  },
  {
    exam_type: "SAT",
    improvement: 630,
    max_score: 1600,
    min_score: 400,
    best_score: 1600,
    transcripts: [
      {
        id: 8569,
        created_at: "2017-04-15T11:24:06.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-04-15T11:24:06.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-04-15T11:24:06.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 1R",
          series: null,
          form_code: "1R",
          score: 970,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 560, raw_score: 33 },
            { name: "Reading and Writing", score: 410, raw_score: 36 }
          ],
          child_subjects: [
            { name: "Reading", score: 31, raw_score: 36 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 28801,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 5742,
        created_at: "2017-05-27T11:25:11.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-05-27T11:25:11.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-05-27T11:25:11.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 2R",
          series: null,
          form_code: "2R",
          score: 1090,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 540, raw_score: 29 },
            { name: "Reading and Writing", score: 550, raw_score: 59 }
          ],
          child_subjects: [
            { name: "Reading", score: 28, raw_score: 31 },
            { name: "Writing and Language", score: 27, raw_score: 28 }
          ]
        },
        essay: {
          id: 30999,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 3794,
        created_at: "2017-07-11T17:50:17.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-07-11T17:50:17.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-07-11T17:50:17.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 3R",
          series: null,
          form_code: "3R",
          score: 1190,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 590, raw_score: 33 },
            { name: "Reading and Writing", score: 600, raw_score: 64 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 34 },
            { name: "Writing and Language", score: 30, raw_score: 30 }
          ]
        },
        essay: {
          id: 32919,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 3041,
        created_at: "2017-07-25T14:11:50.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-07-25T14:11:50.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-07-25T14:11:50.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 4R",
          series: null,
          form_code: "4R",
          score: 1280,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 670, raw_score: 40 },
            { name: "Reading and Writing", score: 610, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 29, raw_score: 35 },
            { name: "Writing and Language", score: 32, raw_score: 33 }
          ]
        },
        essay: {
          id: 33658,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 2242,
        created_at: "2017-08-04T17:31:28.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-04T17:31:28.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-08-04T17:31:28.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 5R",
          series: null,
          form_code: "5R",
          score: 1180,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 580, raw_score: 35 },
            { name: "Reading and Writing", score: 600, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 37 },
            { name: "Writing and Language", score: 30, raw_score: 31 }
          ]
        },
        essay: {
          id: 34444,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 1312,
        created_at: "2017-08-16T22:00:03.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-16T22:00:03.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-08-16T22:00:03.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 6R",
          series: null,
          form_code: "6R",
          score: 1200,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 600, raw_score: 37 },
            { name: "Reading and Writing", score: 600, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 38 },
            { name: "Writing and Language", score: 30, raw_score: 30 }
          ]
        },
        essay: {
          id: 35371,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 634,
        created_at: "2017-08-24T09:12:16.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-24T09:12:16.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-08-24T09:12:16.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 400,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 200, raw_score: 0 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 35985,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 633,
        created_at: "2017-08-24T11:06:54.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-24T11:06:54.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-08-24T11:06:54.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 830,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 630, raw_score: 36 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 36e3,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 41101,
        created_at: "2017-10-22T07:01:07.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-10-22T07:01:07.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-10-22T07:01:07.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 1R",
          series: null,
          form_code: "1R",
          score: 1280,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 660, raw_score: 45 },
            { name: "Reading and Writing", score: 620, raw_score: 70 }
          ],
          child_subjects: [
            { name: "Reading", score: 35, raw_score: 43 },
            { name: "Writing and Language", score: 27, raw_score: 27 }
          ]
        },
        essay: {
          id: 69698,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 47473,
        created_at: "2017-11-18T09:27:42.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-11-18T09:27:42.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-11-18T09:27:42.000-08:00",
          exam_type: "SAT",
          attempt: 3,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 1160,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 610, raw_score: 34 },
            { name: "Reading and Writing", score: 550, raw_score: 56 }
          ],
          child_subjects: [
            { name: "Reading", score: 27, raw_score: 29 },
            { name: "Writing and Language", score: 28, raw_score: 27 }
          ]
        },
        essay: {
          id: 72747,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 48295,
        created_at: "2017-11-25T08:22:35.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-11-25T08:22:35.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-11-25T08:22:35.000-08:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 5R",
          series: null,
          form_code: "5R",
          score: 560,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 360, raw_score: 13 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 73504,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 128095,
        created_at: "2019-08-03T14:30:50.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-08-03T14:30:50.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-08-03T14:30:50.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 1S",
          series: null,
          form_code: "1S",
          score: 710,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 310, raw_score: 8 },
            { name: "Reading and Writing", score: 400, raw_score: 31 }
          ],
          child_subjects: [
            { name: "Reading", score: 20, raw_score: 17 },
            { name: "Writing and Language", score: 20, raw_score: 14 }
          ]
        },
        essay: {
          id: 146726,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 129042,
        created_at: "2019-08-12T06:00:21.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-08-12T06:00:21.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-08-12T06:00:21.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 2R",
          series: null,
          form_code: "2R",
          score: 1600,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 800, raw_score: 58 },
            { name: "Reading and Writing", score: 800, raw_score: 96 }
          ],
          child_subjects: [
            { name: "Reading", score: 40, raw_score: 52 },
            { name: "Writing and Language", score: 40, raw_score: 44 }
          ]
        },
        essay: {
          id: 147617,
          score: 6,
          components: [
            { name: "Reading", score: 2 },
            { name: "Writing", score: 2 },
            { name: "Analysis", score: 2 }
          ],
          create_url: null
        }
      }
    ]
  }
];
var sortedTranscriptSummary = [
  {
    exam_type: "PSAT",
    improvement: 0,
    max_score: 1520,
    min_score: 320,
    best_score: 1050,
    transcripts: [
      {
        id: 170700,
        created_at: "2020-02-27T08:14:52.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:14:52.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:14:52.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 9P",
          series: null,
          form_code: "9P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      },
      {
        id: 170697,
        created_at: "2020-02-27T08:12:37.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:12:37.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:12:37.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 7P",
          series: null,
          form_code: "7P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      },
      {
        id: 170696,
        created_at: "2020-02-27T08:11:53.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2020-02-27T08:11:53.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2020-02-27T08:11:53.000-08:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 8P",
          series: null,
          form_code: "8P",
          score: 320,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 160, raw_score: 0 },
            { name: "Reading and Writing", score: 160, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 8, raw_score: 0 },
            { name: "Writing and Language", score: 8, raw_score: 0 }
          ]
        }
      },
      {
        id: 26780,
        created_at: "2016-07-03T19:25:16.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-07-03T19:25:16.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-07-03T19:25:16.000-07:00",
          exam_type: "PSAT",
          attempt: 1,
          name: "PSAT 13P",
          series: null,
          form_code: "13P",
          score: 1050,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 510, raw_score: 20 },
            { name: "Reading and Writing", score: 540, raw_score: 56 }
          ],
          child_subjects: [
            { name: "Reading", score: 25, raw_score: 24 },
            { name: "Writing and Language", score: 29, raw_score: 32 }
          ]
        }
      }
    ]
  },
  {
    exam_type: "SAT",
    improvement: 630,
    max_score: 1600,
    min_score: 400,
    best_score: 1600,
    transcripts: [
      {
        id: 129042,
        created_at: "2019-08-12T06:00:21.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-08-12T06:00:21.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-08-12T06:00:21.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 2R",
          series: null,
          form_code: "2R",
          score: 1600,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 800, raw_score: 58 },
            { name: "Reading and Writing", score: 800, raw_score: 96 }
          ],
          child_subjects: [
            { name: "Reading", score: 40, raw_score: 52 },
            { name: "Writing and Language", score: 40, raw_score: 44 }
          ]
        },
        essay: {
          id: 147617,
          score: 6,
          components: [
            { name: "Reading", score: 2 },
            { name: "Writing", score: 2 },
            { name: "Analysis", score: 2 }
          ],
          create_url: null
        }
      },
      {
        id: 128095,
        created_at: "2019-08-03T14:30:50.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-08-03T14:30:50.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-08-03T14:30:50.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 1S",
          series: null,
          form_code: "1S",
          score: 710,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 310, raw_score: 8 },
            { name: "Reading and Writing", score: 400, raw_score: 31 }
          ],
          child_subjects: [
            { name: "Reading", score: 20, raw_score: 17 },
            { name: "Writing and Language", score: 20, raw_score: 14 }
          ]
        },
        essay: {
          id: 146726,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 48295,
        created_at: "2017-11-25T08:22:35.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-11-25T08:22:35.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-11-25T08:22:35.000-08:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 5R",
          series: null,
          form_code: "5R",
          score: 560,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 360, raw_score: 13 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 73504,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 47473,
        created_at: "2017-11-18T09:27:42.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-11-18T09:27:42.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-11-18T09:27:42.000-08:00",
          exam_type: "SAT",
          attempt: 3,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 1160,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 610, raw_score: 34 },
            { name: "Reading and Writing", score: 550, raw_score: 56 }
          ],
          child_subjects: [
            { name: "Reading", score: 27, raw_score: 29 },
            { name: "Writing and Language", score: 28, raw_score: 27 }
          ]
        },
        essay: {
          id: 72747,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 41101,
        created_at: "2017-10-22T07:01:07.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-10-22T07:01:07.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-10-22T07:01:07.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 1R",
          series: null,
          form_code: "1R",
          score: 1280,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 660, raw_score: 45 },
            { name: "Reading and Writing", score: 620, raw_score: 70 }
          ],
          child_subjects: [
            { name: "Reading", score: 35, raw_score: 43 },
            { name: "Writing and Language", score: 27, raw_score: 27 }
          ]
        },
        essay: {
          id: 69698,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 633,
        created_at: "2017-08-24T11:06:54.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-24T11:06:54.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-08-24T11:06:54.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 830,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 630, raw_score: 36 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 36e3,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 634,
        created_at: "2017-08-24T09:12:16.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-24T09:12:16.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2017-08-24T09:12:16.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 9R",
          series: null,
          form_code: "9R",
          score: 400,
          complete: false,
          subjects: [
            { name: "Mathematics", score: 200, raw_score: 0 },
            { name: "Reading and Writing", score: 200, raw_score: 0 }
          ],
          child_subjects: [
            { name: "Reading", score: 10, raw_score: 0 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 35985,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 1312,
        created_at: "2017-08-16T22:00:03.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-16T22:00:03.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-08-16T22:00:03.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 6R",
          series: null,
          form_code: "6R",
          score: 1200,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 600, raw_score: 37 },
            { name: "Reading and Writing", score: 600, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 38 },
            { name: "Writing and Language", score: 30, raw_score: 30 }
          ]
        },
        essay: {
          id: 35371,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 2242,
        created_at: "2017-08-04T17:31:28.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-08-04T17:31:28.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-08-04T17:31:28.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 5R",
          series: null,
          form_code: "5R",
          score: 1180,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 580, raw_score: 35 },
            { name: "Reading and Writing", score: 600, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 37 },
            { name: "Writing and Language", score: 30, raw_score: 31 }
          ]
        },
        essay: {
          id: 34444,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 3041,
        created_at: "2017-07-25T14:11:50.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-07-25T14:11:50.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-07-25T14:11:50.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 4R",
          series: null,
          form_code: "4R",
          score: 1280,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 670, raw_score: 40 },
            { name: "Reading and Writing", score: 610, raw_score: 68 }
          ],
          child_subjects: [
            { name: "Reading", score: 29, raw_score: 35 },
            { name: "Writing and Language", score: 32, raw_score: 33 }
          ]
        },
        essay: {
          id: 33658,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 3794,
        created_at: "2017-07-11T17:50:17.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-07-11T17:50:17.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-07-11T17:50:17.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 3R",
          series: null,
          form_code: "3R",
          score: 1190,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 590, raw_score: 33 },
            { name: "Reading and Writing", score: 600, raw_score: 64 }
          ],
          child_subjects: [
            { name: "Reading", score: 30, raw_score: 34 },
            { name: "Writing and Language", score: 30, raw_score: 30 }
          ]
        },
        essay: {
          id: 32919,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 5742,
        created_at: "2017-05-27T11:25:11.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-05-27T11:25:11.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-05-27T11:25:11.000-07:00",
          exam_type: "SAT",
          attempt: 1,
          name: "SAT 2R",
          series: null,
          form_code: "2R",
          score: 1090,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 540, raw_score: 29 },
            { name: "Reading and Writing", score: 550, raw_score: 59 }
          ],
          child_subjects: [
            { name: "Reading", score: 28, raw_score: 31 },
            { name: "Writing and Language", score: 27, raw_score: 28 }
          ]
        },
        essay: {
          id: 30999,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 8569,
        created_at: "2017-04-15T11:24:06.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2017-04-15T11:24:06.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2017-04-15T11:24:06.000-07:00",
          exam_type: "SAT",
          attempt: 2,
          name: "SAT 1R",
          series: null,
          form_code: "1R",
          score: 970,
          complete: true,
          subjects: [
            { name: "Mathematics", score: 560, raw_score: 33 },
            { name: "Reading and Writing", score: 410, raw_score: 36 }
          ],
          child_subjects: [
            { name: "Reading", score: 31, raw_score: 36 },
            { name: "Writing and Language", score: 10, raw_score: 0 }
          ]
        },
        essay: {
          id: 28801,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      }
    ]
  },
  {
    exam_type: "ACT",
    improvement: 17.5,
    max_score: 36,
    min_score: 1,
    best_score: 22,
    transcripts: [
      {
        id: 16116,
        created_at: "2019-06-05T15:23:51.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2019-06-05T15:23:51.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2019-06-05T15:23:51.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 5J",
          series: null,
          form_code: "5J",
          score: 22,
          complete: true,
          subjects: [
            { name: "English", score: 27, raw_score: 62 },
            { name: "Mathematics", score: 20, raw_score: 33 },
            { name: "Reading", score: 17, raw_score: 18 },
            { name: "Science", score: 22, raw_score: 25 }
          ]
        },
        essay: {
          id: 20694,
          score: 12,
          components: [
            { name: "Analysis", score: 12 },
            { name: "Development", score: 12 },
            { name: "Organization", score: 12 },
            { name: "Language Use", score: 12 }
          ],
          create_url: null
        }
      },
      {
        id: 16345,
        created_at: "2016-12-21T07:04:25.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-12-21T07:04:25.000-08:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-12-21T07:04:25.000-08:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 4J",
          series: null,
          form_code: "4J",
          score: 20,
          complete: true,
          subjects: [
            { name: "English", score: 26, raw_score: 58 },
            { name: "Mathematics", score: 19, raw_score: 30 },
            { name: "Reading", score: 14, raw_score: 14 },
            { name: "Science", score: 22, raw_score: 26 }
          ]
        },
        essay: {
          id: 20467,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 25150,
        created_at: "2016-08-25T10:55:44.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-08-25T10:55:44.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-08-25T10:55:44.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 3J",
          series: null,
          form_code: "3J",
          score: 20,
          complete: true,
          subjects: [
            { name: "English", score: 24, raw_score: 53 },
            { name: "Mathematics", score: 16, raw_score: 20 },
            { name: "Reading", score: 22, raw_score: 25 },
            { name: "Science", score: 19, raw_score: 20 }
          ]
        },
        essay: {
          id: 12579,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 25904,
        created_at: "2016-08-08T08:28:21.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-08-08T08:28:21.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-08-08T08:28:21.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 2J",
          series: null,
          form_code: "2J",
          score: 21,
          complete: true,
          subjects: [
            { name: "English", score: 21, raw_score: 48 },
            { name: "Mathematics", score: 19, raw_score: 29 },
            { name: "Reading", score: 23, raw_score: 26 },
            { name: "Science", score: 21, raw_score: 24 }
          ]
        },
        essay: {
          id: 11840,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 26835,
        created_at: "2016-06-30T06:42:10.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-06-30T06:42:10.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: true,
          scored_on: "2016-06-30T06:42:10.000-07:00",
          exam_type: "ACT",
          attempt: 2,
          name: "ACT 7J",
          series: null,
          form_code: "7J",
          score: 19,
          complete: true,
          subjects: [
            { name: "English", score: 24, raw_score: 55 },
            { name: "Mathematics", score: 20, raw_score: 31 },
            { name: "Reading", score: 18, raw_score: 19 },
            { name: "Science", score: 14, raw_score: 12 }
          ]
        },
        essay: {
          id: 10930,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 30489,
        created_at: "2016-03-21T14:07:58.000-07:00",
        error_log_url: null,
        report_url: mockReportUrl,
        updated_at: "2016-03-21T14:07:58.000-07:00",
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2016-03-21T14:07:58.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 1J",
          series: null,
          form_code: "1J",
          score: 6,
          complete: false,
          subjects: [
            { name: "English", score: 1, raw_score: 0 },
            { name: "Mathematics", score: 19, raw_score: 28 },
            { name: "Reading", score: 1, raw_score: 0 },
            { name: "Science", score: 1, raw_score: 0 }
          ]
        },
        essay: {
          id: 7293,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      }
    ]
  }
];
var transcriptSummaryAP = [
  {
    exam_type: "AP",
    improvement: 0,
    max_score: 5,
    min_score: 1,
    best_score: 1,
    transcripts: [
      {
        id: 271629,
        updated_at: "2022-11-22T12:06:32.000-08:00",
        created_at: "2022-11-22T12:06:31.000-08:00",
        report_url: mockReportUrl,
        error_log_url: null,
        user: user2,
        exam: {
          scored_on: "2022-11-22T12:06:32.000-08:00",
          exam_type: "AP",
          attempt: 3,
          name: "AP Biology 1L",
          series: "Biology",
          form_code: "1L",
          score: 1,
          all_sections_scored: true,
          complete: true,
          subjects: [
            {
              name: "Multiple Choice",
              score: 1,
              raw_score: 8
            }
          ]
        }
      },
      {
        id: 271622,
        updated_at: "2022-11-22T08:20:37.000-08:00",
        created_at: "2022-11-22T08:20:36.000-08:00",
        report_url: mockReportUrl,
        error_log_url: null,
        user: user2,
        exam: {
          scored_on: "2022-11-22T08:20:37.000-08:00",
          exam_type: "AP",
          attempt: 2,
          name: "AP Biology 1L",
          series: "Biology",
          form_code: "1L",
          score: 1,
          all_sections_scored: true,
          complete: true,
          subjects: [
            {
              name: "Multiple Choice",
              score: 1,
              raw_score: 11
            }
          ]
        }
      },
      {
        id: 271621,
        updated_at: "2022-11-22T08:16:39.000-08:00",
        created_at: "2022-11-22T08:16:38.000-08:00",
        report_url: mockReportUrl,
        error_log_url: null,
        user: user2,
        exam: {
          scored_on: "2022-11-22T08:16:39.000-08:00",
          exam_type: "AP",
          attempt: 1,
          name: "AP Biology 1L",
          series: "Biology",
          form_code: "1L",
          score: 1,
          all_sections_scored: true,
          complete: true,
          subjects: [
            {
              name: "Multiple Choice",
              score: 1,
              raw_score: 13
            }
          ]
        }
      }
    ]
  }
];
var transcriptSummaryIncomplete = [
  {
    exam_type: "ACT",
    improvement: 17.5,
    max_score: 36,
    min_score: 1,
    best_score: 22,
    transcripts: [
      {
        id: 30489,
        created_at: "2022-11-22T08:16:38.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2016-03-21T14:07:58.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 1J",
          series: null,
          form_code: "1J",
          score: 6,
          complete: false,
          subjects: [
            { name: "English", score: 1, raw_score: 0 },
            { name: "Mathematics", score: 19, raw_score: 28 },
            { name: "Reading", score: 1, raw_score: 0 },
            { name: "Science", score: 1, raw_score: 0 }
          ]
        },
        essay: {
          id: 7293,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 26835,
        created_at: "2022-11-22T08:16:38.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2016-06-30T06:42:10.000-07:00",
          exam_type: "ACT",
          attempt: 2,
          name: "ACT 7J",
          series: null,
          form_code: "7J",
          score: 19,
          complete: true,
          subjects: [
            { name: "English", score: 24, raw_score: 55 },
            { name: "Mathematics", score: 20, raw_score: 31 },
            { name: "Reading", score: 18, raw_score: 19 },
            { name: "Science", score: 14, raw_score: 12 }
          ]
        },
        essay: {
          id: 10930,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      },
      {
        id: 25904,
        created_at: "2022-11-22T08:16:38.000-08:00",
        error_log_url: null,
        report_url: mockReportUrl,
        user: user2,
        exam: {
          all_sections_scored: false,
          scored_on: "2016-08-08T08:28:21.000-07:00",
          exam_type: "ACT",
          attempt: 1,
          name: "ACT 2J",
          series: null,
          form_code: "2J",
          score: 21,
          complete: true,
          subjects: [
            { name: "English", score: 21, raw_score: 48 },
            { name: "Mathematics", score: 19, raw_score: 29 },
            { name: "Reading", score: 23, raw_score: 26 },
            { name: "Science", score: 21, raw_score: 24 }
          ]
        },
        essay: {
          id: 11840,
          score: null,
          components: [],
          create_url: mockCreateUrl
        }
      }
    ]
  }
];

// src/lib/feed-item.ts
var activityFeedItemCard = {
  id: 1234,
  title: "Welcome to your Private Tutoring program!",
  subtitle: "Monday, October 19 at 3:00pm",
  body: "We're excited to start helping you improve. To get started, there are two tools you need to install to make your sessions successful. We also have a short video to show you what Revolution Private Tutoring is all about. Enjoy!",
  format: "card",
  countdown: false,
  icon: null,
  imageUrl: null,
  regardingId: 446688,
  regardingType: "Enrollment",
  video: null,
  avatarUrl: "https://revolutionprep.com/logo.svg",
  bannerImageUrl: null,
  startsAt: "2021-05-28T06:55:25-07:00",
  actions: [
    {
      action: "url",
      detail: "https://zoom.us/download",
      data: {},
      label: "Install Zoom",
      icon: "mdi-download",
      placement: "block",
      description: "Install Zoom to join your online tutoring sessions"
    },
    {
      action: "url",
      detail: "https://www.revolutionprep.com/app",
      data: {},
      label: "Download Mobile App",
      icon: "mdi-download",
      placement: "block",
      description: "Download our Exam Scoring mobile application for iOS or Android devices"
    }
  ]
};
var activityFeedItemAlert = {
  id: 1246,
  title: "Tutoring session with Kevin Malone is coming up!",
  subtitle: "Thursday, June  3 at 6:30AM",
  body: "Just a reminder that you\u2019ve got your session with Kevin this Thursday at 6:30AM. If you need to upload any materials or leave Kevin some notes, now's a great time.",
  format: "alert",
  countdown: true,
  icon: null,
  imageUrl: null,
  regardingId: 1842180,
  regardingType: "Attendance",
  video: null,
  avatarUrl,
  bannerImageUrl: null,
  startsAt: "2021-06-03T06:30:00-07:00",
  actions: [
    {
      action: "modal",
      detail: "showSessionDialog",
      data: {
        sessionId: 1232323,
        studentId: 444444
      },
      label: "View Details",
      icon: null,
      placement: "default",
      description: null
    }
  ]
};

// src/lib/feed-action-address.ts
var activityFeedItemLocation = {
  cityStateZip: "Washington, DC 20500",
  country: "United States",
  detail: "https://www.google.com/maps/place/The+White+House",
  lat: "38.8977",
  lng: "-77.0366",
  room: "",
  street: "1600 Pennsylvania Avenue Northwest"
};

// src/lib/gpa.ts
var gpas = [
  "3.76\u20134.0",
  "3.51\u20133.75",
  "3.26\u20133.5",
  "3.0\u20133.25",
  "2.76\u20133.0",
  "2.51\u20132.75",
  "2.26\u20132.5",
  "2.01\u20132.25",
  "1.76\u20132.0",
  "1.51\u20131.75",
  "1.26\u20131.5",
  "1.0\u20131.25",
  "Mostly A's",
  "Mostly A's/B's",
  "Mostly B's",
  "Mostly B's/C's",
  "Mostly C's",
  "N/A / Don't know"
];

// src/lib/grade-level.ts
var gradeLevels = [
  {
    id: 16,
    name: "College Senior",
    grade: 16
  },
  {
    id: 15,
    name: "College Junior",
    grade: 15
  },
  {
    id: 14,
    name: "College Sophomore",
    grade: 14
  },
  {
    id: 13,
    name: "College Freshman",
    grade: 13
  },
  {
    id: 12,
    name: "12th Grade",
    grade: 12
  },
  {
    id: 11,
    name: "11th Grade",
    grade: 11
  },
  {
    id: 10,
    name: "10th Grade",
    grade: 10
  },
  {
    id: 9,
    name: "9th Grade",
    grade: 9
  },
  {
    id: 8,
    name: "8th Grade",
    grade: 8
  },
  {
    id: 7,
    name: "7th Grade",
    grade: 7
  },
  {
    id: 6,
    name: "6th Grade",
    grade: 6
  },
  {
    id: 5,
    name: "5th Grade",
    grade: 5
  },
  {
    id: 4,
    name: "4th Grade",
    grade: 4
  },
  {
    id: 3,
    name: "3rd Grade",
    grade: 3
  },
  {
    id: 2,
    name: "2nd Grade",
    grade: 2
  },
  {
    id: 1,
    name: "1st Grade",
    grade: 1
  }
];
var gradeLevelsWithGradYear = [
  {
    id: 16,
    grade: 16,
    name: "College Senior (2018 graduation year)"
  },
  {
    id: 15,
    grade: 15,
    name: "College Junior (2019 graduation year)"
  },
  {
    id: 14,
    grade: 14,
    name: "College Sophomore (2020 graduation year)"
  },
  {
    id: 13,
    grade: 13,
    name: "College Freshman (2021 graduation year)"
  },
  {
    id: 12,
    grade: 12,
    name: "12th Grade (2022 graduation year)"
  },
  {
    id: 11,
    grade: 11,
    name: "11th Grade (2023 graduation year)"
  },
  {
    id: 10,
    grade: 10,
    name: "10th Grade (2024 graduation year)"
  },
  {
    id: 9,
    grade: 9,
    name: "9th Grade (2025 graduation year)"
  },
  {
    id: 8,
    grade: 8,
    name: "8th Grade (2026 graduation year)"
  },
  {
    id: 7,
    grade: 7,
    name: "7th Grade (2027 graduation year)"
  },
  {
    id: 6,
    grade: 6,
    name: "6th Grade (2028 graduation year)"
  },
  {
    id: 5,
    grade: 5,
    name: "5th Grade (2029 graduation year)"
  },
  {
    id: 4,
    grade: 4,
    name: "4th Grade (2030 graduation year)"
  },
  {
    id: 3,
    grade: 3,
    name: "3rd Grade (2031 graduation year)"
  },
  {
    id: 2,
    grade: 2,
    name: "2nd Grade (2032 graduation year)"
  },
  {
    id: 1,
    grade: 1,
    name: "1st Grade (2033 graduation year)"
  },
  {
    id: 18,
    grade: 0,
    name: "Kindergarten (2034 graduation year)"
  },
  {
    id: 17,
    grade: -1,
    name: "TK (2035 graduation year)"
  }
];

// src/lib/grade-score.ts
var gradeScore = [
  {
    grade: "A+",
    score: 100
  },
  {
    grade: "A",
    score: 96
  },
  {
    grade: "A-",
    score: 92
  },
  {
    grade: "B+",
    score: 89
  },
  {
    grade: "B",
    score: 86
  },
  {
    grade: "B-",
    score: 82
  },
  {
    grade: "C+",
    score: 79
  },
  {
    grade: "C",
    score: 76
  },
  {
    grade: "C-",
    score: 72
  },
  {
    grade: "D+",
    score: 69
  },
  {
    grade: "D",
    score: 66
  },
  {
    grade: "D-",
    score: 62
  },
  {
    grade: "F",
    score: 59
  },
  {
    grade: "Pass",
    score: 10
  },
  {
    grade: "Fail",
    score: -1
  },
  {
    grade: "1",
    score: 1
  },
  {
    grade: "2",
    score: 2
  },
  {
    grade: "3",
    score: 3
  },
  {
    grade: "4",
    score: 4
  },
  {
    grade: "5",
    score: 5
  },
  {
    grade: "6",
    score: 6
  },
  {
    grade: "7",
    score: 7
  }
];

// src/lib/navigation-menu-item.ts
var menuItems = () => {
  return [
    {
      id: "navItemDashboard",
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "/",
      external: false
    },
    {
      id: "navItemCallList",
      title: "Call List",
      icon: "mdi-card-account-phone",
      to: "/call-list",
      external: true,
      roles: [
        "academic_advisor",
        "advising_manager",
        "director_of_academic_advising",
        "developer"
      ]
    },
    {
      id: "navItemProspecting",
      title: "Prospecting",
      icon: "mdi-account-cash",
      to: "/family-search",
      external: true,
      roles: [
        "academic_advisor",
        "advising_manager",
        "director_of_academic_advising",
        "developer"
      ]
    },
    {
      id: "navItemAdvisor",
      title: "Advisor",
      icon: "mdi-briefcase",
      to: null,
      roles: [
        "academic_advisor",
        "advising_manager",
        "director_of_academic_advising",
        "developer",
        "admin"
      ],
      items: [
        {
          id: "navItemAdvisorLeadSources",
          title: "Lead Sources",
          icon: null,
          to: "/lead-sources",
          external: true,
          roles: "admin"
        },
        {
          id: "navItemAdvisorTutorSearch",
          title: "Tutor Search",
          icon: null,
          to: "/tutors",
          external: true,
          roles: [
            "academic_advisor",
            "advising_manager",
            "director_of_academic_advising",
            "developer"
          ]
        }
      ]
    },
    {
      id: "navItemTeams",
      title: "Teams",
      icon: "mdi-account-group",
      to: null,
      items: [
        {
          id: "navItemTeamsAdvisingTeams",
          title: "Advising Teams",
          icon: null,
          to: "/advisor-teams",
          external: true
        },
        {
          id: "navItemTeamsTutorTeams",
          title: "Tutor Teams",
          icon: null,
          to: "/tutor-teams",
          external: true,
          roles: [
            "instruction_manager",
            "director_of_instruction_management"
          ]
        }
      ]
    },
    {
      id: "navItemUsers",
      title: "Users",
      icon: "mdi-account",
      to: null,
      items: [
        {
          id: "navItemUsersStudents",
          title: "Students",
          icon: null,
          to: "/users/students",
          external: true
        },
        {
          id: "navItemUsersParents",
          title: "Parents",
          icon: null,
          to: "/users/parents",
          external: true
        },
        {
          id: "navItemUsersTutors",
          title: "Tutors",
          icon: null,
          to: "/users/tutors",
          external: true,
          roles: "hr"
        },
        {
          id: "navItemUsersEmployees",
          title: "Employees",
          icon: null,
          to: "/users/employees",
          external: true,
          roles: "hr"
        }
      ]
    },
    {
      id: "navItemCourses",
      title: "Courses",
      icon: "mdi-school",
      to: null,
      items: [
        {
          id: "navItemCoursesCourseSearch",
          title: "Course Search",
          icon: null,
          to: "/courses",
          external: true
        },
        {
          id: "navItemCoursesSessionSearch",
          title: "Session Search",
          icon: null,
          to: "/sessions",
          external: true,
          roles: "instruction_manager"
        },
        {
          id: "navItemCoursesDepartments",
          title: "Departments",
          icon: null,
          to: "/departments",
          external: true,
          roles: "developer"
        },
        {
          id: "navItemCoursesTestDates",
          title: "Test Dates",
          icon: null,
          to: "/test-dates",
          external: true,
          roles: "instruction_manager"
        }
      ]
    },
    {
      id: "navItemReports",
      title: "Reports",
      icon: "mdi-chart-line",
      to: null,
      roles: "admin",
      items: [
        {
          id: "navItemReportsTransactionsReport",
          title: "Transactions Report",
          icon: null,
          to: "/reports/transactions",
          external: true
        },
        {
          id: "navItemReportsDailySalesReport",
          title: "Daily Sales Report",
          icon: null,
          to: "/reports/daily-sales",
          external: true
        },
        {
          id: "navItemReportsAdvisorActivityReport",
          title: "Advisor Activity Report",
          icon: null,
          to: "/reports/advisor-activity",
          external: true
        }
      ]
    },
    {
      id: "navItemOperations",
      title: "Operations",
      icon: "mdi-cog",
      to: null,
      roles: [
        "accountant",
        "director_of_finance_and_analytics",
        "hr",
        "operations"
      ],
      items: [
        {
          id: "navItemOperationsBookingUpdate",
          title: "Booking Update",
          icon: null,
          to: "/bookings",
          external: true,
          roles: "operations"
        },
        {
          id: "navItemOperationsCoupons",
          title: "Coupons",
          icon: null,
          to: "/coupons",
          external: true,
          roles: "operations"
        },
        {
          id: "navItemOperationsMaterials",
          title: "Materials",
          icon: null,
          to: "/materials",
          external: true,
          roles: "operations"
        },
        {
          id: "navItemOperationsPayrolls",
          title: "Payrolls",
          icon: null,
          to: "/payrolls",
          external: true,
          roles: [
            "accountant",
            "director_of_finance_and_analytics",
            "hr"
          ]
        },
        {
          id: "navItemOperationsRefunds",
          title: "Refunds",
          icon: null,
          to: "/refund-requests",
          external: true,
          roles: [
            "operations",
            "director_of_finance_and_analytics"
          ]
        },
        {
          id: "navItemOperationsShipments",
          title: "Shipments",
          icon: null,
          to: "/shipments",
          external: true,
          roles: "operations"
        }
      ]
    },
    {
      id: "navItemEssays",
      title: "Essays",
      icon: "mdi-file-document",
      to: null,
      roles: "instruction_manager",
      items: [
        {
          id: "navItemEssaysEssayGraders",
          title: "Essay Graders",
          icon: null,
          to: "/essays",
          external: true,
          roles: "instruction_manager"
        },
        {
          id: "navItemEssaysEssayGrading",
          title: "Essay Grading",
          icon: null,
          to: "/essay-grading",
          external: true
        }
      ]
    }
  ];
};

// src/lib/notice.ts
var newNotice = {
  acknowledged_at: null,
  created_at: "2024-04-09T07:48:19-07:00",
  due_at: null,
  id: 2876971,
  link_id: 1803197,
  link_type: "Session",
  message: "You have a new Tutoring session with Desmond Inglis on Wed at 7:00AM.",
  read_at: null,
  recipient_id: 750653,
  recipient_type: "Student",
  title: "New Session",
  updated_at: "2024-04-09T07:48:19-07:00"
};
var headsUpNotice = {
  title: "Session about to start",
  message: "Your session at 8:05AM PDT with Desmond is about to start.",
  session_id: 1803199
};

// src/lib/organization-info.ts
var organizationInfo = {
  name: "Revolution Prep",
  tagLine: "Learn Smarter",
  address: {
    street1: "1337 3rd Street Promenade",
    street2: "3rd Floor",
    city: "Santa Monica",
    state: "CA",
    zip: "90401",
    country: "United States"
  },
  phone: "+18777387737",
  department: {
    id: 1,
    name: "Retail",
    handle: "retail"
  }
};

// src/lib/place.ts
var place = {
  id: 1660,
  city: "Beacon",
  country: "United States",
  formatted: "554 Main Street, Beacon, NY 12508",
  lat: 41.5037,
  lng: -73.9626,
  name: "554 Main St",
  place_id: "ChIJoWnT2WYx3YkRYSRa4EF3BpI",
  state: "NY",
  street1: "554 Main Street",
  street2: null,
  zip: "12508"
};

// src/lib/pending-school.ts
var pendingSchool = {
  id: 167822,
  avatar: null,
  createdAt: "2024-05-29T11:30:08-05:00",
  centerId: 31,
  centerException: false,
  description: ",",
  highGrade: null,
  category: "High School",
  leadScore: null,
  level: null,
  lowGrade: null,
  name: "NOTASCHOOL (NOTACITY,\n            AK)",
  nickname: null,
  param: null,
  schoolType: null,
  type: "PendingSchool",
  updatedAt: "2024-05-29T11:30:08-05:00",
  websiteUrl: null,
  fullName: "NOTASCHOOL (NOTACITY,\n            AK)",
  address: null,
  employee: null,
  closer: null,
  center: {
    id: 31,
    name: "No Center",
    leadSourcesCount: -7697,
    color: null,
    displayShape: false,
    shape: null,
    lat: null,
    lng: null,
    managerId: 3056,
    polygon: null,
    regionId: null,
    createdAt: "2019-11-21T17:38:30-06:00",
    updatedAt: "2023-02-06T17:17:20-06:00"
  },
  contactItem: null,
  lastPromotion: null,
  upcomingPromotions: []
};
var pendingSchoolOutsideUS = {
  id: 167823,
  avatar: null,
  createdAt: "2024-05-29T16:51:42-05:00",
  centerId: 31,
  centerException: false,
  description: ", ",
  highGrade: null,
  category: "High School",
  leadScore: null,
  level: null,
  lowGrade: null,
  name: "ASDFASDFASDF (ASDF,\n              ASDF, Azerbaijan)",
  nickname: null,
  param: null,
  schoolType: null,
  type: "PendingSchool",
  updatedAt: "2024-05-29T16:51:42-05:00",
  websiteUrl: null,
  fullName: "ASDFASDFASDF (ASDF,\n              ASDF, Azerbaijan)",
  address: null,
  employee: null,
  closer: null,
  center: {
    id: 31,
    name: "No Center",
    leadSourcesCount: -7696,
    color: null,
    displayShape: false,
    shape: null,
    lat: null,
    lng: null,
    managerId: 3056,
    polygon: null,
    regionId: null,
    createdAt: "2019-11-21T17:38:30-06:00",
    updatedAt: "2023-02-06T17:17:20-06:00"
  },
  contactItem: null,
  lastPromotion: null,
  upcomingPromotions: []
};

// src/lib/prime-hour.ts
var primeHours = [
  {
    day: "Sunday",
    hour: 5,
    duration: 14
  },
  {
    day: "Monday",
    hour: 6,
    duration: 14
  },
  {
    day: "Tuesday",
    hour: 6,
    duration: 14
  },
  {
    day: "Wednesday",
    hour: 6,
    duration: 14
  },
  {
    day: "Thursday",
    hour: 6,
    duration: 14
  },
  {
    day: "Friday",
    hour: 6,
    duration: 10
  },
  {
    day: "Saturday",
    hour: 5,
    duration: 11
  }
];

// src/lib/pricing.ts
var pricing = {
  id: null,
  type: "Pricing::Fixed",
  price: 2799,
  recurringPrice: 0,
  intervalLength: null,
  intervalUnit: null,
  totalOccurrences: null,
  setupFee: 0,
  trialPrice: 0,
  trialIntervalLength: null,
  trialIntervalUnit: null,
  contractIntervalLength: null,
  contractIntervalUnit: null,
  description: "$2,799.00",
  fixed: true,
  hasTrial: false,
  hasContract: false,
  recurring: false
};

// src/lib/property.ts
var properties = [
  {
    value: "ADHD/ADD",
    kind: "Learning Difference",
    id: 1,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Dyslexia",
    kind: "Learning Difference",
    id: 2,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Dyscalculia",
    kind: "Learning Difference",
    id: 3,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Dysgraphia",
    kind: "Learning Difference",
    id: 4,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Auditory Processing Disorders",
    kind: "Learning Difference",
    id: 5,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Visual Processing Disorders",
    kind: "Learning Difference",
    id: 6,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Traumatic Brain Injuries",
    kind: "Learning Difference",
    id: 7,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Autism Spectrum Disorders",
    kind: "Learning Difference",
    id: 8,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Anxiety Disorders",
    kind: "Learning Difference",
    id: 9,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Extended Time (50%)",
    kind: "Accommodation",
    id: 10,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "Extended Time (100%)",
    kind: "Accommodation",
    id: 11,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "IEP",
    kind: "Accommodation",
    id: 12,
    createdAt: "2022-02-04T15:24:17-08:00"
  },
  {
    value: "504",
    kind: "Accommodation",
    id: 13,
    createdAt: "2022-02-04T15:24:17-08:00"
  }
];

// src/lib/property-record.ts
var propertyRecord = {
  id: 7,
  kind: "Accommodation",
  value: "Extended Time (50%)",
  propertyId: 10,
  createdAt: "2022-02-09T14:05:29-08:00"
};

// src/lib/referral-source.ts
var referralSources = [
  "Financial Aid Referral",
  "Gift Card",
  "Internet Search",
  "Mock Exam",
  "Non-Gift Card Offer",
  "Non-School Referral",
  "Parent Event",
  "Parent Referral",
  "School Contact",
  "School Direct Referral",
  "Seminar/Workshop",
  "Student Event"
];

// src/lib/review.ts
var incompleteReview = {
  id: 123123,
  comment: null,
  completed: false,
  completedAt: null,
  createdAt: "2017-08-31T19:40:30-05:00",
  featured: false,
  stars: null,
  updatedAt: "2017-08-31T19:40:30-05:00",
  employee,
  course: coursePrivateTutoringWithBrand
};

// src/lib/search-filter-params.ts
var searchFilterParams = [
  [
    {
      title: "Brands",
      id: "brandIds",
      icon: "mdi-clipboard-text-outline",
      type: "multi-select",
      options: []
    },
    {
      title: "Status",
      id: "status",
      icon: "mdi-list-status",
      type: "select",
      options: [
        {
          text: "All",
          value: "all"
        },
        {
          text: "Published",
          value: "published"
        },
        {
          text: "Unpublished",
          value: "unpublished"
        },
        {
          text: "Cancelled",
          value: "cancelled"
        }
      ]
    },
    {
      title: "Time Period",
      id: "schedule",
      icon: "mdi-list-status",
      type: "selectWithDatePicker",
      options: [
        {
          text: "Anytime",
          value: "anytime"
        },
        {
          text: "Upcoming + Ongoing",
          value: "upcoming + ongoing"
        },
        {
          text: "Upcoming",
          value: "upcoming"
        },
        {
          text: "Ongoing",
          value: "ongoing"
        },
        {
          text: "Past",
          value: "past"
        },
        {
          text: "Custom",
          value: "custom"
        }
      ]
    }
  ],
  [
    {
      title: "Segment",
      id: "segmentId",
      icon: "mdi-list-status",
      type: "select",
      options: [{
        text: "All",
        value: "all"
      }]
    },
    {
      title: "Subject",
      id: "subjectId",
      icon: "mdi-list-status",
      type: "select",
      options: [{
        text: "All",
        value: "all"
      }]
    },
    {
      title: "Test Date",
      id: "testDateId",
      icon: "mdi-list-status",
      type: "select",
      options: [{
        text: "All",
        value: "all"
      }]
    },
    {
      title: "Grade Level",
      id: "gradeLevel",
      icon: "mdi-list-status",
      type: "select",
      options: [{
        text: "All",
        value: "all"
      }]
    }
  ],
  [
    {
      title: "Staffing",
      id: "staffing",
      icon: "mdi-list-status",
      type: "select",
      options: [
        {
          text: "All",
          value: "all"
        },
        {
          text: "Staffed",
          value: "staffed"
        },
        {
          text: "Unstaffed",
          value: "unstaffed"
        }
      ]
    },
    {
      title: "Enrollment",
      id: "enrollment",
      icon: "mdi-list-status",
      type: "select",
      options: [
        {
          text: "All",
          value: "all"
        },
        {
          text: "Open",
          value: "open"
        },
        {
          text: "Full",
          value: "full"
        },
        {
          text: "None",
          value: "none"
        }
      ]
    },
    {
      title: "Lead Source",
      id: "leadSourceId",
      icon: "mdi-list-status",
      type: "autocompleteLeadSource"
    },
    {
      title: "Zip",
      id: "zip",
      icon: "mdi-list-status",
      type: "text"
    }
  ],
  [
    {
      title: "Delivery",
      icon: "mdi-list-status",
      type: "checkbox",
      options: [
        {
          text: "Online",
          id: "online"
        },
        {
          text: "In-person",
          id: "inPerson"
        }
      ]
    },
    {
      title: "Reserved",
      id: "reserved",
      text: "Search for student",
      icon: "mdi-account-check",
      type: "switchWithTextField",
      component: "autocompleteStudent"
    },
    {
      title: "Hosted",
      id: "hosted",
      text: "Search for tutor",
      icon: "mdi-account-tie",
      type: "switchWithTextField",
      component: "autocompleteTutor"
    },
    {
      title: "Third Party",
      id: "thirdParty",
      icon: "mdi-account-tie-voice",
      type: "switch"
    }
  ]
];

// src/lib/self-study.ts
var selfStudyCourseItems = [
  {
    id: 174,
    unit: "Practice Exam",
    folder: null,
    name: null,
    kind: "Practice Exam",
    category: "Get Started By Taking a Practice Exam",
    sequence: 1,
    url: null,
    order: 1,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 175,
    unit: "Week 1",
    folder: null,
    name: null,
    kind: "Typeform",
    category: "How to Use This Course",
    sequence: 1,
    url: "https://form.typeform.com/to/Hd4AXhH2",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 176,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "Introduction to the SAT",
    kind: "Typeform",
    category: "Math",
    sequence: 2,
    url: "https://form.typeform.com/to/k2VrkGGX",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 177,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "Your Math Toolkit",
    kind: "Typeform",
    category: "Math",
    sequence: 3,
    url: "https://form.typeform.com/to/c73L07LZ",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 178,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "Backsolve",
    kind: "Typeform",
    category: "Math",
    sequence: 4,
    url: "https://form.typeform.com/to/MYEDtbyZ",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 179,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "How to Approach SAT Reading",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 5,
    url: "https://form.typeform.com/to/XFxCocVf",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 180,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "Reading Practice",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 6,
    url: "https://form.typeform.com/to/PvptfEIv",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 181,
    unit: "Week 1",
    folder: "Your Rev Ups",
    name: "Math Practice",
    kind: "Typeform",
    category: "Math",
    sequence: 7,
    url: "https://form.typeform.com/to/UtdIIEBC",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 182,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Mark and Move on",
    kind: "Typeform",
    category: "Strategy",
    sequence: 8,
    url: "https://form.typeform.com/to/majBgbOQ",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:50-07:00",
    updated_at: "2024-10-29T09:00:50-07:00"
  },
  {
    id: 183,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Introduction to Linear Equations",
    kind: "Typeform",
    category: "Math",
    sequence: 9,
    url: "https://form.typeform.com/to/NfwmMYYK",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 184,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Lines in Coordinate Plane",
    kind: "Typeform",
    category: "Math",
    sequence: 10,
    url: "https://form.typeform.com/to/Fxo3IukA",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 185,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Linear Inequalities",
    kind: "Typeform",
    category: "Math",
    sequence: 11,
    url: "https://form.typeform.com/to/Zr8m9DNN",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 186,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Sentence Boundaries and Introduction to Writing",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 12,
    url: "https://form.typeform.com/to/CLOzRw83",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 187,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Possession",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 13,
    url: "https://form.typeform.com/to/UeHrSaDN",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 188,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Planning your Study tTme",
    kind: "Typeform",
    category: "Strategy",
    sequence: 14,
    url: "https://form.typeform.com/to/gRMOG0hW",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 189,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Student Produced Responses",
    kind: "Typeform",
    category: "Math",
    sequence: 15,
    url: "https://form.typeform.com/to/JDeZNwDk",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 190,
    unit: "Week 2",
    folder: "Your Rev Ups",
    name: "Reading/Writing practice",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 16,
    url: "https://form.typeform.com/to/KBi8rq4F",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 191,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Function Notation and Evaluating Functions",
    kind: "Typeform",
    category: "Math",
    sequence: 17,
    url: "https://form.typeform.com/to/mqTCt154",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 192,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Functions as Charts and Graphs",
    kind: "Typeform",
    category: "Math",
    sequence: 18,
    url: "https://form.typeform.com/to/TGXPjO2s",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 193,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Absolute Value",
    kind: "Typeform",
    category: "Math",
    sequence: 19,
    url: "https://form.typeform.com/to/69WveM04",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 194,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Annotation and Cross-out Tools",
    kind: "Typeform",
    category: "Strategy",
    sequence: 20,
    url: "https://form.typeform.com/to/YWEeBfbt",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 195,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Thinking Big and Small: Thinking Big",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 21,
    url: "https://form.typeform.com/to/lpWpRLlN",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 196,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Verbs",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 22,
    url: "https://form.typeform.com/to/AMDE5EOg",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 197,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Developing Mental Endurance",
    kind: "Typeform",
    category: "Strategy",
    sequence: 23,
    url: "https://form.typeform.com/to/U7FbRHoy",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 198,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Student Produced Responses",
    kind: "Typeform",
    category: "Math",
    sequence: 24,
    url: "https://form.typeform.com/to/ililugou",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 199,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Timing Strategy",
    kind: "Typeform",
    category: "Strategy",
    sequence: 25,
    url: "https://form.typeform.com/to/taK747NO",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  },
  {
    id: 200,
    unit: "Week 3",
    folder: "Your Rev Ups",
    name: "Reading and Writing practice",
    kind: "Typeform",
    category: "Reading and Writing",
    sequence: 26,
    url: "https://form.typeform.com/to/fRmDqqmu",
    order: 2,
    syllabus_name: "SAT",
    created_at: "2024-10-29T09:00:51-07:00",
    updated_at: "2024-10-29T09:00:51-07:00"
  }
];
var selfStudyCourseItemEngagements = [
  {
    id: 346,
    course_item_id: 176,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:18:26-07:00",
    completed_at: "2024-10-29T11:18:25-07:00",
    correct_count: 1,
    total_count: 2,
    attempts: 1
  },
  {
    id: 347,
    course_item_id: 178,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:32:57-07:00",
    completed_at: "2024-10-29T11:32:57-07:00",
    correct_count: 0,
    total_count: 1,
    attempts: 1
  },
  {
    id: 348,
    course_item_id: 177,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:33:49-07:00",
    completed_at: "2024-10-29T11:33:49-07:00",
    correct_count: 1,
    total_count: 1,
    attempts: 1
  },
  {
    id: 349,
    course_item_id: 179,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:49:16-07:00",
    completed_at: "2024-10-29T11:49:16-07:00",
    correct_count: 1,
    total_count: 1,
    attempts: 1
  },
  {
    id: 350,
    course_item_id: 180,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:50:08-07:00",
    completed_at: "2024-10-29T11:50:07-07:00",
    correct_count: 0,
    total_count: 1,
    attempts: 1
  },
  {
    id: 351,
    course_item_id: 181,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:50:19-07:00",
    completed_at: "2024-10-29T11:50:18-07:00",
    correct_count: 0,
    total_count: 1,
    attempts: 1
  },
  {
    id: 352,
    course_item_id: 175,
    enrollment_id: 791234,
    updated_at: "2024-10-29T11:52:20-07:00",
    completed_at: "2024-10-29T11:52:19-07:00",
    correct_count: 1,
    total_count: 1,
    attempts: 1
  }
];

// src/lib/state.ts
var states = [
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "District of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "United States Minor Outlying Islands",
    abbreviation: "UM"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  },
  {
    name: "Armed Forces Americas (except Canada)",
    abbreviation: "AA"
  },
  {
    name: "Armed Forces Africa, Canada, Europe, Middle East",
    abbreviation: "AE"
  },
  {
    name: "Armed Forces Pacific",
    abbreviation: "AP"
  }
];

// src/lib/statistic.ts
var statistics = [
  {
    label: "Best Score",
    value: "1600",
    unit: "",
    color: "#EF8536"
  },
  {
    label: "Improvement",
    value: "630",
    unit: "points",
    color: "#EF8536"
  }
];
var statistic = {
  label: "Improvement",
  value: "630",
  unit: "points",
  color: "#EF8536"
};

// src/lib/study-area-snapshot.ts
var studyAreaSnapshots = [
  {
    id: 36,
    score: 1220,
    official: true,
    date: "2021-11-06"
  },
  {
    id: 34,
    score: 1160,
    official: true,
    date: "2021-08-28"
  },
  {
    id: 35,
    score: 1030,
    official: true,
    date: "2021-06-05"
  }
];
var studyAreaSnapshot = {
  id: 123,
  score: 100,
  official: false,
  date: "2022-02-01"
};
var digitalSnapshots = [
  {
    id: 138,
    kind: "Official Bluebook Practice Exam",
    data: {
      math: 200,
      "reading and writing": 350
    },
    score: 1200,
    official: true,
    date: "2023-01-02"
  },
  {
    id: 139,
    kind: "Official Exam",
    data: {
      math: 400,
      "reading and writing": 380
    },
    score: 1200,
    official: true,
    date: "2023-01-03"
  }
];

// src/lib/study-area.ts
var studyAreas = [
  {
    id: 1e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 75,
    subject: studyAreaSubjects[0],
    testDateId: null
  },
  {
    id: 2e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 11,
    subject: studyAreaSubjects[1],
    testDateId: null
  },
  {
    id: 3e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 2,
    subject: studyAreaSubjects[2],
    testDateId: null
  },
  {
    id: 4e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 83,
    subject: studyAreaSubjects[3],
    testDateId: null
  },
  {
    id: 5e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 119,
    subject: studyAreaSubjects[4],
    testDateId: null
  },
  {
    id: 6e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 24,
    subject: studyAreaSubjects[5],
    testDateId: null
  },
  {
    id: 7e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 15,
    subject: studyAreaSubjects[6],
    testDateId: null
  },
  {
    id: 8e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 90,
    subject: studyAreaSubjects[7],
    testDateId: null
  },
  {
    id: 9e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 79,
    subject: studyAreaSubjects[8],
    testDateId: null
  },
  {
    id: 1e4,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 4,
    subject: studyAreaSubjects[9],
    testDateId: null
  },
  {
    id: 11e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 8,
    subject: studyAreaSubjects[10],
    testDateId: null
  },
  {
    id: 12e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 58,
    subject: studyAreaSubjects[11],
    testDateId: null
  },
  {
    id: 13e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 60,
    subject: studyAreaSubjects[12],
    testDateId: null
  },
  {
    id: 14e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 101,
    subject: studyAreaSubjects[13],
    testDateId: null
  },
  {
    id: 15e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 23,
    subject: studyAreaSubjects[14],
    testDateId: null
  },
  {
    id: 16e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 35,
    subject: studyAreaSubjects[15],
    testDateId: null
  },
  {
    id: 17e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 40,
    subject: studyAreaSubjects[16],
    testDateId: null
  },
  {
    id: 18e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 92,
    subject: studyAreaSubjects[17],
    testDateId: null
  },
  {
    id: 19e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 3,
    subject: studyAreaSubjects[18],
    testDateId: null
  },
  {
    id: 2e4,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 44,
    subject: studyAreaSubjects[19],
    testDateId: null
  },
  {
    id: 21e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 48,
    subject: studyAreaSubjects[20],
    testDateId: null
  }
];
var studyArea = {
  id: 22e3,
  archivedAt: null,
  confidenceLevel: null,
  startingScore: null,
  goalScore: null,
  subjectId: 45,
  subject: subjects[48],
  testDateId: null
};
var digitalExamStudyArea = {
  id: 406080,
  archivedAt: null,
  confidenceLevel: null,
  goalScore: 1210,
  startingScore: 1200,
  subjectId: 316,
  testDateId: null,
  subject: digitalExamSubject,
  tutor: null,
  testDate: null,
  snapshots: digitalSnapshots,
  lastSnapshot: digitalSnapshots[0]
};
var testPrepStudyAreas = [
  {
    id: 313131,
    archivedAt: null,
    confidenceLevel: null,
    goalScore: 32,
    startingScore: 25,
    subjectId: 43,
    testDateId: 281,
    subject: testPrepSubjects[0],
    tutor: null,
    testDate: {
      id: 280,
      createdAt: "2020-11-18T16:58:44-08:00",
      date: "2022-04-02",
      title: "2022 - Apr 2 ACT",
      updatedAt: "2021-07-26T10:37:19-07:00"
    },
    snapshots: [
      {
        id: 7,
        score: 21,
        official: true,
        date: "2021-04-17"
      }
    ],
    lastSnapshot: {
      id: 7,
      score: 10,
      official: true,
      date: "2021-04-17"
    }
  },
  {
    id: 469347,
    archivedAt: null,
    confidenceLevel: null,
    goalScore: 1350,
    startingScore: 1120,
    subjectId: 51,
    testDateId: 276,
    subject: testPrepSubjects[3],
    tutor: null,
    testDate: {
      id: 275,
      createdAt: "2020-11-18T16:57:54-08:00",
      date: "2022-03-12",
      title: "2022 - Mar 12 SAT",
      updatedAt: "2020-11-18T16:57:54-08:00"
    },
    snapshots: [
      {
        id: 34,
        score: 1160,
        official: true,
        date: "2021-08-28"
      }
    ],
    lastSnapshot: {
      id: 34,
      score: 1160,
      official: true,
      date: "2021-08-28"
    }
  }
];
var examReviewStudyArea = {
  id: 292929,
  archivedAt: null,
  confidenceLevel: null,
  goalScore: null,
  startingScore: null,
  subjectId: 45,
  testDateId: null,
  subject: examReviewSubject
};
var studyAreaWithSnapshots = {
  id: 454545,
  archivedAt: null,
  confidenceLevel: null,
  goalScore: null,
  startingScore: null,
  subjectId: 11,
  testDateId: null,
  subject: studyAreaSubjects[1],
  tutor: null,
  testDate: null,
  snapshots: [
    {
      id: 1840,
      score: 79,
      official: false,
      date: "2022-04-06"
    },
    {
      id: 1842,
      score: 82,
      official: false,
      date: "2022-04-16"
    },
    {
      id: 1843,
      score: 89,
      official: false,
      date: "2022-05-06"
    }
  ],
  lastSnapshot: {
    id: 1843,
    score: 89,
    official: false,
    date: "2022-05-06"
  }
};
var sortedStudyAreas = [
  {
    id: 19e3,
    archivedAt: null,
    confidenceLevel: null,
    goalScore: null,
    startingScore: null,
    subject: studyAreaSubjects[18],
    subjectId: 3,
    testDateId: null
  },
  {
    id: 1e4,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 4,
    subject: studyAreaSubjects[9],
    testDateId: null
  },
  {
    id: 3e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 2,
    subject: studyAreaSubjects[2],
    testDateId: null
  },
  {
    id: 8e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 90,
    subject: studyAreaSubjects[7],
    testDateId: null
  },
  {
    id: 2e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 11,
    subject: studyAreaSubjects[1],
    testDateId: null
  },
  {
    id: 1e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 75,
    subject: studyAreaSubjects[0],
    testDateId: null
  },
  {
    id: 13e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 60,
    subject: studyAreaSubjects[12],
    testDateId: null
  },
  {
    id: 9e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 79,
    subject: studyAreaSubjects[8],
    testDateId: null
  },
  {
    id: 7e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 15,
    subject: studyAreaSubjects[6],
    testDateId: null
  },
  {
    id: 16e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 35,
    subject: studyAreaSubjects[15],
    testDateId: null
  },
  {
    id: 2e4,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 44,
    subject: studyAreaSubjects[19],
    testDateId: null
  },
  {
    id: 12e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 58,
    subject: studyAreaSubjects[11],
    testDateId: null
  },
  {
    id: 21e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 48,
    subject: studyAreaSubjects[20],
    testDateId: null
  },
  {
    id: 18e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 92,
    subject: studyAreaSubjects[17],
    testDateId: null
  },
  {
    id: 14e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 101,
    subject: studyAreaSubjects[13],
    testDateId: null
  },
  {
    id: 4e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 83,
    subject: studyAreaSubjects[3],
    testDateId: null
  },
  {
    id: 15e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 23,
    subject: studyAreaSubjects[14],
    testDateId: null
  },
  {
    id: 5e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 119,
    subject: studyAreaSubjects[4],
    testDateId: null
  },
  {
    id: 11e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 8,
    subject: studyAreaSubjects[10],
    testDateId: null
  },
  {
    id: 17e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 40,
    subject: studyAreaSubjects[16],
    testDateId: null
  },
  {
    id: 6e3,
    archivedAt: null,
    confidenceLevel: null,
    startingScore: null,
    goalScore: null,
    subjectId: 24,
    subject: studyAreaSubjects[5],
    testDateId: null
  }
];

// src/lib/test-date.ts
var recentTestDates = [
  {
    id: 200,
    createdAt: "2020-11-18T16:58:33-08:00",
    date: "2022-02-05",
    title: "2022 - Feb 5 ACT",
    updatedAt: "2020-11-18T16:58:33-08:00"
  },
  {
    id: 201,
    createdAt: "2020-11-18T16:58:22-08:00",
    date: "2021-12-11",
    title: "2021 - Dec 11 ACT",
    updatedAt: "2020-11-18T16:58:22-08:00"
  },
  {
    id: 202,
    createdAt: "2020-11-18T16:58:12-08:00",
    date: "2021-10-23",
    title: "2021 - Oct 23 ACT",
    updatedAt: "2020-11-18T16:58:12-08:00"
  },
  {
    id: 203,
    createdAt: "2020-11-18T16:58:03-08:00",
    date: "2021-09-11",
    title: "2021 - Sep 11 ACT",
    updatedAt: "2020-11-18T16:58:03-08:00"
  },
  {
    id: 204,
    createdAt: "2020-08-18T10:55:48-07:00",
    date: "2021-07-17",
    title: "2021 - Jul 17 ACT",
    updatedAt: "2020-08-18T10:55:48-07:00"
  },
  {
    id: 205,
    createdAt: "2020-08-18T10:55:41-07:00",
    date: "2021-06-12",
    title: "2021 - Jun 12 ACT",
    updatedAt: "2020-08-18T10:55:41-07:00"
  },
  {
    id: 206,
    createdAt: "2020-08-18T10:55:34-07:00",
    date: "2021-04-17",
    title: "2021 - Apr 17 ACT",
    updatedAt: "2020-08-18T10:55:34-07:00"
  }
];
var upcomingTestDates = [
  {
    id: 404,
    createdAt: "2121-07-26T10:36:17-07:00",
    date: "2122-06-04",
    title: "2122 - Jun 4 SAT",
    updatedAt: "2121-07-26T10:36:17-07:00"
  },
  {
    id: 405,
    createdAt: "2121-07-26T10:36:00-07:00",
    date: "2122-05-07",
    title: "2122 - May 7 SAT",
    updatedAt: "2121-07-26T10:36:00-07:00"
  },
  {
    id: 406,
    createdAt: "2120-11-18T16:57:54-08:00",
    date: "2122-03-12",
    title: "2122 - Mar 12 SAT",
    updatedAt: "2120-11-18T16:57:54-08:00"
  }
];

// src/lib/time-zone.ts
var timeZones = [
  {
    name: "Hawaii (GMT-10:00)",
    value: "Pacific/Honolulu",
    category: "US"
  },
  {
    name: "Alaska (GMT-09:00)",
    value: "America/Juneau",
    category: "US"
  },
  {
    name: "Pacific Time (US & Canada) (GMT-08:00)",
    value: "America/Los_Angeles",
    category: "US"
  },
  {
    name: "Arizona (GMT-07:00)",
    value: "America/Phoenix",
    category: "US"
  },
  {
    name: "Mountain Time (US & Canada) (GMT-07:00)",
    value: "America/Denver",
    category: "US"
  },
  {
    name: "Central Time (US & Canada) (GMT-06:00)",
    value: "America/Chicago",
    category: "US"
  },
  {
    name: "Eastern Time (US & Canada) (GMT-05:00)",
    value: "America/New_York",
    category: "US"
  },
  {
    name: "Indiana (East) (GMT-05:00)",
    value: "America/Indiana/Indianapolis",
    category: "US"
  },
  {
    name: "International Date Line West (GMT-12:00)",
    value: "Etc/GMT+12",
    category: "All"
  },
  {
    name: "American Samoa (GMT-11:00)",
    value: "Pacific/Pago_Pago",
    category: "All"
  },
  {
    name: "Midway Island (GMT-11:00)",
    value: "Pacific/Midway",
    category: "All"
  },
  {
    name: "Tijuana (GMT-08:00)",
    value: "America/Tijuana",
    category: "All"
  },
  {
    name: "Chihuahua (GMT-07:00)",
    value: "America/Chihuahua",
    category: "All"
  },
  {
    name: "Mazatlan (GMT-07:00)",
    value: "America/Mazatlan",
    category: "All"
  },
  {
    name: "Central America (GMT-06:00)",
    value: "America/Guatemala",
    category: "All"
  },
  {
    name: "Guadalajara (GMT-06:00)",
    value: "America/Mexico_City",
    category: "All"
  },
  {
    name: "Mexico City (GMT-06:00)",
    value: "America/Mexico_City",
    category: "All"
  },
  {
    name: "Monterrey (GMT-06:00)",
    value: "America/Monterrey",
    category: "All"
  },
  {
    name: "Saskatchewan (GMT-06:00)",
    value: "America/Regina",
    category: "All"
  },
  {
    name: "Bogota (GMT-05:00)",
    value: "America/Bogota",
    category: "All"
  },
  {
    name: "Lima (GMT-05:00)",
    value: "America/Lima",
    category: "All"
  }
];

// src/lib/tutor-package.ts
var tutorPackages = [
  {
    id: 18642,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 149,
    duration: 720,
    hours: 12,
    fixedTotal: 1799,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 93339,
      price: 1799,
      description: "$1,799.00"
    },
    tier: tiers[1]
  },
  {
    id: 18439,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 179,
    duration: 720,
    hours: 12,
    fixedTotal: 2199,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 93939,
      price: 2199,
      description: "$2,199.00"
    },
    tier: tiers[1]
  },
  {
    id: 18444,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 199,
    duration: 720,
    hours: 12,
    fixedTotal: 2399,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 93639,
      price: 2399,
      description: "$2,399.00"
    },
    tier
  },
  {
    id: 18447,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 229,
    duration: 720,
    hours: 12,
    fixedTotal: 2799,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91357
    },
    tier
  },
  {
    id: 18428,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 99,
    duration: 720,
    hours: 12,
    fixedTotal: 1199,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 91111,
      price: 1199,
      description: "$1,199.00"
    },
    tier: tiers[0]
  },
  {
    id: 18431,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 129,
    duration: 720,
    hours: 12,
    fixedTotal: 1599,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91234,
      price: 1599,
      description: "$1,599.00"
    },
    tier: tiers[0]
  },
  {
    id: 18432,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 129,
    duration: 1440,
    hours: 24,
    fixedTotal: 3099,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91335,
      price: 3099,
      description: "$3,099.00"
    },
    tier: tiers[0]
  },
  {
    id: 18437,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 149,
    duration: 1440,
    hours: 24,
    fixedTotal: 3599,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 91e3,
      price: 3599,
      description: "$3,599.00"
    },
    tier: tiers[1]
  },
  {
    id: 18440,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 179,
    duration: 1440,
    hours: 24,
    fixedTotal: 4299,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91313,
      price: 4299,
      description: "$4,299.00"
    },
    tier: tiers[1]
  },
  {
    id: 18445,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 199,
    duration: 1440,
    hours: 24,
    fixedTotal: 4799,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 91248,
      price: 4799,
      description: "$4,799.00"
    },
    tier
  },
  {
    id: 18448,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 229,
    duration: 1440,
    hours: 24,
    fixedTotal: 5499,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91551,
      price: 5499,
      description: "$5,499.00"
    },
    tier
  },
  {
    id: 18429,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 99,
    duration: 1440,
    hours: 24,
    fixedTotal: 2399,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 92929,
      price: 2399,
      description: "$2,399.00"
    },
    tier: tiers[0]
  },
  {
    id: 18433,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 129,
    duration: 2160,
    hours: 36,
    fixedTotal: 4699,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 91616,
      price: 4699,
      description: "$4,699.00"
    },
    tier: tiers[0]
  },
  {
    id: 18438,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 149,
    duration: 2160,
    hours: 36,
    fixedTotal: 5399,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 91414,
      price: 5399,
      description: "$5,399.00"
    },
    tier: tiers[1]
  },
  {
    id: 18441,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 179,
    duration: 2160,
    hours: 36,
    fixedTotal: 6499,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 90404,
      price: 6499,
      description: "$6,499.00"
    },
    tier: tiers[1]
  },
  {
    id: 18446,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 199,
    duration: 2160,
    hours: 36,
    fixedTotal: 7199,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 98789,
      price: 7199,
      description: "$7,199.00"
    },
    tier
  },
  {
    id: 18449,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 229,
    duration: 2160,
    hours: 36,
    fixedTotal: 8299,
    includeMembership: false,
    status: null,
    additionalItems: [],
    brand,
    pricing: {
      ...pricing,
      id: 95432,
      price: 8299,
      description: "$8,299.00"
    },
    tier
  },
  {
    id: 18430,
    createdAt: "2018-06-04T23:50:54-04:00",
    billingRate: 99,
    duration: 2160,
    hours: 36,
    fixedTotal: 3599,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 90909,
      price: 3599,
      description: "$3,599.00"
    },
    tier: tiers[0]
  },
  {
    id: 28117,
    createdAt: "2019-02-22T00:20:57-05:00",
    billingRate: 99,
    duration: 2880,
    hours: 48,
    fixedTotal: 4798,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 101010,
      price: 4798,
      description: "$4,798.00"
    },
    tier: tiers[0]
  },
  {
    id: 28118,
    createdAt: "2019-02-22T00:20:57-05:00",
    billingRate: 149,
    duration: 2880,
    hours: 48,
    fixedTotal: 7198,
    includeMembership: true,
    status: null,
    additionalItems: [courseMembership],
    brand,
    pricing: {
      ...pricing,
      id: 1e5,
      price: 7198,
      description: "$7,198.00"
    },
    tier: tiers[1]
  }
];
var tutorPackage = tutorPackages[0];

// src/lib/tutor-package-match.ts
var tutorPackageMatch = {
  id: 1,
  category: "Tutor Personality Fit",
  changeNotes: null,
  claimedAt: null,
  completed: false,
  completedAt: null,
  detail: null,
  kind: "Rematch",
  label: "Ready for Match",
  notes: "notes",
  possibleEvents: [
    "claim"
  ],
  reason: "Tutor Departure",
  replaceTutorId: null,
  requestedAt: "2022-02-10T16:17:20-08:00",
  startDate: "2022-03-01",
  state: "requested",
  student,
  tutorPackageId: 12345,
  createdAt: "2022-02-10T16:17:20-08:00",
  updatedAt: "2022-02-16T18:11:38-08:00"
};
var tutorPackageInitialMatch = {
  ...tutorPackageMatch,
  category: null,
  kind: "Initial Match",
  possibleEvents: [
    "claim",
    "request_confirmation"
  ],
  reason: null
};
var tutorPackageMatchResendEmail = {
  id: 2,
  category: null,
  changeNotes: null,
  claimedAt: null,
  completedAt: null,
  completed: false,
  createdAt: "2022-04-21T14:18:54-07:00",
  detail: "",
  kind: "Initial Match",
  label: "Waiting for Parent Form",
  notes: "Some notes",
  possibleEvents: [
    "resend_email",
    "confirm"
  ],
  reason: null,
  replaceTutorId: null,
  requestedAt: "2022-04-21T14:18:54-07:00",
  startDate: "2022-04-22",
  state: "pending_confirmation",
  tutorPackageId: 246,
  updatedAt: "2022-04-21T14:18:54-07:00"
};
var tutorPackageMatches = [
  {
    id: 6,
    category: "Content Knowledge",
    changeNotes: null,
    claimedAt: null,
    completed: false,
    completedAt: null,
    detail: "Subject-specific deficiency (Academic)",
    kind: "Rematch",
    label: "Ready for Match",
    notes: "Lorem ipsum dolor sit amet",
    possibleEvents: ["claim"],
    reason: "Dissatisfied With Tutor",
    replaceTutorId: null,
    requestedAt: "2022-02-17T16:44:17-08:00",
    startDate: "2022-06-01",
    state: "requested",
    tutorPackageId: 13579,
    createdAt: "2022-02-17T16:44:17-08:00",
    updatedAt: "2022-02-17T16:44:17-08:00"
  },
  {
    id: 5,
    category: "Sessions Unproductive",
    changeNotes: null,
    claimedAt: "2022-02-17T16:43:46-08:00",
    completed: true,
    completedAt: "2022-02-17T16:43:50-08:00",
    detail: null,
    kind: "Rematch",
    label: "Completed",
    notes: "Lorem ipsum dolor sit amet",
    possibleEvents: ["uncomplete"],
    reason: "Scheduling Issues",
    replaceTutorId: null,
    requestedAt: "2022-02-17T16:40:30-08:00",
    startDate: "2022-05-01",
    state: "completed",
    tutorPackageId: 13579,
    createdAt: "2022-02-17T16:40:30-08:00",
    updatedAt: "2022-02-17T16:43:50-08:00"
  },
  {
    id: 4,
    category: "Tutor Professionalism",
    changeNotes: null,
    claimedAt: "2022-02-17T16:39:47-08:00",
    completed: true,
    completedAt: "2022-02-17T16:39:52-08:00",
    detail: null,
    kind: "Rematch",
    label: "Completed",
    notes: null,
    possibleEvents: ["uncomplete"],
    reason: "Family Preference",
    replaceTutorId: null,
    requestedAt: "2022-02-17T16:34:15-08:00",
    startDate: "2022-04-01",
    state: "completed",
    tutorPackageId: 13579,
    createdAt: "2022-02-17T16:34:15-08:00",
    updatedAt: "2022-02-17T16:50:32-08:00"
  },
  {
    id: 3,
    category: null,
    changeNotes: null,
    claimedAt: "2022-02-17T16:33:06-08:00",
    completed: true,
    completedAt: "2022-02-17T16:33:12-08:00",
    detail: null,
    kind: "Initial Match",
    label: "Completed",
    notes: "Lorem ipsum dolor sit amet",
    possibleEvents: ["uncomplete"],
    reason: null,
    replaceTutorId: null,
    requestedAt: "2022-02-16T15:57:56-08:00",
    startDate: "2022-03-01",
    state: "completed",
    tutorPackageId: 13579,
    createdAt: "2022-02-16T15:57:56-08:00",
    updatedAt: "2022-02-17T16:45:50-08:00"
  }
];

// src/lib/tutor-package-transcript.ts
var tutorPackageTranscript = {
  activeMatch: tutorPackageInitialMatch,
  additionalItems: [],
  additionalSubject: null,
  additionalSubjectDuration: null,
  additionalSubjectFrequency: null,
  billingRate: 229,
  brand,
  createdAt: null,
  deliverable,
  deliveredDuration: 0,
  duration: 720,
  employee: null,
  expiredDuration: 0,
  fixedTotal: 2799,
  hours: 12,
  id: null,
  includeMembership: false,
  materials: null,
  maxSubjectDuration: null,
  maxSubjectFrequency: null,
  name: "12 Hours of Premium Tutoring for Ash Ketchum with Professor Oak",
  notes: null,
  pace: 0,
  pricing,
  provisionedDuration: 720,
  scheduledDuration: 0,
  status: "open",
  student,
  studentHasMembership: false,
  subject: null,
  subjectDuration: null,
  subjectFrequency: null,
  tier,
  totalDuration: 720,
  tutor,
  wonAt: null
};
var tutorPackageTranscriptWithParent = {
  activeMatch: tutorPackageInitialMatch,
  additionalItems: [],
  additionalSubject: null,
  additionalSubjectDuration: null,
  additionalSubjectFrequency: null,
  billingRate: 229,
  brand,
  createdAt: null,
  deliverable,
  deliveredDuration: 0,
  duration: 720,
  employee: null,
  expiredDuration: 0,
  fixedTotal: 2799,
  hours: 12,
  id: null,
  includeMembership: false,
  materials: null,
  maxSubjectDuration: null,
  maxSubjectFrequency: null,
  name: "12 Hours of Premium Tutoring for Ash Ketchum with Professor Oak",
  notes: null,
  pace: 0,
  parent: {
    id: 123456,
    advisorId: null,
    firstName: "Michael",
    lastInitial: "S",
    type: "Parent",
    timeZone: "America/Chicago",
    userId: 1234,
    callsCount: 0,
    createdAt: "2019-01-28T21:20:02-08:00",
    customer: true,
    doNotContact: true,
    emailSubscriptionStatus: "subscribed",
    email: "michael.scott@gmail.com",
    emailsCount: 0,
    employeeId: null,
    fullName: "Michael Scott",
    handoff: false,
    handlerType: "Learning Success Manager",
    homeEstimate: 123456,
    homeEstimateRatio: 0.9,
    homeUrl: "http://localhost:8888",
    hubspotContactUrl: null,
    language: "en",
    lastActivityAt: null,
    lastName: "Shearer",
    lastWebSaleAt: null,
    leadAt: "2019-01-28T21:20:02-08:00",
    leadScore: null,
    leadScoreDigest: null,
    lockedAt: null,
    login: "michaelscott",
    phone: "+13333334444",
    pushNotifications: true,
    referralRevenue: "0.0",
    revenue: 4312,
    smsCount: 0,
    smsOptInAt: null,
    smsOptInMedium: null,
    smsOptInType: null,
    source: "Internet Search",
    sourceLink: null,
    status: "No Sales/Partner Contact",
    students: [student],
    updatedAt: "2019-02-07T14:44:10-08:00",
    username: "michaelscott",
    vip: false,
    active: true,
    name: "Michael Scott"
  },
  pricing,
  provisionedDuration: 720,
  scheduledDuration: 0,
  status: "open",
  student,
  studentHasMembership: false,
  subject: null,
  subjectDuration: null,
  subjectFrequency: null,
  tier,
  totalDuration: 720,
  tutor,
  wonAt: null
};
var customPackage = {
  additionalItems: [],
  additionalSubject: null,
  additionalSubjectDuration: null,
  additionalSubjectFrequency: null,
  billingRate: 229,
  brand,
  createdAt: null,
  deliveredDuration: 0,
  deliverable: {
    ...deliverable,
    conversionItemId: null,
    duration: 2400,
    title: "40 hours of Premium Private Tutoring for Ash Ketchum"
  },
  duration: 2400,
  employee: null,
  expiredDuration: 0,
  fixedTotal: 9160,
  hours: 40,
  id: null,
  includeMembership: false,
  materials: null,
  maxSubjectDuration: null,
  maxSubjectFrequency: null,
  name: "40 hours of Premium Private Tutoring for Ash Ketchum",
  notes: null,
  pace: 0,
  pricing: {
    ...pricing,
    price: 9160,
    description: "$9,160.00"
  },
  provisionedDuration: 2400,
  scheduledDuration: 0,
  status: "open",
  student,
  studentHasMembership: false,
  subject: null,
  subjectDuration: null,
  subjectFrequency: null,
  tier,
  totalDuration: 2400,
  tutor: null,
  wonAt: null
};
var packageWithSubjects = {
  activeMatch: tutorPackageMatches[0],
  additionalSubject: studyAreaSubjects[0],
  additionalSubjectDuration: 60,
  additionalSubjectFrequency: 1,
  billingRate: 99,
  createdAt: "2017-09-25T14:58:44-07:00",
  deliveredDuration: 900,
  duration: 900,
  expiredDuration: 0,
  fixedTotal: 1485,
  hours: 15,
  id: 13579,
  includeMembership: false,
  matches: tutorPackageMatches,
  materials: null,
  maxSubjectDuration: 90,
  maxSubjectFrequency: 2,
  name: "15 hours of Advanced Private Tutoring for Ash Ketchum",
  notes: "notes",
  pace: 0,
  provisionedDuration: 900,
  requestedTutor: null,
  scheduledDuration: 0,
  status: "won",
  studentHasMembership: false,
  subject: testPrepSubjects[3],
  subjectDuration: 90,
  subjectFrequency: 2,
  totalDuration: 900,
  wonAt: null
};
var packageWithoutMatch = {
  ...tutorPackageTranscript,
  activeMatch: null
};
var packageWithAffiliateReservation = {
  additionalSubjectDuration: null,
  additionalSubjectFrequency: null,
  affiliateReservation: {
    id: 30,
    activatedAt: null,
    canceledAt: null,
    companyName: "Miller",
    expiredAt: null,
    expiresAt: "2024-03-13T17:00:00-07:00",
    fulfilledAt: null,
    numberOfHours: 4,
    reservationNumber: "22222222",
    sequence: 1,
    startsAt: "2023-12-14T16:00:00-08:00",
    status: "pending",
    createdAt: "2023-12-19T10:35:32-08:00",
    updatedAt: "2024-01-26T10:20:32-08:00",
    affiliate: {
      id: 1,
      active: true,
      billingRate: "39.0",
      invoiceEmail: "providerbilling@brighthorizons.com",
      reportEmail: "backupcare_reports@revolutionprep.com",
      invoiceDayOfMonth: "3",
      logoUrl: "https://orbit-assets.s3.amazonaws.com/logos/bright-horizons-logo.svg",
      name: "Bright Horizons - Back-Up Care",
      phoneCustomerService: "(877) 777-2520"
    }
  },
  billingRate: 115,
  brand: {
    id: 42,
    affiliateId: 1,
    description: null,
    departmentId: 1,
    expireDurationAfterDays: 90,
    forceBillingRate: null,
    forceDuration: null,
    handle: "back-up-care-tutoring-department-1",
    includePoints: false,
    offerArrearBilling: false,
    offerReupMessage: "You are out of Back-Up Care Tutoring Hours.",
    offerStudentReup: false,
    renewalDuration: void 0,
    name: "Back-Up Care Tutoring",
    includeParentUpdates: true,
    subjects: [
      {
        id: 1,
        aliasedSubjectId: null,
        digitalExam: false,
        lowGrade: "6",
        highGrade: "17",
        homeworkHelp: true,
        name: "Algebra I",
        param: "algebra-i",
        categoryName: "Math"
      },
      {
        id: 2,
        aliasedSubjectId: null,
        digitalExam: false,
        lowGrade: "6",
        highGrade: "17",
        homeworkHelp: true,
        name: "Algebra II",
        param: "algebra-ii",
        categoryName: "Math"
      },
      {
        id: 3,
        aliasedSubjectId: null,
        digitalExam: false,
        lowGrade: "9",
        highGrade: "17",
        homeworkHelp: true,
        name: "AP Calculus AB",
        param: "ap-calculus-ab",
        categoryName: "Math"
      },
      {
        id: 4,
        aliasedSubjectId: null,
        digitalExam: false,
        lowGrade: "9",
        highGrade: "17",
        homeworkHelp: true,
        name: "AP Calculus BC",
        param: "ap-calculus-bc",
        categoryName: "Math"
      }
    ]
  },
  createdAt: "2023-12-19T10:35:32-08:00",
  deliveredDuration: 0,
  duration: 240,
  enrollment: void 0,
  expiredDuration: 0,
  fixedTotal: 156,
  hours: 4,
  id: 120271,
  includeMembership: false,
  materials: null,
  maxSubjectDuration: null,
  maxSubjectFrequency: null,
  name: "4 hours of Back-Up Care Tutoring for Randey Care",
  notes: null,
  pace: 0,
  parent,
  provisionedDuration: 240,
  requestedTutorId: null,
  scheduledDuration: 0,
  status: "open",
  student,
  studentHasMembership: false,
  subject,
  subjectDuration: null,
  subjectFrequency: null,
  totalDuration: 240,
  tutor: null,
  wonAt: null
};

// src/lib/tutoring-history.ts
var tutoringHistoryTutors = [
  {
    ...tutorsPartial[0],
    tier,
    stories: [],
    subjects,
    personalProfile: {
      id: 5999,
      approach: "Professor Oak specializes in the study of Pok\xE9mon",
      background: null,
      likes: null,
      dislikes: null,
      professionalTitle: null,
      educations: [
        {
          id: 721,
          graduationYear: null,
          institution: "DeVry University's Keller Graduate School of Management-Ohio",
          schoolId: 134468,
          degrees: [
            {
              id: 709,
              levelId: 26,
              levelName: "Graduate Certificate",
              major: "Information Systems Management",
              name: "Graduate Certificate in Information Systems Management",
              shortName: "GCert in Information Systems Management"
            },
            {
              id: 712,
              levelId: 7,
              levelName: "Master of Business Administration",
              major: "Information Systems Management",
              name: "Master of Business Administration in Information Systems Management",
              shortName: "MBA in Information Systems Management"
            }
          ]
        },
        {
          id: 722,
          graduationYear: null,
          institution: "DeVry University-Ohio",
          schoolId: 134466,
          degrees: [
            {
              id: 710,
              levelId: 2,
              levelName: "Bachelor of Arts",
              major: "Business Administration & Information Systems",
              name: "Bachelor of Arts in Business Administration & Information Systems",
              shortName: "B.A. in Business Administration & Information Systems"
            }
          ]
        },
        {
          id: 723,
          graduationYear: null,
          institution: "University of Phoenix-Online Campus",
          schoolId: 131402,
          degrees: [
            {
              id: 711,
              levelId: 28,
              levelName: "Teaching Credential",
              major: "Faculty Certification",
              name: "Teaching Credential in Faculty Certification",
              shortName: "TC in Faculty Certification"
            }
          ]
        }
      ]
    }
  },
  {
    ...tutorsPartial[1],
    tier: tiers[0],
    stories: [],
    subjects,
    personalProfile: {
      id: 6e3,
      approach: "Leslie specializes in political science",
      background: "Leslie is Deputy Director of Parks and Recreation in Pawnee",
      likes: null,
      dislikes: null,
      professionalTitle: null,
      educations: [
        {
          id: 824,
          graduationYear: null,
          institution: "California State University-San Bernardino",
          schoolId: 131522,
          degrees: [
            {
              id: 818,
              levelId: 2,
              levelName: "Bachelor of Arts",
              major: "Mathematics and Environmental Studies",
              name: "Bachelor of Arts in Mathematics and Environmental Studies",
              shortName: "B.A. in Mathematics and Environmental Studies"
            }
          ]
        },
        {
          id: 825,
          graduationYear: null,
          institution: "University of Nevada - Las Vegas",
          schoolId: 137881,
          degrees: [
            {
              id: 819,
              levelId: 3,
              levelName: "Bachelor of Science",
              major: "Environmental Engineering",
              name: "Bachelor of Science in Environmental Engineering",
              shortName: "B.S. in Environmental Engineering"
            }
          ]
        }
      ]
    }
  }
];
var tutoringHistories = [
  {
    id: 4e4,
    active: true,
    brandName: "Private Tutoring",
    createdAt: "2021-03-22T13:27:52-04:00",
    endedAt: null,
    isNew: true,
    minutes: 0,
    startedAt: null,
    updatedAt: "2021-03-22T13:27:52-04:00",
    enrollment: enrollmentPrivateTutoring,
    tutor: tutoringHistoryTutors[0]
  },
  {
    id: 40001,
    active: true,
    brandName: "Private Tutoring",
    createdAt: "2021-03-22T13:32:26-04:00",
    endedAt: null,
    isNew: true,
    minutes: 0,
    startedAt: null,
    updatedAt: "2021-03-22T13:32:26-04:00",
    enrollment: {
      ...enrollmentPrivateTutoring,
      id: 33334
    },
    tutor: tutoringHistoryTutors[1]
  }
];
var sortedTutoringHistories = tutoringHistories;

// src/lib/tutoring.ts
var tutoring = {
  id: 999999,
  assignmentsCount: 1,
  attendanceTakenAt: null,
  attendancesAttendedCount: 0,
  attendancesCount: 1,
  attendancesJoinedCount: 0,
  canAttend: true,
  canCancel: true,
  canLaunch: false,
  canEdit: true,
  createdAt: "2021-06-28T14:16:24-04:00",
  description: "Tutoring with Ash K.",
  duration: 60,
  employeesRequired: 1,
  endsAt: "2021-07-11T10:00:00-04:00",
  identifier: "PT",
  inPerson: false,
  name: "Tutoring",
  notes: "These are some notes",
  online: true,
  parentUpdateId: null,
  parentUpdateSentAt: null,
  problems: [],
  promotionSubject: null,
  recordingsReady: null,
  room: null,
  segmentName: "Premium",
  sequence: 157,
  startsAt: "2021-07-11T09:00:00-04:00",
  studentEditable: true,
  subjectId: null,
  supervisorId: 1234,
  suppressAttendance: false,
  title: "Tutoring",
  type: "Tutoring",
  updatedAt: "2021-06-28T14:17:06-04:00",
  launchable: false,
  isPast: false,
  isTutoring: true,
  launchedAt: null,
  scheduledBy: student,
  supervisor: tutorsPartial[0],
  course: coursePrivateTutoring,
  place: null,
  sessionSubject: null,
  slot: null,
  hub: null,
  splitInto: null,
  zoomMeeting: null,
  allotments: [sessionAllotment],
  assignments: [assignment],
  attendances: [attendanceTutoring],
  blockoutAttendances: [],
  students: [student],
  uploads: [tutoringUpload],
  potentialSubjects: subjects,
  spokes: []
};
var createTutoringPayloadsByWeek = {
  "Sun Sep 01 2024 00:00:00 GMT-0700 (Pacific Daylight Time)": [
    {
      startsAt: "2024-09-02T08:00:00-07:00",
      duration: 60,
      availabilityId: 5461529
    },
    {
      startsAt: "2024-09-03T10:00:00-07:00",
      duration: 60,
      availabilityId: 5461556
    },
    {
      startsAt: "2024-09-04T09:30:00-07:00",
      duration: 60,
      availabilityId: 5461568
    },
    {
      startsAt: "2024-09-05T07:00:00-07:00",
      duration: 60,
      availabilityId: 5461584
    }
  ]
};

// src/lib/user.ts
var user3 = {
  id: 2222,
  actorId: 1876,
  actorType: "Employee",
  addressString: "383 Linden Ave Scranton, PA 18503",
  avatarUrl,
  createdAt: "2015-05-04T15:39:47-07:00",
  examsApiToken: "admin-exams-api-token",
  email: "jim.halpert@revolutionprep.com",
  firstName: "Jim",
  fullName: "Jim Halpert",
  lastName: "Halpert",
  hubspotHandle: null,
  hasNoPassword: false,
  homeUrl: "https://admin.revolutionprep.com",
  language: "en",
  lastSignInAt: "2020-02-26T09:26:04-08:00",
  lockedAt: null,
  login: "jhalpert",
  phone: "+14442224242",
  pushNotifications: false,
  roles: [
    "academic_advisor",
    "admin"
  ],
  signInCount: 1994,
  timeZone: "America/Los_Angeles",
  title: "Academic Advisor",
  updatedAt: "2020-02-27T07:46:43-08:00",
  username: "jhalpert"
};
var studentUser = {
  id: 242424,
  actorId: 246810,
  actorType: "Student",
  addressString: "1 East 1st Street, Apt 1, New York, NY 10000",
  avatarUrl,
  createdAt: "2015-11-17T17:43:01-05:00",
  email: "developers.revolution+student-user-242424@gmail.com",
  examsApiToken: "student-exams-api-token",
  firstName: "Mick",
  fullName: "Mick Donald",
  hasNoPassword: false,
  homeUrl: "https://localhost:9030",
  hubspotHandle: null,
  language: "en",
  lastName: "Donald",
  lastSignInAt: "2024-10-21T15:42:47-04:00",
  lockedAt: null,
  login: "mdonald",
  phone: "+12342342342",
  pushNotifications: true,
  roles: [
    "student"
  ],
  signInCount: 1383,
  timeZone: "America/New_York",
  title: "Student",
  updatedAt: "2024-10-21T17:01:52-04:00",
  username: "mdonald"
};

// src/lib/user-role.ts
var userRoles = [
  "academic_advisor",
  "accountant",
  "operations",
  "advising_manager",
  "analyst",
  "director_of_instruction_management",
  "director_of_academic_advising",
  "director_of_finance_and_analytics",
  "hr",
  "instruction_manager",
  "admin",
  "tutor",
  "parent",
  "student",
  "developer",
  "contact",
  "faculty_leader",
  "spoof_advisor",
  "closer"
];

// src/lib/user-title.ts
var userTitles = [
  {
    name: "Wonka",
    roles: [
      "accountant",
      "operations",
      "advising_manager",
      "analyst",
      "director_of_instruction_management",
      "director_of_academic_advising",
      "director_of_finance_and_analytics",
      "hr",
      "instruction_manager",
      "developer",
      "admin",
      "spoof_advisor"
    ]
  },
  {
    name: "Director of Academic Advising",
    roles: [
      "director_of_academic_advising",
      "advising_manager",
      "academic_advisor",
      "hr",
      "admin",
      "spoof_advisor"
    ]
  },
  {
    name: "Advising Manager",
    roles: [
      "advising_manager",
      "admin",
      "spoof_advisor"
    ]
  },
  {
    name: "Unpriveleged",
    roles: []
  }
];

// src/lib/validation-error.ts
var validationErrors = {
  studentFirstName: ["The Student first name field must be at least 2 characters"],
  studentLastName: ["The Student last name field is required"],
  studentGrade: [],
  studentTimeZone: [],
  studentSchool: [],
  parentFirstName: [],
  parentLastName: [],
  parentEmail: [],
  countryCode: [],
  parentPhone: [],
  parentTimeZone: [],
  parentAddress: [],
  parentStatus: []
};

// src/lib/video.ts
var video = {
  name: "Dashes (ACT)",
  duration: 275,
  vimeoVideoId: "412345678",
  tags: [
    "unit:English",
    "category:Test-Prep",
    "subject:ACT"
  ],
  subjectName: "ACT",
  unitName: "English"
};
var videoUnits = [
  {
    name: "Introduction",
    videos: [
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "464646464",
        subjectName: "ACT",
        unitName: "Introduction",
        name: "ACT Introduction and Strategies",
        duration: 1042,
        tags: [
          "unit:Introduction",
          "category:Test-Prep",
          "subject:ACT"
        ]
      }
    ]
  },
  {
    name: "English",
    videos: [
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "424242424",
        subjectName: "ACT",
        unitName: "English",
        name: "ACT English Introduction and Strategies",
        duration: 411,
        tags: [
          "subject:ACT",
          "category:Test-Prep",
          "unit:English"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "454545454",
        subjectName: "ACT",
        unitName: "English",
        name: "Colons (ACT)",
        duration: 278,
        tags: [
          "category:Test-Prep",
          "subject:ACT",
          "unit:English"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "484848484",
        subjectName: "ACT",
        unitName: "English",
        name: "Commas (ACT)",
        duration: 436,
        tags: [
          "unit:English",
          "category:Test-Prep",
          "subject:ACT"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "494949494",
        subjectName: "ACT",
        unitName: "English",
        name: "Contractions (ACT)",
        duration: 243,
        tags: [
          "unit:English",
          "subject:ACT",
          "category:Test-Prep"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "412345678",
        subjectName: "ACT",
        unitName: "English",
        name: "Dashes (ACT)",
        duration: 275,
        tags: [
          "unit:English",
          "category:Test-Prep",
          "subject:ACT"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "404040404",
        subjectName: "ACT",
        unitName: "English",
        name: "Modifiers (ACT)",
        duration: 217,
        tags: [
          "category:Test-Prep",
          "unit:English",
          "subject:ACT"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "434343434",
        subjectName: "ACT",
        unitName: "English",
        name: "Parallelism (ACT)",
        duration: 239,
        tags: [
          "unit:English",
          "category:Test-Prep",
          "subject:ACT"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "414141414",
        subjectName: "ACT",
        unitName: "English",
        name: "Possession (ACT)",
        duration: 245,
        tags: [
          "subject:ACT",
          "category:Test-Prep",
          "unit:English"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "444444444",
        subjectName: "ACT",
        unitName: "English",
        name: "Prepositions (ACT)",
        duration: 209,
        tags: [
          "unit:English",
          "category:Test-Prep",
          "subject:ACT"
        ]
      }
    ]
  }
];
var videoSubject = {
  name: "ACT",
  vimeoShowcaseId: "7777777",
  categoryName: "Test Prep",
  units: [
    {
      name: "Introduction",
      videos: [
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "464646464",
          subjectName: "ACT",
          unitName: "Introduction",
          name: "ACT Introduction and Strategies",
          duration: 1042,
          tags: [
            "unit:Introduction",
            "category:Test-Prep",
            "subject:ACT"
          ]
        }
      ]
    },
    {
      name: "English",
      videos: [
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "424242424",
          subjectName: "ACT",
          unitName: "English",
          name: "ACT English Introduction and Strategies",
          duration: 411,
          tags: [
            "subject:ACT",
            "category:Test-Prep",
            "unit:English"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "454545454",
          subjectName: "ACT",
          unitName: "English",
          name: "Colons (ACT)",
          duration: 278,
          tags: [
            "category:Test-Prep",
            "subject:ACT",
            "unit:English"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "484848484",
          subjectName: "ACT",
          unitName: "English",
          name: "Commas (ACT)",
          duration: 436,
          tags: [
            "unit:English",
            "category:Test-Prep",
            "subject:ACT"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "494949494",
          subjectName: "ACT",
          unitName: "English",
          name: "Contractions (ACT)",
          duration: 243,
          tags: [
            "unit:English",
            "subject:ACT",
            "category:Test-Prep"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "412345678",
          subjectName: "ACT",
          unitName: "English",
          name: "Dashes (ACT)",
          duration: 275,
          tags: [
            "unit:English",
            "category:Test-Prep",
            "subject:ACT"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "404040404",
          subjectName: "ACT",
          unitName: "English",
          name: "Modifiers (ACT)",
          duration: 217,
          tags: [
            "category:Test-Prep",
            "unit:English",
            "subject:ACT"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "434343434",
          subjectName: "ACT",
          unitName: "English",
          name: "Parallelism (ACT)",
          duration: 239,
          tags: [
            "unit:English",
            "category:Test-Prep",
            "subject:ACT"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "414141414",
          subjectName: "ACT",
          unitName: "English",
          name: "Possession (ACT)",
          duration: 245,
          tags: [
            "subject:ACT",
            "category:Test-Prep",
            "unit:English"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "444444444",
          subjectName: "ACT",
          unitName: "English",
          name: "Prepositions (ACT)",
          duration: 209,
          tags: [
            "unit:English",
            "category:Test-Prep",
            "subject:ACT"
          ]
        }
      ]
    }
  ]
};
var videoSubjects = [
  {
    name: "ACT",
    vimeoShowcaseId: "7777777",
    categoryName: "Test Prep",
    icon: null,
    units: [
      {
        name: "Introduction",
        videos: [{
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "464646464",
          subjectName: "ACT",
          unitName: "Introduction",
          name: "ACT Introduction and Strategies",
          duration: 1042,
          tags: [
            "unit:Introduction",
            "category:Test-Prep",
            "subject:ACT"
          ]
        }]
      },
      {
        name: "English",
        videos: [
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "424242424",
            subjectName: "ACT",
            unitName: "English",
            name: "ACT English Introduction and Strategies",
            duration: 411,
            tags: [
              "subject:ACT",
              "category:Test-Prep",
              "unit:English"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "454545454",
            subjectName: "ACT",
            unitName: "English",
            name: "Colons (ACT)",
            duration: 278,
            tags: [
              "category:Test-Prep",
              "subject:ACT",
              "unit:English"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "484848484",
            subjectName: "ACT",
            unitName: "English",
            name: "Commas (ACT)",
            duration: 436,
            tags: [
              "unit:English",
              "category:Test-Prep",
              "subject:ACT"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "494949494",
            subjectName: "ACT",
            unitName: "English",
            name: "Contractions (ACT)",
            duration: 243,
            tags: [
              "unit:English",
              "subject:ACT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "412345678",
            subjectName: "ACT",
            unitName: "English",
            name: "Dashes (ACT)",
            duration: 275,
            tags: [
              "unit:English",
              "category:Test-Prep",
              "subject:ACT"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "404040404",
            subjectName: "ACT",
            unitName: "English",
            name: "Modifiers (ACT)",
            duration: 217,
            tags: [
              "category:Test-Prep",
              "unit:English",
              "subject:ACT"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "434343434",
            subjectName: "ACT",
            unitName: "English",
            name: "Parallelism (ACT)",
            duration: 239,
            tags: [
              "unit:English",
              "category:Test-Prep",
              "subject:ACT"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "414141414",
            subjectName: "ACT",
            unitName: "English",
            name: "Possession (ACT)",
            duration: 245,
            tags: [
              "subject:ACT",
              "category:Test-Prep",
              "unit:English"
            ]
          },
          {
            vimeoShowcaseId: "7777777",
            vimeoVideoId: "444444444",
            subjectName: "ACT",
            unitName: "English",
            name: "Prepositions (ACT)",
            duration: 209,
            tags: [
              "unit:English",
              "category:Test-Prep",
              "subject:ACT"
            ]
          }
        ]
      }
    ]
  },
  {
    name: "SAT",
    vimeoShowcaseId: "7654321",
    categoryName: "Test Prep",
    icon: null,
    units: [
      {
        name: "Introduction",
        videos: [{
          vimeoShowcaseId: "7654321",
          vimeoVideoId: "499999999",
          subjectName: "SAT",
          unitName: "Introduction",
          name: "SAT Introduction",
          duration: 839,
          tags: [
            "category:Test-Prep",
            "subject:SAT",
            "unit:Introduction"
          ]
        }]
      },
      {
        name: "Essay",
        videos: [{
          vimeoShowcaseId: "7654321",
          vimeoVideoId: "400000000",
          subjectName: "SAT",
          unitName: "Essay",
          name: "SAT Essay Introduction and Strategies",
          duration: 379,
          tags: [
            "subject:SAT",
            "category:Test-Prep",
            "unit:Essay"
          ]
        }]
      },
      {
        name: "Reading",
        videos: [
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "474747474",
            subjectName: "SAT",
            unitName: "Reading",
            name: "SAT Reading Introduction and Strategies",
            duration: 766,
            tags: [
              "category:Test-Prep",
              "subject:SAT",
              "unit:Reading"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "433333333",
            subjectName: "SAT",
            unitName: "Reading",
            name: "Words in Context (SAT)",
            duration: 336,
            tags: [
              "category:Test-Prep",
              "subject:SAT",
              "unit:Reading"
            ]
          }
        ]
      },
      {
        name: "Writing and Language",
        videos: [
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "422222222",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "SAT Writing Introduction and Stragies",
            duration: 590,
            tags: [
              "unit:Writing-and-Language",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "412345678",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Colons (SAT)",
            duration: 195,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "432432432",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Commas (SAT)",
            duration: 463,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "456456456",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Contractions (SAT)",
            duration: 286,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "454345434",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Dashes (SAT)",
            duration: 232,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "464246424",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Frequently Confused Words (SAT)",
            duration: 311,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "474147414",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Modifers (SAT)",
            duration: 298,
            tags: [
              "unit:Writing-and-Language",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "484048404",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Parallelism (SAT)",
            duration: 250,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "424042404",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Possession (SAT)",
            duration: 259,
            tags: [
              "unit:Writing-and-Language",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "414241424",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Prepositions (SAT)",
            duration: 211,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "432143214",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Pronouns (SAT)",
            duration: 234,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "456745674",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Redundancy (SAT)",
            duration: 295,
            tags: [
              "subject:SAT",
              "unit:Writing-and-Language",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "468046804",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Sentence Fragments (SAT)",
            duration: 235,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "420842084",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Transitions (SAT)",
            duration: 223,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "426426426",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Verbs (SAT)",
            duration: 280,
            tags: [
              "subject:SAT",
              "unit:Writing-and-Language",
              "category:Test-Prep"
            ]
          }
        ]
      },
      {
        name: "Math",
        videos: [
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000001",
            subjectName: "SAT",
            unitName: "Math",
            name: "SAT Math Introduction and Strategies",
            duration: 681,
            tags: [
              "category:Test-Prep",
              "subject:SAT",
              "unit:Math"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000002",
            subjectName: "SAT",
            unitName: "Math",
            name: "Absolute Value (SAT)",
            duration: 356,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000003",
            subjectName: "SAT",
            unitName: "Math",
            name: "Arc and Sector (SAT)",
            duration: 556,
            tags: [
              "category:Test-Prep",
              "unit:Math",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000004",
            subjectName: "SAT",
            unitName: "Math",
            name: "Area (Triangles) (SAT)",
            duration: 459,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000005",
            subjectName: "SAT",
            unitName: "Math",
            name: "Area and Circumference (SAT)",
            duration: 436,
            tags: [
              "category:Test-Prep",
              "subject:SAT",
              "unit:Math"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000006",
            subjectName: "SAT",
            unitName: "Math",
            name: "Average (Arithmetic Mean) (SAT)",
            duration: 502,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000007",
            subjectName: "SAT",
            unitName: "Math",
            name: "Backsolving (SAT)",
            duration: 555,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000008",
            subjectName: "SAT",
            unitName: "Math",
            name: "Circle Equation (SAT)",
            duration: 392,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000009",
            subjectName: "SAT",
            unitName: "Math",
            name: "Complex Numbers (SAT)",
            duration: 469,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000010",
            subjectName: "SAT",
            unitName: "Math",
            name: "Distributive Property (SAT)",
            duration: 362,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000011",
            subjectName: "SAT",
            unitName: "Math",
            name: "Exponents (SAT)",
            duration: 300,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000012",
            subjectName: "SAT",
            unitName: "Math",
            name: "Fractions (SAT)",
            duration: 408,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Math"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000013",
            subjectName: "SAT",
            unitName: "Math",
            name: "Functions (SAT)",
            duration: 297,
            tags: [
              "subject:SAT",
              "unit:Math",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000014",
            subjectName: "SAT",
            unitName: "Math",
            name: "Interpreting Data (SAT)",
            duration: 360,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000015",
            subjectName: "SAT",
            unitName: "Math",
            name: "Interpreting Equations (SAT)",
            duration: 393,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000016",
            subjectName: "SAT",
            unitName: "Math",
            name: "Line of Best Fit (SAT)",
            duration: 386,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000017",
            subjectName: "SAT",
            unitName: "Math",
            name: "Parabolas (SAT)",
            duration: 571,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000018",
            subjectName: "SAT",
            unitName: "Math",
            name: "Polynomial Factors (SAT)",
            duration: 337,
            tags: [
              "category:Test-Prep",
              "subject:SAT",
              "unit:Math"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000019",
            subjectName: "SAT",
            unitName: "Math",
            name: "Probability (SAT)",
            duration: 496,
            tags: [
              "subject:SAT",
              "unit:Math",
              "category:Test-Prep"
            ]
          }
        ]
      }
    ]
  }
];
var ACTVideos = [
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "464646464",
    subjectName: "ACT",
    unitName: "Introduction",
    name: "ACT Introduction and Strategies",
    duration: 1042,
    tags: [
      "unit:Introduction",
      "category:Test-Prep",
      "subject:ACT"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "424242424",
    subjectName: "ACT",
    unitName: "English",
    name: "ACT English Introduction and Strategies",
    duration: 411,
    tags: [
      "subject:ACT",
      "category:Test-Prep",
      "unit:English"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "454545454",
    subjectName: "ACT",
    unitName: "English",
    name: "Colons (ACT)",
    duration: 278,
    tags: [
      "category:Test-Prep",
      "subject:ACT",
      "unit:English"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "484848484",
    subjectName: "ACT",
    unitName: "English",
    name: "Commas (ACT)",
    duration: 436,
    tags: [
      "unit:English",
      "category:Test-Prep",
      "subject:ACT"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "494949494",
    subjectName: "ACT",
    unitName: "English",
    name: "Contractions (ACT)",
    duration: 243,
    tags: [
      "unit:English",
      "subject:ACT",
      "category:Test-Prep"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "412345678",
    subjectName: "ACT",
    unitName: "English",
    name: "Dashes (ACT)",
    duration: 275,
    tags: [
      "unit:English",
      "category:Test-Prep",
      "subject:ACT"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "404040404",
    subjectName: "ACT",
    unitName: "English",
    name: "Modifiers (ACT)",
    duration: 217,
    tags: [
      "category:Test-Prep",
      "unit:English",
      "subject:ACT"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "434343434",
    subjectName: "ACT",
    unitName: "English",
    name: "Parallelism (ACT)",
    duration: 239,
    tags: [
      "unit:English",
      "category:Test-Prep",
      "subject:ACT"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "414141414",
    subjectName: "ACT",
    unitName: "English",
    name: "Possession (ACT)",
    duration: 245,
    tags: [
      "subject:ACT",
      "category:Test-Prep",
      "unit:English"
    ]
  },
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "444444444",
    subjectName: "ACT",
    unitName: "English",
    name: "Prepositions (ACT)",
    duration: 209,
    tags: [
      "unit:English",
      "category:Test-Prep",
      "subject:ACT"
    ]
  }
];
var ACTIntroVideos = [
  {
    vimeoShowcaseId: "7777777",
    vimeoVideoId: "464646464",
    subjectName: "ACT",
    unitName: "Introduction",
    name: "ACT Introduction and Strategies",
    duration: 1042,
    tags: [
      "unit:Introduction",
      "category:Test-Prep",
      "subject:ACT"
    ]
  }
];
var ACTVideosStartingWithC = [
  {
    name: "English",
    videos: [
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "454545454",
        subjectName: "ACT",
        unitName: "English",
        name: "Colons (ACT)",
        duration: 278,
        tags: [
          "category:Test-Prep",
          "subject:ACT",
          "unit:English"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "484848484",
        subjectName: "ACT",
        unitName: "English",
        name: "Commas (ACT)",
        duration: 436,
        tags: [
          "unit:English",
          "category:Test-Prep",
          "subject:ACT"
        ]
      },
      {
        vimeoShowcaseId: "7777777",
        vimeoVideoId: "494949494",
        subjectName: "ACT",
        unitName: "English",
        name: "Contractions (ACT)",
        duration: 243,
        tags: [
          "unit:English",
          "subject:ACT",
          "category:Test-Prep"
        ]
      }
    ]
  }
];
var allSubjectsVideosStartingWithC = [
  {
    name: "ACT",
    vimeoShowcaseId: "7777777",
    categoryName: "Test Prep",
    icon: null,
    units: [{
      name: "English",
      videos: [
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "454545454",
          subjectName: "ACT",
          unitName: "English",
          name: "Colons (ACT)",
          duration: 278,
          tags: [
            "category:Test-Prep",
            "subject:ACT",
            "unit:English"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "484848484",
          subjectName: "ACT",
          unitName: "English",
          name: "Commas (ACT)",
          duration: 436,
          tags: [
            "unit:English",
            "category:Test-Prep",
            "subject:ACT"
          ]
        },
        {
          vimeoShowcaseId: "7777777",
          vimeoVideoId: "494949494",
          subjectName: "ACT",
          unitName: "English",
          name: "Contractions (ACT)",
          duration: 243,
          tags: [
            "unit:English",
            "subject:ACT",
            "category:Test-Prep"
          ]
        }
      ]
    }]
  },
  {
    name: "SAT",
    vimeoShowcaseId: "7654321",
    categoryName: "Test Prep",
    icon: null,
    units: [
      {
        name: "Writing and Language",
        videos: [
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "412345678",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Colons (SAT)",
            duration: 195,
            tags: [
              "subject:SAT",
              "category:Test-Prep",
              "unit:Writing-and-Language"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "432432432",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Commas (SAT)",
            duration: 463,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "456456456",
            subjectName: "SAT",
            unitName: "Writing and Language",
            name: "Contractions (SAT)",
            duration: 286,
            tags: [
              "category:Test-Prep",
              "unit:Writing-and-Language",
              "subject:SAT"
            ]
          }
        ]
      },
      {
        name: "Math",
        videos: [
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000008",
            subjectName: "SAT",
            unitName: "Math",
            name: "Circle Equation (SAT)",
            duration: 392,
            tags: [
              "unit:Math",
              "subject:SAT",
              "category:Test-Prep"
            ]
          },
          {
            vimeoShowcaseId: "7654321",
            vimeoVideoId: "400000009",
            subjectName: "SAT",
            unitName: "Math",
            name: "Complex Numbers (SAT)",
            duration: 469,
            tags: [
              "unit:Math",
              "category:Test-Prep",
              "subject:SAT"
            ]
          }
        ]
      }
    ]
  }
];

// src/lib/vimeo.ts
var vimeoVideo = {
  name: "Dashes (ACT)",
  link: "https://vimeo.com/412345678",
  duration: 275,
  tags: [
    { name: "unit:English" },
    { name: "category:Test-Prep" },
    { name: "subject:ACT" }
  ]
};
var vimeoShowcase = {
  name: "ACT",
  description: "Test Prep",
  link: "https://vimeo.com/showcase/7777777"
};
var vimeoShowcases = [
  {
    name: "ACT",
    description: "Test Prep",
    link: "https://vimeo.com/showcase/7777777",
    custom_logo: null
  },
  {
    name: "SAT",
    description: "Test Prep",
    link: "https://vimeo.com/showcase/7563411",
    custom_logo: null
  }
];
var ACTShowcaseVideos = [
  {
    name: "ACT Introduction and Strategies",
    link: "https://vimeo.com/464646464",
    duration: 1042,
    tags: [
      { name: "unit:Introduction" },
      { name: "category:Test-Prep" },
      { name: "subject:ACT" }
    ]
  },
  {
    name: "ACT English Introduction and Strategies",
    link: "https://vimeo.com/424242424",
    duration: 411,
    tags: [
      { name: "subject:ACT" },
      { name: "category:Test-Prep" },
      { name: "unit:English" }
    ]
  },
  {
    name: "Colons (ACT)",
    link: "https://vimeo.com/454545454",
    duration: 278,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:ACT" },
      { name: "unit:English" }
    ]
  },
  {
    name: "Commas (ACT)",
    link: "https://vimeo.com/484848484",
    duration: 436,
    tags: [
      { name: "unit:English" },
      { name: "category:Test-Prep" },
      { name: "subject:ACT" }
    ]
  },
  {
    name: "Contractions (ACT)",
    link: "https://vimeo.com/494949494",
    duration: 243,
    tags: [
      { name: "unit:English" },
      { name: "subject:ACT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Dashes (ACT)",
    link: "https://vimeo.com/412345678",
    duration: 275,
    tags: [
      { name: "unit:English" },
      { name: "category:Test-Prep" },
      { name: "subject:ACT" }
    ]
  },
  {
    name: "Modifiers (ACT)",
    link: "https://vimeo.com/404040404",
    duration: 217,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:English" },
      { name: "subject:ACT" }
    ]
  },
  {
    name: "Parallelism (ACT)",
    link: "https://vimeo.com/434343434",
    duration: 239,
    tags: [
      { name: "unit:English" },
      { name: "category:Test-Prep" },
      { name: "subject:ACT" }
    ]
  },
  {
    name: "Possession (ACT)",
    link: "https://vimeo.com/414141414",
    duration: 245,
    tags: [
      { name: "subject:ACT" },
      { name: "category:Test-Prep" },
      { name: "unit:English" }
    ]
  },
  {
    name: "Prepositions (ACT)",
    link: "https://vimeo.com/444444444",
    duration: 209,
    tags: [
      { name: "unit:English" },
      { name: "category:Test-Prep" },
      { name: "subject:ACT" }
    ]
  }
];
var SATShowcaseVideos = [
  {
    name: "SAT Introduction",
    link: "https://vimeo.com/499999999",
    duration: 839,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Introduction" }
    ]
  },
  {
    name: "SAT Essay Introduction and Strategies",
    link: "https://vimeo.com/400000000",
    duration: 379,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Essay" }
    ]
  },
  {
    name: "SAT Reading Introduction and Strategies",
    link: "https://vimeo.com/474747474",
    duration: 766,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Reading" }
    ]
  },
  {
    name: "Words in Context (SAT)",
    link: "https://vimeo.com/433333333",
    duration: 336,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Reading" }
    ]
  },
  {
    name: "SAT Writing Introduction and Stragies",
    link: "https://vimeo.com/422222222",
    duration: 590,
    tags: [
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Colons (SAT)",
    link: "https://vimeo.com/412345678",
    duration: 195,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" }
    ]
  },
  {
    name: "Commas (SAT)",
    link: "https://vimeo.com/432432432",
    duration: 463,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Contractions (SAT)",
    link: "https://vimeo.com/456456456",
    duration: 286,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Dashes (SAT)",
    link: "https://vimeo.com/454345434",
    duration: 232,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" }
    ]
  },
  {
    name: "Frequently Confused Words (SAT)",
    link: "https://vimeo.com/464246424",
    duration: 311,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Modifers (SAT)",
    link: "https://vimeo.com/474147414",
    duration: 298,
    tags: [
      { name: "unit:Writing-and-Language" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Parallelism (SAT)",
    link: "https://vimeo.com/484048404",
    duration: 250,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" }
    ]
  },
  {
    name: "Possession (SAT)",
    link: "https://vimeo.com/424042404",
    duration: 259,
    tags: [
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Prepositions (SAT)",
    link: "https://vimeo.com/414241424",
    duration: 211,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Pronouns (SAT)",
    link: "https://vimeo.com/432143214",
    duration: 234,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" }
    ]
  },
  {
    name: "Redundancy (SAT)",
    link: "https://vimeo.com/456745674",
    duration: 295,
    tags: [
      { name: "subject:SAT" },
      { name: "unit:Writing-and-Language" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Sentence Fragments (SAT)",
    link: "https://vimeo.com/468046804",
    duration: 235,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Transitions (SAT)",
    link: "https://vimeo.com/420842084",
    duration: 223,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Writing-and-Language" }
    ]
  },
  {
    name: "Verbs (SAT)",
    link: "https://vimeo.com/426426426",
    duration: 280,
    tags: [
      { name: "subject:SAT" },
      { name: "unit:Writing-and-Language" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "SAT Math Introduction and Strategies",
    link: "https://vimeo.com/400000001",
    duration: 681,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Math" }
    ]
  },
  {
    name: "Absolute Value (SAT)",
    link: "https://vimeo.com/400000002",
    duration: 356,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Arc and Sector (SAT)",
    link: "https://vimeo.com/400000003",
    duration: 556,
    tags: [
      { name: "category:Test-Prep" },
      { name: "unit:Math" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Area (Triangles) (SAT)",
    link: "https://vimeo.com/400000004",
    duration: 459,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Area and Circumference (SAT)",
    link: "https://vimeo.com/400000005",
    duration: 436,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Math" }
    ]
  },
  {
    name: "Average (Arithmetic Mean) (SAT)",
    link: "https://vimeo.com/400000006",
    duration: 502,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Backsolving (SAT)",
    link: "https://vimeo.com/400000007",
    duration: 555,
    tags: [
      { name: "unit:Math" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Circle Equation (SAT)",
    link: "https://vimeo.com/400000008",
    duration: 392,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Complex Numbers (SAT)",
    link: "https://vimeo.com/400000009",
    duration: 469,
    tags: [
      { name: "unit:Math" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Distributive Property (SAT)",
    link: "https://vimeo.com/400000010",
    duration: 362,
    tags: [
      { name: "unit:Math" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Exponents (SAT)",
    link: "https://vimeo.com/400000011",
    duration: 300,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Fractions (SAT)",
    link: "https://vimeo.com/400000012",
    duration: 408,
    tags: [
      { name: "subject:SAT" },
      { name: "category:Test-Prep" },
      { name: "unit:Math" }
    ]
  },
  {
    name: "Functions (SAT)",
    link: "https://vimeo.com/400000013",
    duration: 297,
    tags: [
      { name: "subject:SAT" },
      { name: "unit:Math" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Interpreting Data (SAT)",
    link: "https://vimeo.com/400000014",
    duration: 360,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Interpreting Equations (SAT)",
    link: "https://vimeo.com/400000015",
    duration: 393,
    tags: [
      { name: "unit:Math" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Line of Best Fit (SAT)",
    link: "https://vimeo.com/400000016",
    duration: 386,
    tags: [
      { name: "unit:Math" },
      { name: "subject:SAT" },
      { name: "category:Test-Prep" }
    ]
  },
  {
    name: "Parabolas (SAT)",
    link: "https://vimeo.com/400000017",
    duration: 571,
    tags: [
      { name: "unit:Math" },
      { name: "category:Test-Prep" },
      { name: "subject:SAT" }
    ]
  },
  {
    name: "Polynomial Factors (SAT)",
    link: "https://vimeo.com/400000018",
    duration: 337,
    tags: [
      { name: "category:Test-Prep" },
      { name: "subject:SAT" },
      { name: "unit:Math" }
    ]
  },
  {
    name: "Probability (SAT)",
    link: "https://vimeo.com/400000019",
    duration: 496,
    tags: [
      { name: "subject:SAT" },
      { name: "unit:Math" },
      { name: "category:Test-Prep" }
    ]
  }
];

// src/lib/v-select-item.ts
var subjectItems = [
  { header: "Math" },
  { props: { disabled: true }, value: 2, text: "Algebra II" },
  { props: { disabled: true }, value: 3, text: "AP Calculus AB" },
  { props: { disabled: true }, value: 4, text: "AP Calculus BC" },
  { props: { disabled: true }, value: 60, text: "Calculus (College-Level)" },
  { props: { disabled: true }, value: 58, text: "Geometry" },
  { value: 7, text: "Pre-Calculus" },
  { props: { disabled: true }, value: 8, text: "Statistics" },
  { value: 9, text: "Trigonometry" },
  { header: "Science" },
  { props: { disabled: true }, value: 90, text: "Anatomy & Physiology (College-Level)" },
  { value: 76, text: "Biology (College-Level)" },
  { props: { disabled: true }, value: 79, text: "Chemistry (College-Level)" },
  { props: { disabled: true }, value: 15, text: "Computer Science " },
  { value: 178, text: "Organic Chemistry (College-Level)" },
  { props: { disabled: true }, value: 83, text: "Physics (College-Level)" },
  { header: "English" },
  { props: { disabled: true }, value: 23, text: "Reading Comprehension" },
  { value: 72, text: "TOEFL" },
  { props: { disabled: true }, value: 24, text: "Writing" },
  { header: "Language" },
  { value: 104, text: "Chinese" },
  { value: 105, text: "Chinese (Level 2)" },
  { value: 106, text: "Chinese (Level 3)" },
  { value: 107, text: "French" },
  { value: 108, text: "French (Level 2)" },
  { value: 109, text: "French (Level 3)" },
  { value: 110, text: "German" },
  { value: 111, text: "German (Level 2)" },
  { value: 112, text: "Italian" },
  { value: 113, text: "Italian (Level 2)" },
  { value: 114, text: "Japanese" },
  { value: 115, text: "Japanese (Level 2)" },
  { value: 116, text: "Japanese (Level 3)" },
  { value: 117, text: "Latin" },
  { value: 118, text: "Latin (Level 2)" },
  { props: { disabled: true }, value: 119, text: "Spanish" },
  { value: 120, text: "Spanish (Level 2)" },
  { value: 121, text: "Spanish (Level 3)" },
  { header: "Social Studies" },
  { value: 98, text: "Art History" },
  { value: 125, text: "Economics" },
  { props: { disabled: true }, value: 35, text: "European History" },
  { value: 37, text: "Human Geography " },
  { props: { disabled: true }, value: 92, text: "Macroeconomics (College-Level)" },
  { value: 94, text: "Microeconomics (College-Level)" },
  { props: { disabled: true }, value: 101, text: "Music Theory" },
  { value: 85, text: "Psychology (College-Level)" },
  { props: { disabled: true }, value: 40, text: "U.S. Government" },
  { value: 41, text: "U.S. History" },
  { value: 42, text: "World History" },
  { header: "Test Prep" },
  { value: 150, text: "Executive Functioning" },
  { props: { disabled: true }, value: 44, text: "GMAT" },
  { value: 45, text: "GRE" },
  { props: { disabled: true }, value: 48, text: "LSAT" },
  { value: 49, text: "MCAT" }
];
export {
  ACTIntroVideos,
  ACTShowcaseVideos,
  ACTTranscripts,
  ACTVideos,
  ACTVideosStartingWithC,
  PSATTranscripts,
  SATShowcaseVideos,
  academicsEnrollment,
  activityFeedItemAlert,
  activityFeedItemCard,
  activityFeedItemLocation,
  actorAvatar,
  address,
  allSubjectsVideosStartingWithC,
  assignment,
  attachment,
  attachments,
  attendance,
  attendanceTutoring,
  attendances,
  authorizeNetProfile,
  availabilities,
  availabilitiesJson,
  availability,
  availabilityRepeatOptions,
  avatarConfig,
  avatarUrl,
  blockoutAttendances,
  blockoutsJson,
  brand,
  brandPermissions,
  brandPrivateTutoring,
  brandRevolutionNow,
  brandSelfStudy,
  brandTutoring,
  calendarAvailabilitiesItems,
  calendarAvailabilitiesJson,
  chartData,
  chartOptions,
  consult,
  contact,
  countries,
  course,
  courseMaterials,
  courseMembership,
  coursePrivateTutoring,
  coursePrivateTutoringWithBrand,
  courseRevolutionNowLimited,
  courseSATPracticeExam,
  courseSelfStudy,
  courseTutoringAlgebraII,
  courseTutoringGeometry,
  createTutoringPayloadsByWeek,
  creditCard,
  customPackage,
  deliverable,
  deliverableMembership,
  departments,
  digitalExamStudyArea,
  digitalExamSubject,
  digitalSnapshots,
  dynamicSession,
  dynamicSessionAttendances,
  employee,
  enrollmentPrivateTutoring,
  enrollmentWithTutoringHistories,
  enrollments,
  events,
  examReviewStudyArea,
  examReviewSubject,
  examToProctor,
  examTranscriptScan,
  examTypes,
  exams,
  gpas,
  gradeLevels,
  gradeLevelsWithGradYear,
  gradeScore,
  headsUpNotice,
  imminentClose,
  incompleteReview,
  invalidCreditCardEventPayload,
  leadSource,
  libraryLeadSource,
  limitedEnrollment,
  menuItems,
  mockAddress,
  mockDataURI,
  mockGooglePlace,
  newNotice,
  organizationInfo,
  packageWithAffiliateReservation,
  packageWithSubjects,
  packageWithoutMatch,
  paidRNEnrollment,
  parent,
  parentWithContact,
  partialAvatarConfig,
  pendingSchool,
  pendingSchoolOutsideUS,
  place,
  pricing,
  primeHours,
  properties,
  propertyRecord,
  recentTestDates,
  referralSources,
  schoolLeadSource,
  schoolStatusLeadSource,
  searchFilterParams,
  selfStudyCourseItemEngagements,
  selfStudyCourseItems,
  session,
  sessionACT,
  sessionAlgebraII,
  sessionAllotment,
  sessionAllotments,
  sessionBiologyHonors,
  sessionGeometry,
  sessionNoAvatarUrl,
  sessionNoTitle,
  sessionSupervisor,
  sessionSupervisors,
  sessionTutoring,
  sessions,
  slotSubscriptions,
  sortedExams,
  sortedStudyAreas,
  sortedTranscriptSummary,
  sortedTutoringHistories,
  states,
  statistic,
  statistics,
  student,
  studentUser,
  studentWithoutPaymentProfile,
  studyArea,
  studyAreaSnapshot,
  studyAreaSnapshots,
  studyAreaSubjects,
  studyAreaWithSnapshots,
  studyAreas,
  subject,
  subjectItems,
  subjects,
  subjectsByCategory,
  testPrepStudyAreas,
  testPrepSubjects,
  tier,
  tiers,
  timeZones,
  transcriptSummary,
  transcriptSummaryAP,
  transcriptSummaryIncomplete,
  transcripts,
  tutor,
  tutorAvailabilities,
  tutorPackage,
  tutorPackageInitialMatch,
  tutorPackageMatch,
  tutorPackageMatchResendEmail,
  tutorPackageMatches,
  tutorPackageTranscript,
  tutorPackageTranscriptWithParent,
  tutorPackages,
  tutoring,
  tutoringHistories,
  tutoringHistoryTutors,
  tutoringUpload,
  tutors,
  tutorsPartial,
  upcomingTestDates,
  user3 as user,
  userRoles,
  userTitles,
  validCreditCardEventPayload,
  validationErrors,
  video,
  videoSubject,
  videoSubjects,
  videoUnits,
  vimeoShowcase,
  vimeoShowcases,
  vimeoVideo,
  zoomMeeting
};
